/**
 * @define Map
 *
 * Map is a component with interactive areas of of irregular shapes.
 * Usage: https://www.policeone.com/police-jobs/search/state/
 */

.Map-image {
  display: inline-block;
}

.Map-image--usa {
  width: 497px;
  height: 295px;
}

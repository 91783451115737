/**
 * @define utilities
 * Layout utilities: formatting contexts, floats, clearing, etc.
 */

.u-clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

.u-clearLeft {
  clear: left;
}

.u-clearRight {
  clear: right;
}

.u-clearBoth {
  clear: both !important;
}

/**
 * New Block Fromatting Context.
 * Can be used next to floats (its content wont flow under a float and wont
 * go above/behind it)
 */
.u-nbfc {
  overflow: hidden !important;
}

/**
 * Floats
 */

.u-floatLeft {
  float: left !important;
}

.u-floatRight {
  float: right !important;
}

.u-floatNone {
  float: none !important;
}

/**
 * If a replaced el/inline-block needs to be ignored by the line box: no
 * matter what its height, it won't affect the padding/margin of either the
 * parent and its siblings.
 */
.u-ignoreLineBox {
  margin: -1em 0.3em;
  vertical-align: middle;
}

/**
 * 1) In case there is a .Picture inside it.
 */
.u-fillHeight {
  height: 100%;
  max-height: 100%; /* 1 */
}

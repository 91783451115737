/**
 * @define Article
 * This component is for main content sections of the site: news, articles,
 * columns, press-releases, ect.
 */

/* The component
  ========================================================================= */

/**
 * The component itself.
 * NOTE that it changes font size on mobile, so use with caution.
 *
 * 1) Article's intro text elements are floated, so clearance is a must
 *    since the article might have only those.
 */
.Article {
  @include clearfix; /* 1 */

  margin-bottom: 2.5rem;
  font-size: $font-size-base;

  @media (max-width: $screen-mobile-xl) {
    line-height: 1.5;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/* Text and text-containing elements
  ========================================================================= */

/**
 * Common styles for article items: headings, paragraphs, etc.
 * The trick is to nullify their line-height with negative margins. After that
 * we can just set the needed bottom gap (via padding) once and never worry
 * what comes after what (i.e. avoiding `h1 + h2`, `h1 + p`)
 * EVERY such text element MUST have appropriate negative margins set.
 *
 * As for the default Article-p - it can either be used as <p> or to contain
 * for example some List--inline
 *
 * 1) float is needed to avoid staking of adjacent els' neg. margins
 * 2) Since the prev. el will have a float, need to clear it.
 * 3) Usin padding as a bottom gap (margin-bottom is busy nullyfying the lh)
 * 4) Default negative margins for regular text (taking into account its lh)
 * 5) Resetting defaults for possible heading tags
 * 6) [Firefox] FF can add a page-break before a floated element for no
 *    reason, so we have to sacrifice some pixel-perfect spacings on print
 *    to prevent that.
 *    TODO: ... we actually can include the negative bottom margin in a
 *    bottom padding, and then we WON'T NEED FLOATS AT ALL!!
 */
.Article-p {
  float: left; /* 1 */
  clear: both; /* 2 */
  margin: -0.24em 0 -0.3em; /* 4 */
  padding-bottom: 1.875rem; /* 3 */
  color: inherit; /* 5 */

  @media (max-width: $screen-mobile-xl) {
    margin: -0.41em 0 -0.4em;
    padding-bottom: 1.25rem; /* 3 */
  }

  @media print {
    float: none; /* 6 */
    clear: none;
  }

  &:last-child {
    padding-bottom: 0; /* 3 */
  }
}

/**
 * The big heading
 */
.Article-p--heading {
  @include mac-font-smoothing;
  @include font-family($font: $font-family-alt, $line-height: 1);

  margin: -0.15em 0 -0.2em !important;
  padding-bottom: 1.625rem;
  text-transform: none;
  font-size: $font-size-xxxl;

  @media (max-width: $screen-mobile-xl) {
    padding-bottom: 1.35rem;
    font-size: $font-size-l;
  }
}

/**
 * The subheading
 */
.Article-p--subHeading {
  @include mac-font-smoothing;
  @include font-family($font-family-alt);

  margin: -0.2em 0 -0.24em !important;
  text-transform: none;
  color: $gray-dark;
  font-size: $font-size-ml;

  @media (max-width: $screen-mobile-xl) {
    font-size: 1.3125rem;
  }
}

/* Block elements
  ========================================================================= */

/**
 * A generic top-level block inside article.
 * E.g., .Content is wrapped in this
 * WARNING: not inside .Content? As putting classes inside edited content is
 * not the brightest idea.
 *
 * 1) Prev element can be a text item with a float.
 */
.Article-block {
  clear: both; /* 1 */
  margin: 0 0 $article-item-mbottom;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A generic item. Wrap this around button blocks, social links and other
 * blocks where line-height doesn't add to a visual gap.
 * Not using clearfix: this one can have some elements hidden by default,
 * e.g. fixed social bar. In such cases clearfix would force this block's
 * bottom margin to persist even if the child is hidden/fixed.
 */
.Article-item {
  clear: both;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $screen-mobile-xl) {
    margin-bottom: 1.25rem;
  }
}

/**
 * A horizontal line, usually a divider between the template-generated info
 * and a user-defined content
 */
.Article-item--hr {
  border: 1px solid $border-color;
  border-width: 1px 0 0;
}

/**
 * Copyright block
 */
.Article-copyright {
  display: flex;
  align-items: center;
  margin: 0 0 $article-item-mbottom;
  font-size: $font-size-s;

  &:last-child {
    margin-bottom: 0;
  }
}

.Article-copyrightImg {
  flex-shrink: 0;
  max-width: 150px;
  padding-right: 1rem;
  line-height: 0;
}

/**
 * Classes for several meta blocks: Share buttons, Views number (video)
 * TODO: not sure if this is the best solution for such elements. Thought
 * that creating a dedicated component was too much, and Video is pretty
 * standalone, with not place for any footer. To consider something better
 */
.Article-metaLeft {
  float: left;

  @media (max-width: $screen-mobile-xxxl) {
    float: none;
    margin-bottom: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.Article-metaRight {
  float: right;
  margin: 0;

  @media (max-width: $screen-mobile-xxxl) {
    float: none;
  }
}

.Article-metaBig {
  @include mac-font-smoothing;

  text-transform: uppercase;
  color: $gray-dark;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1;
}

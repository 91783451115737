/**
 * Keeping the awkward nesting and tag selectors because the markup doesn't
 * have class names for elements.
 */
.cub-Table {
  border: none;

  @media (max-width: $screen-tablet-xs) {
    display: block;
  }

  tr {
    border: 0 solid $border-color;
    border-width: 0 0 1px;

    @media (max-width: $screen-tablet-xs) {
      display: block;
      border: none;
    }

    &:last-child {
      border: none;
    }
  }

  th,
  td {
    border: none;

    @media (max-width: $screen-tablet-xs) {
      display: block;
    }

    &:empty {
      @media (max-width: $screen-tablet-xs) {
        display: none;
      }
    }

    &:first-child {
      @media (max-width: $screen-tablet-xs) {
        color: $white;
        background: $titlebar-bg;
      }

      a {
        // We have no choice but mirror the HTML nesting here because LID
        // doesn't set classes on table contents elements
        // stylelint-disable-next-line max-nesting-depth
        &,
        &:hover {
          // stylelint-disable-next-line max-nesting-depth
          @media (max-width: $screen-tablet-xs) {
            color: inherit;
          }
        }
      }
    }
  }

  thead {
    @media (max-width: $screen-tablet-xs) {
      display: none;
    }
  }

  tbody {
    @media (max-width: $screen-tablet-xs) {
      display: block;
    }
  }
}

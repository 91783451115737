/** @define CubWidgetFormGroup */

// TODO: remove this after next refactoring:
// remove .article-template form fieldset .form-group selector

/**
 * 1) We don't want to limit the form's width in a grid layout
 *    TODO: change that to `.cub-Row-column &`?
 * 2) When inside a grid
 */
.cub-FormGroup {
  position: relative;
  overflow: visible !important;
  max-width: $cub-width;
  padding-bottom: 0 !important;

  @media screen and (max-width: $screen-tablet-l) {
    max-width: 100%;
  }

  .Form-body & {
    max-width: none; /* 1 */
  }

  .cub-Row-column & {
    max-width: none; /* 2 */
  }
}

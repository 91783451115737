/** @define CubWidgetVerificationRequests */

/**
 * Verification requests styles for cub widget
 **/

.cub-VerificationRequests-content {
  max-width: $cub-width;
}

.cub-VerificationRequest {
  border-radius: 0;
}

.cub-Verified {
  color: $cub-vr-verified-color;
  font-size: $cub-font-size;
}

/**
 * @define Heading
 *
 * Styles for headings of different types (including h1, h2, etc.)
 */

/**
 * A generic heading component
 *
 * 1) More-or-less bold Rokkitt (and OpenSans too) is too full on Mac
 * 2) Assuming that the last .Heading in a container *must never* have bottom
 *    margin, we have to override cases like `.SomeBlock .Heading {mb: 10px}`
 *    (so that we don't have to add this :last-child on every such instance).
 * 3) Resetting h2 defaults
 */
.Heading {
  @include mac-font-smoothing; /* 1 */
  @include font-family($font-family-alt);

  display: block;
  margin: 0 0 1rem;
  text-transform: none;
  color: inherit; /* 3 */
  font-size: $font-size-ml;

  @media print {
    break-after: avoid;
    break-inside: avoid;
  }

  &:last-child {
    margin-bottom: 0 !important; /* 2 */
  }

  &::after {
    content: none; /* 3 */
  }
}

/* Size-based modifiers
 ========================================================================= */

/**
 * A very small, 14px bold headings with base font
 * E.g.: PGH Lead form tpl, "Note on procurement..."
 * Don't like this item very much, but the logic of that element requires it
 * to be a heading, and resetting all these props would be too much overkill
 */
.Heading--xs {
  @include font-family($font-weight: 700, $line-height: $line-height-general);

  margin-bottom: 1em;
  font-size: $font-size-sm;
}

/**
 * Heading with font equal to base (thus "small") in uppercase (thus
 * "medium")
 * E.g.: a substitute for Heading--stripe, when the text is long and it
 * should fit into one line; Content's H3; etc.
 */
.Heading--sm {
  @include font-family($font-weight: 700, $line-height: $line-height-general, $caps: true);

  color: $heading-sm-color;
  font-size: $font-size-base;
}

.Heading--s {
  font-size: $font-size-m2;
}

/**
 * A medium-sized (24px) heading
 * E.g. Sub-heading (Style Guide), but other headings as well
 */
.Heading--m {
  margin-bottom: 1.25rem;
  color: $gray-dark;
  font-size: $font-size-ml;
}

/**
 * A heading with extra large (xl) font size
 * E.g.: Market advertising (CROSS-111), Job listing (POLICE-1581)
 */
.Heading--xl {
  margin-bottom: 1.5rem;
  font-size: $font-size-xl;
}

/**
 * ~32px headings
 * The designer absolutely insisted that we deploy this one as well, not
 * just use the existing 30px heading
 * E.g.: Grants Homepage gray boxes
 */
.Heading--xl2 {
  font-size: $font-size-xl2;
}

/**
 * ~34px headings
 * E.g.: Grants Homepage H1
 */
.Heading--xxl {
  font-size: $font-size-xxl;
}

/**
 * The main heading for content, articles, etc.
 */
.Heading--xxxl {
  @include font-family($font: $font-family-alt, $line-height: 1);

  margin-bottom: 0.75rem;
  font-size: $font-size-xxxl;
}

/* Looks-based modifiers
  ========================================================================= */

/**
 * Sometimes a heading doesn't need default large bottom margins
 * E.g.: Breaking News (Homepage)
 */
.Heading--compact {
  margin-bottom: 0.25rem;
}

/**
 * Heading with a gray stripe to the right of the text
 *
 * 1) It's meant to be single-lined
 * 2) Not giving it `display: block` or alike, so that it doesn't take space
 *    in non-flex browsers
 */
.Heading--stripe {
  @include font-family($font-weight: 700, $line-height: $line-height-general, $caps: true);

  display: flex;
  color: $heading-sm-color;
  font-size: $font-size-base;
  flex-wrap: nowrap; /* 1 */
  align-items: center;

  &::after { /* 2 */
    flex: 1 1 auto;
    height: 0.8rem;
    padding: 0 0 0 1.5625rem;
    content: "";
    background: $gray-light;
    background-clip: content-box;
  }
}

/**
 * A heading with a bottom border
 *
 * 1) The paddings are calculated with the heading's line height in mind and
 *    assuming that the following text is a regular paragraph.
 *    Works well with Heading--xl, xl2, xxl, xxxl, m and s. For other sizes
 *    we could adjust the padding in those modifiers
 * 2) not specifying the border-color so that it's the same as text color
 */
.Heading--underlined {
  margin-bottom: 0.8rem; /* 1 */
  padding-bottom: 0.5rem; /* 1 */
  border-width: 0 0 1px; /* 2 */
  border-style: solid; /* 2 */
}

/* @define PreHeader */

/**
 * The topmost block for an ad. For now dropping the dedicated classname for
 * the inner element as is doesn't have any specific styling.
 */
.PreHeader {
  display: block;
  flex: 0 0 auto;
  background: $header-new-top-ad-bg;

  @media (max-width: $screen-tablet-s) {
    display: none;
  }

  img,
  iframe {
    display: block;
    margin: 0 auto;
  }
}

/**
 * @define Picture
 *
 * The structure setting image dimensions on the back end in order to
 * 1) prevent browsers from shrinking responsive images (by distributing them
 *    over logical pixels VS physical pixels, which is the case when the width /
 *    height of the image is not set), and
 * 2) avoid reflow between the "image not loading" and "image loaded" states.
 */

/**
 * The main component.
 *
 * 1) In case this one is clickable and goes beyond its parent
 */
.Picture {
  position: relative;
  z-index: 0;
  display: inline-block;
  overflow: hidden; /* 1 */
  max-width: 100%;
  max-height: inherit;
  vertical-align: middle;
}

/**
 * In case we don't have the info on the image
 */
.Picture--noResponsive {
  .Picture-pic {
    display: inline-block;
    padding: 0;
  }

  .Picture-img {
    position: static;
    width: auto;
    height: auto;
    object-fit: fill;
  }
}

/**
 * Sometimes we need to center an image, not stretch it
 *
 * 1) For some reason, if it's the container of an image that is positioned
 *    absolutely, the image shrinks considerably, even though there is no
 *    responsive markup!
 *    So we have to make sure it's the image that is given pos:a, AND that
 *    pos:a is relative to the outer container.
 * 2) Resetting attribute styles
 */
.Picture--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.Picture--noResponsive {
    position: static; /* 1 */
    top: auto;
    left: auto;
    width: auto !important; /* 2 */
    transform: none;

    .Picture-pic {
      position: static; /* 1 */
      padding: 0 !important; /* 2 */
    }

    .Picture-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

/**
 * In case we need to just stretch the image.
 *
 * 1) Overriding attribute styles
 */
.Picture--stretch {
  position: relative;
  display: block;
  width: 100% !important; /* 1 */
  height: 100%;

  .Picture-pic {
    padding: 0 !important; /* 1 */
  }

  .Picture-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    .is-noObjectFit & {
      height: auto;
    }
  }
}

.Picture--svg {
  height: 100%;
  max-height: inherit;

  &.Picture--stretch {
    display: inline-block;
    width: 1000px;
  }
}

.Picture-pic {
  display: block;
  max-width: 100%;
  max-height: inherit;
}

.Picture-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: inherit;
  margin: 0;
  object-fit: contain;

  &.is-lazyLoad {
    .is-noJs & {
      display: none !important;
    }
  }
}

/** @define DefList */

$_dl-item-margin-vert: 0.5em;
$_dl-term-width: 11.125rem;

/**
 * A component for styling <dl> elements
 * Using and negating top spacings because in case of bottom negative margins
 * the float on DefList-term could lead to the following text trespassing
 * in the block of this component.
 */
.DefList {
  @include clearfix;

  margin: - $_dl-item-margin-vert 0 0;
  padding: 0;
}

.DefList--fluid {
  > .DefList-term {
    width: auto;
    margin-right: 0.625em;
    font-weight: 700;
  }

  .DefList-description {
    float: left;
    margin: 0;

    @media (max-width: $screen-mobile-xl) {
      float: none;
    }
  }
}

/**
 * DefList term/item/variable/question
 */
.DefList-term {
  float: left;
  clear: left;
  width: $_dl-term-width;
  padding: $_dl-item-margin-vert 0 0;

  @media (max-width: $screen-mobile-xl) {
    float: none;
    width: auto;
  }
}

/**
 * DefList definition/value/description/answer
 */
.DefList-description {
  display: block;
  margin: 0 0 0 $_dl-term-width; /* 1 */
  padding: $_dl-item-margin-vert 0 0;
  word-wrap: break-word;

  @media (max-width: $screen-mobile-xl) {
    margin: 0;
  }
}

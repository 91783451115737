// Custom variables
$top-warning-background: #f0ad4e; // original $_top_warning_background
$hide-color: #222; // original $_hide_color

.TopSystemWarning {
  position: relative;
  padding: 0.75rem 2.25rem 0.75rem 0.75rem;
  transition: background-color 0.5s;
  text-align: center;
  background-color: $top-warning-background;
}

.TopSystemWarning-hide {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: inline-block;
  width: 12px;
  height: 17px;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: rotate(0deg) scale(1, 1);
  outline: 0;
  font-size: 1.2em;
  line-height: 1;

  &:focus,
  .fa:focus {
    outline: none;
  }

  &,
  &:hover {
    color: $hide-color;
  }

  &:hover {
    transform: rotate(-90deg) scale(1.1, 1.1);
  }
}


.Icon--play:before { content: '\25b6'; } /* '▶' */
.Icon--cancel:before { content: '\2715'; } /* '✕' */
.Icon--down-open:before { content: '\e75c'; } /* '' */
.Icon--arrows-cw:before { content: '\e760'; } /* '' */
.Icon--pencil:before { content: '\e800'; } /* '' */
.Icon--search:before { content: '\e801'; } /* '' */
.Icon--right-open:before { content: '\e802'; } /* '' */
.Icon--briefcase:before { content: '\e803'; } /* '' */
.Icon--print:before { content: '\e804'; } /* '' */
.Icon--comment:before { content: '\e805'; } /* '' */
.Icon--upload:before { content: '\e806'; } /* '' */
.Icon--download:before { content: '\e807'; } /* '' */
.Icon--globe:before { content: '\e808'; } /* '' */
.Icon--videocam:before { content: '\e809'; } /* '' */
.Icon--ok:before { content: '\e80a'; } /* '' */
.Icon--lock-open:before { content: '\e80b'; } /* '' */
.Icon--cog:before { content: '\e80c'; } /* '' */
.Icon--user:before { content: '\e80d'; } /* '' */
.Icon--info-circled:before { content: '\e80e'; } /* '' */
.Icon--folder-open:before { content: '\e80f'; } /* '' */
.Icon--plus:before { content: '\e810'; } /* '' */
.Icon--right-big:before { content: '\e811'; } /* '' */
.Icon--phone:before { content: '\e812'; } /* '' */
.Icon--star:before { content: '\e813'; } /* '' */
.Icon--chat:before { content: '\e814'; } /* '' */
.Icon--heart:before { content: '\e815'; } /* '' */
.Icon--lexipolBadge:before { content: '\e816'; } /* '' */
.Icon--brokenBadge:before { content: '\e817'; } /* '' */
.Icon--usa-small:before { content: '\e818'; } /* '' */
.Icon--key-pin:before { content: '\e819'; } /* '' */
.Icon--handcuffs:before { content: '\e81a'; } /* '' */
.Icon--youtube:before { content: '\e81b'; } /* '' */
.Icon--twitter:before { content: '\f099'; } /* '' */
.Icon--facebook:before { content: '\f09a'; } /* '' */
.Icon--right-circled:before { content: '\f0a9'; } /* '' */
.Icon--menu:before { content: '\f0c9'; } /* '' */
.Icon--gplus:before { content: '\f0d5'; } /* '' */
.Icon--money:before { content: '\f0d6'; } /* '' */
.Icon--mail-alt:before { content: '\f0e0'; } /* '' */
.Icon--linkedin:before { content: '\f0e1'; } /* '' */
.Icon--comment-empty:before { content: '\f0e5'; } /* '' */
.Icon--angle-double-right:before { content: '\f101'; } /* '' */
.Icon--angle-double-down:before { content: '\f103'; } /* '' */
.Icon--angle-left:before { content: '\f104'; } /* '' */
.Icon--angle-right:before { content: '\f105'; } /* '' */
.Icon--angle-up:before { content: '\f106'; } /* '' */
.Icon--angle-down:before { content: '\f107'; } /* '' */
.Icon--quote-left:before { content: '\f10d'; } /* '' */
.Icon--spinner:before { content: '\f110'; } /* '' */
.Icon--reply:before { content: '\f112'; } /* '' */
.Icon--code:before { content: '\f121'; } /* '' */
.Icon--info:before { content: '\f129'; } /* '' */
.Icon--dollar:before { content: '\f155'; } /* '' */
.Icon--doc-inv:before { content: '\f15b'; } /* '' */
.Icon--doc-text-inv:before { content: '\f15c'; } /* '' */
.Icon--thumbs-up-alt:before { content: '\f164'; } /* '' */
.Icon--thumbs-down-alt:before { content: '\f165'; } /* '' */
.Icon--reddit:before { content: '\f1a1'; } /* '' */
.Icon--stumbleupon:before { content: '\f1a4'; } /* '' */
.Icon--file-pdf:before { content: '\f1c1'; } /* '' */
.Icon--trash:before { content: '\f1f8'; } /* '' */
.Icon--instagram:before { content: '\f32d'; } /* '' */
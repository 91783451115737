/* @define Footer */

/* Helpers
 * ======================================================================= */

// Custom local component variables
$footer-list-spacing: 1.273em; // ~14px
$_footer-font-size: 0.6875rem; // ~11px;
$_footer-left-side-width: 27%;
$_footer-left-side-margin: 6.6%;

/**
 * The component
 */
.Footer {
  @include font-family;

  position: relative;
  color: $footer-color;
  font-size: $_footer-font-size;
  line-height: 1.364;

  @media print {
    display: none;
  }
}

/* Top part
 * ======================================================================= */

.Footer-top {
  color: $footer-top-color;
  background: $footer-top-bg;

  @if ($footer-top-line-color) {
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      content: "";
      background: $footer-top-line-color;
    }
  }

  @if ($footer-top-color != inherit) {
    .Footer-link {
      &,
      &:active,
      &:visited {
        color: $footer-menu-color;
      }

      &:hover {
        color: $footer-menu-hover-color;
      }
    }
  }
}

/**
 * The inner part of the top block, limited in width
 *
 * 1) Not setting the shorthand `padding` to not mess Containers paddings
 * 2) The values of the top and bottom spacings isn't equal
 *    because logos have a bottom shadow, that increases an image height.
 * 3) Flex is needed to a) vertically center the elements, b) allow the menu
 *    be aligned at 2/3 of width, but grow if its contents require so.
 * 4) Compensating for descendants. Can't have a :last-child on them because
 *    the actual last ones of them could be hidden.
 */
.Footer-topInner {
  display: flex; /* 3 */
  padding-top: 0.6875rem; /* 1, 2 */
  padding-bottom: 0.5625rem; /* 1, 2 */
  align-items: center; /* 3 */
  flex-wrap: wrap;

  @media (max-width: $screen-tablet-l) {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: -1rem; /* 4 */
  }

  @media (max-width: $screen-tablet-s) {
    align-items: center;
    text-align: center;
  }

  @media (max-width: $screen-mobile-xxxl) {
    padding: 0;
    text-align: center;
  }
}

/**
 * The footer logo
 *
 * 1) Fallback for non-flex browsers
 * 2) margin-right: auto is crucial to align the menu (which is next to this
 *    logo) correctly inside the flex-ible parent
 */
.Footer-logo {
  float: left; /* 1 */
  flex: 0 0 auto;
  max-width: $footer-logo-width;
  height: 43px;
  margin: 0 auto 0 0; /* 2 */

  @media (max-width: $screen-tablet-l) {
    float: none; /* 1 */
    margin: 0 0 0.625rem;
  }

  @media (max-width: $screen-mobile-xxxl) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // TODO remove this constraint if all the logos are updated to the new
    // "arrow" format of the same height
    width: 190px;
    max-width: none;
    min-height: 33px;
    max-height: 70px;
    margin: 0.5rem auto;
    line-height: 1;
  }
}

/**
 * Footer Menu
 *
 * 1) This menu should be at least 2/3 width and it should grow (shifting to
 *    the left) if it has much enough content.
 * 2) Fallback for non-flex browsers; with flex this margin is not needed
 *    at all
 * 3) the exact position (the default one, while it's not too wide yet) should
 *    be aligned with the bottom menus
 */
.Footer-menu {
  @include clearfix;

  float: right; /* 2 */
  flex: 0 0 auto; /* 1 */
  min-width: (100% - $_footer-left-side-margin - $_footer-left-side-width); /* 1, 3 */
  margin: 0.875rem 0 0.8125rem; /* 2 */
  padding: 0;
  list-style: none;
  font-size: $font-size-sm;
  line-height: 1.2;

  @media (max-width: $screen-tablet-l) {
    float: none; /* 2 */
    min-width: 0;
    margin: 0 0 1rem; /* 2 */
  }

  @media (max-width: $screen-tablet-s) {
    align-self: stretch;
  }

  @media (max-width: $screen-mobile-xxxl) {
    text-align: center;
    background: $footer-menu-bg-sm;
  }
}

/**
 * 1) Overriding our default <li> styles
 */
.Footer-menuItem {
  float: left;
  margin: 0 2.0625rem 0 0;
  padding: 0; /* 1 */
  font: inherit;

  &::before { /* 1 */
    content: none;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  @media (max-width: $screen-desktop-s - 1) {
    margin-right: $footer-menu-margin-lg;
  }

  @media (max-width: $screen-tablet-s) {
    display: inline-block;
    float: none;
    margin: 0 1rem;
  }

  @media (max-width: $screen-mobile-xxxl) {
    display: block;
    margin: 0;
    border-bottom: 1px solid $footer-menu-border-sm;
    line-height: 2.25rem;
  }
}

.Footer-menuLink {
  display: inline-block;
  transition: color 0.4s ease;
  text-decoration: none;
  font: inherit;

  &,
  &:visited {
    color: $footer-menu-color;

    @media (max-width: $screen-mobile-xxxl) {
      color: $footer-menu-color-mobile;
    }
  }

  @media (max-width: $screen-tablet-l) {
    padding: 0 0.625rem;
    line-height: 3;
  }

  @media (max-width: $screen-tablet-s) {
    // On tiny screens make the links wide and as tall as the links from
    // the header
    display: block;
    line-height: 3.5;
  }

  .Footer-menuItem.is-active &,
  &:hover {
    transition: none;
    text-decoration: none;
    color: $footer-menu-hover-color;
    background: none;
  }

  // Hover and active state are not the same on all sites
  .Footer-menuItem.is-active & {
    font-weight: $footer-menu-active-weight;

    @media (max-width: $screen-mobile-xxxl) {
      color: $footer-menu-active-color-mobile;
    }
  }

  &:hover {
    @media (max-width: $screen-mobile-xxxl) {
      color: $footer-menu-hover-color-mobile;
    }
  }
}

/* Middle/Bottom part
 * ======================================================================= */

/**
 * 1) For layouts that DO need it on mobile (unhide it there with .u-block).
 */
.Footer-bottom {
  overflow: hidden;
  padding: 1.6875rem 0;
  background: $footer-bottom-bg;

  @media (max-width: $screen-tablet-s) {
    display: none;
    padding: 1rem 0; /* 1 */
  }
}

.Footer-left {
  float: left;
  width: $_footer-left-side-width;
  margin-right: $_footer-left-side-margin;

  @media (max-width: $screen-tablet-l) {
    float: none;
    width: auto;
    margin: 0 0 1.5rem;
  }

  @media (max-width: $screen-mobile-xxxl) {
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.Footer-right {
  overflow: hidden;
}

/* Middle/Bottom part texts
 * ======================================================================= */

/**
 * Footer Heading
 *
 * 1) Otherwise it's too bold on Mac
 */
.Footer-heading {
  @include mac-font-smoothing; /* 1 */

  margin: 0 0 1rem;
  color: $white;
  font-size: $font-size-s;
  font-weight: bold;
  line-height: 1.2;
}

// Footer Text
.Footer-text {
  margin: 0 0 1.25rem;
  color: inherit; /* 3 */
  font-size: inherit; /* 3 */

  &:last-child {
    margin-bottom: 0;
  }

  .Footer-topInner & {
    margin-bottom: 1rem;
  }
}

/**
 * 1) Otherwise it's too bold on Mac
 */
.Footer-text--select {
  @include mac-font-smoothing; /* 1 */

  float: left;
  margin: 0 0.727em 0.818em 0;
  font-weight: 700;
}

/**
 * Footer Link
 *
 * 1) For sites with completely different color scheme, like EduGH
 */
.Footer-link {
  text-decoration: none;

  &,
  &:active,
  &:visited,
  &:hover {
    color: $white;
  }

  &:hover {
    text-decoration: underline;
  }
}

/**
 * An image inside a footer text that is vertically centered; use with
 * u-ignoreLineBox to make the line box ignore that image's height
 * E.g.: P1 logo in "Brought to you by Police1" on PGH
 */
.Footer-textImg {
  display: inline-block;
  max-width: 110px;
  height: 2.27em;
  vertical-align: middle;
}

/* Lists
 * ======================================================================= */

// Footer List
.Footer-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: inherit; /* 3 */
}

// inline variant of Footer List
.Footer-list--inline {
  clear: both;
  overflow: hidden;

  .Footer-listItem {
    position: relative;
    float: left;
    padding-right: $footer-list-spacing;

    // The | separator
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 1px;
      height: 1em;
      margin: -0.5em ($footer-list-spacing / 2) 0 0;
      content: "";
      background: $footer-color;
    }

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.Footer-listItem {
  margin: 0 0 0.273em;
  padding: 0;
  line-height: 1.2;

  &::before {
    content: none;
  }
}

.Footer-listLink {
  transition: color 0.4s ease;
  text-decoration: none;

  &,
  &:visited {
    color: inherit;
  }

  &:active,
  &:hover {
    transition: none;
    text-decoration: none;
    color: $footer-hover-color;
  }

  // On Tablet increase (and make tappable) gaps between the links
  @media (max-width: $screen-tablet-l) {
    display: block;
    padding: 0.5em 0;
  }
}

/* The very bottom part
 * ======================================================================= */

.Footer-siteNetworkBlock {
  float: left;
}

/**
 * Footer select
 */
.Footer-select {
  $font-size: $font-size-s;
  $height: 1.417;

  position: relative;
  top: -1px;
  display: inline-block;
  width: 140px;
  height: ($height * 1em);
  padding: 0;
  color: #3b3b3b;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
  font-family: $font-family-vanilla;
  font-size: $font-size;
  line-height: $height;
}

/**
 * Some Logos in the Footer (NLAOMF, Truste logos)
 * (used only on P1)
 *
 * 1) MAGIC number. Using right margin to align the last logo's right edge
 *    with the last menu list's (above it) right edge
 */
.Footer-relatedLogos {
  position: relative;
  top: -2.273em;
  float: right;
  width: 40%;
  margin: 0 2.8rem -1em -1em; /* 1 */
  text-align: right;

  @media (max-width: $screen-desktop-s) {
    margin-right: 1rem; /* 1 */
  }

  @media (max-width: $screen-tablet-l) {
    top: -2.818em;
    margin-right: 2.8rem; /* 1 */
  }
}

.Footer-relatedLogoImage,
.Footer-relatedLogoText {
  display: inline-block;
  margin: 0 0 1em 1em;
  vertical-align: middle;
}

.Footer-relatedLogoImage {
  max-width: 80px;
  max-height: 80px;
}

.Footer-relatedLogoText {
  color: $white;
  font-size: $font-size-s;
  font-style: italic;
}

/** @define CubWidgetCaption */

/**
 * Caption styles for cub widget
 * This css contains to override more specific site selectors.
 * After optimizing site css can be removed from this code.
 *
 * 1) overriding some h2's defaults
*/

.cub-Caption {
  @include titlebar-basis;
}

/** @define Grid */

/* helpers
 ========================================================================= */

// Grid (general)
// a gap between items
$_grid-item-gap: 1.25rem !default;
// Grid with fixed-width items (~4 in a row)
// a gap between the items
$_grid-4fixed-item-gap: 1rem !default;

/**
 * Generic grid
 * Utilizes flexbox, with fallback for non-supporting browsers.
 * This grid system doesn't specify width for elements/columns to be able to
 * coexist with classes from frameworks such as Bootstrap. It redefines
 * paddings though. The main purpose is to use advantages of flexbox.
 * For stretching content use flex utilities (u-flex, u-flexGrow1, etc.)
 *
 * 1) Fallback for browsers without flex. Using only on non-flex browsers since
 *    dropping the cascading for font sizes gets really tedious.
 * 2) Removing gaps between `display: inline-block`-s
 * 3) Compensating for the items' margins
 * 4) If a browser supports only the 2009 flex standard, don't use flex there
 *    It's meaningless since flex-wrap won't work
 * 5) [Firefox] On print Firefox aviods to add page breaks inside flex els
 *    (and also d:ib and inside tables)
 */
.Grid {
  display: block; /* 1 */
  display: flex;
  flex-direction: row;
  margin: 0 0 (- $_grid-item-gap) (- $_grid-item-gap); /* 3 */
  padding: 0;
  text-align: left; /* 1 */
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media print {
    display: block; /* 5 */
  }

  .is-noFlexOr2009Only & { /* 4 */
    display: block;
    font-size: 0 !important; /* 1, 2 */
  }
}

/* Gutter size modifiers
 ========================================================================= */

/**
 * The mixin for building gaps modifiers for .Grid
 * Used ONLY HERE, to avoid copy-pasting
 *
 * $bottom-gap — bottom gap after .Grid-item
 */
@mixin grid-gap($gap: 1rem) {
  @media (min-width: $screen-tablet-s) {
    margin: 0 0 (- $gap) (- $gap); /* 3 */
  }

  > .Grid-item,
  > .Grid-fullWidthBlock {
    @media (min-width: $screen-tablet-s) {
      padding: 0 0 $gap $gap;
    }
  }
}

.Grid--gapXS {
  @include grid-gap($gap: 0.625rem);
}

.Grid--gapS {
  @include grid-gap($gap: 1rem);
}

.Grid--gapM {
  @include grid-gap($gap: 1.5 * $_grid-item-gap);
}

/**
 * The mixin for building VERTICAL gaps modifiers for .Grid
 * Used ONLY HERE, to avoid copy-pasting
 *
 * $bottom-gap — bottom gap after .Grid-item
 */
@mixin grid-gap-bottom($bottom-gap: 1rem) {
  @media (min-width: $screen-tablet-s) {
    margin-bottom: - $bottom-gap;
  }

  > .Grid-item,
  > .Grid-fullWidthBlock {
    @media (min-width: $screen-tablet-s) {
      padding-bottom: $bottom-gap;
    }
  }
}

/**
 * 1.5x bottom gap on Grid items (30px by default)
 */
.Grid--gapBottomM {
  @include grid-gap-bottom($bottom-gap: 1.5 * $_grid-item-gap);
}

/**
 * ~36px bottom margin
 */
.Grid--gapBottomML {
  @include grid-gap-bottom($bottom-gap: 1.8 * $_grid-item-gap);
}

/* Items' flow modifiers (horizontal alignment, wrapping, etc.)
 ========================================================================= */

.Grid--rignt {
  justify-content: flex-end;
}

.Grid--center {
  justify-content: center;
}

/**
 * A modifier for grids that are meant to be host for a carousel.
 * All styles here are for the most seamless transition between before the
 * carousel is initilized and after it to prevent visible "jadder" as much as
 * possible.
 * Not using justify-content: center; that would improve grids with a single
 * item, but for every other it will create a shift, i.e. the rightmost
 * visible element will be clipped.
 *
 * 1) So that grid with the number of items greater than one row wouldn't
 *    "jump" up and down
 * 2) "Allocating space" for Prev/Next arrows.
 * 3) We still need to compensate for the items' left spacing, so using
 *    a default Grid's margin here as well
 * 4) lazysizes searches for offsetParent when calculating an element's
 *    visibility. We need this .Grid to among such parents for the images
 *    inside it, since lazysizes starts working before Owl.Carousel is inited.
 */
.Grid--carousel {
  position: relative; /* 4 */
  overflow: hidden; /* 1 */
  margin-right: $owl-carousel-side-margin; /* 2 */
  margin-left: $owl-carousel-side-margin - $_grid-item-gap; /* 2, 3 */
  flex-wrap: nowrap; /* 1 */
}

/**
 * A grid with items with non-fixed size
 * The goal of this modifier is basically to allow items that reside on the
 * same row to wrap on multiple rows AND have vert margins that would not
 * affect their wrappers bottom margin/padding
 * E.g.: Training page, the two buttons at the top intro block
 */
.Grid--flexible {
  > .Grid-item {
    width: auto;
    max-width: 100%;
  }
}

/* Grid with 2 columns
 ========================================================================= */

// TODO: create grids with unconditional (@media) elements' sizes

/**
 * 2 elements in a row
 */

.Grid--2 {
  > .Grid-item {
    width: 50%;
  }
}

/* Grid with 3 items
 ========================================================================= */

/**
 * This one's items are responsive
 *
 * Use case: info block [Site] network on Reg. page
 */

.Grid--3 {
  margin: 0;

  > .Grid-item {
    width: 33.33%;
    padding: 0;

    @media screen and (max-width: $screen-mobile-xl) {
      width: 100%;
    }
  }
}

/* Grid with ~4 items of fixed width
 ========================================================================= */

/**
 * Grid with items of fixed width (on full screen - 4x in a row), gray border,
 * autostretching height
 *
 * 1) Compensating for the items' margins
 */

.Grid--4fixed {
  margin-bottom: - $_grid-4fixed-item-gap; /* 1 */
  margin-left: - $_grid-4fixed-item-gap; /* 1 */

  /**
   * Since here we have fixed-width elements, we can safely use precomputed
   * margins instead of paddings
   *
   * 1) Fallback for browsers without flex
   * 2) Restoring from `font-size: 0` on a container
   * 3) Fighting specificity (.article-template *,
   *    .modal-template * { fz: inherit })
   */

  > .Grid-item {
    width: 12.625rem;
    margin-bottom: $_grid-4fixed-item-gap;
    margin-left: $_grid-4fixed-item-gap;
    padding: 0;
  }

  > .Grid-fullWidthBlock {
    padding-bottom: $_grid-4fixed-item-gap;
    padding-left: $_grid-4fixed-item-gap;
  }
}

/* Responsive grid modifiers
 ========================================================================= */
/**
 * For 'sm' produces:
 * .Grid--2sm > .Grid-item {
 *    width: 50%;
 * }
 *
 * .Grid--3sm > .Grid-item {
 *   width: 33%;
 * }
 * etc.
 */

@mixin _grid($class: "sm") {
  @for $i from 1 through 12 {
    .Grid--#{$i}#{$class} > .Grid-item {
      width: (100% / $i);
    }
  }
}

@media (min-width: $screen-mobile-xs) {
  @include _grid("xxs");
}

@media (min-width: $screen-mobile-s) {
  @include _grid("tiny");
}

@media (min-width: $screen-mobile-l2) {
  @include _grid("mobileL");
}

@media (min-width: $screen-mobile-xl) {
  @include _grid("xs");
}

@media (min-width: $screen-mobile-xxl) {
  @include _grid("s");
}

@media (min-width: $screen-mobile-xxxl) {
  @include _grid("mobileXXXL");
}

@media (min-width: $screen-tablet-s) {
  @include _grid("sm");
}

@media (min-width: $screen-tablet-l) {
  @include _grid("md");
}

@media (min-width: $screen-desktop-s) {
  @include _grid("lg");
}

/**
 * Sometimes there is no need to keep a grid layout on print.
 * E.g. https://policeone.com/grants/
 *
 * 1) [Firefox] Otherwise FF can add a page break before it.
 */
.Grid--plainOnPrint {
  @media print {
    display: block;
  }

  > .Grid-item {
    @media print {
      display: block;
      width: auto;
    }
  }
}

/**
 * Grid whose items are floated instead of being d:ib on print. The reasont
 * for making this modifier is that some browsers (Firefox) clip inline-blocks
 * that are higher than one page on print. So, it's to be used with grid items
 * whose contents are intended to be tall (some lists, etc.).
 * Of cource we don't want to float grid items by default, obviously due to
 * how floats form cells (not in normal rows that is).
 */
.Grid--floatedOnPrint {
  @media print {
    @include clearfix;
  }

  > .Grid-item {
    @media print {
      display: block;
      float: left;
    }
  }
}

/* Elements
 ========================================================================= */

/**
 * A grid item.
 * Give this class to the Grid's children.
 *
 * 1) Fallback for browsers without flex
 * 2) Restoring from `font-size: 0` on a container
 * 3) Resetting <li> styles
 */
.Grid-item {
  display: inline-block; /* 1 */
  flex: 0 0 auto;
  width: 100%;
  margin: 0;
  padding: 0 0 $_grid-item-gap $_grid-item-gap;
  vertical-align: top; /* 1 */

  &::before { /* 3 */
    content: none;
  }

  .is-noFlexOr2009Only & { /* 4 */
    font-size: $font-size-base; /* 1, 2 -- (or 1rem) */
  }

  /**
   * Reset some styles if inside a carousel item. Checking for being a direct
   * child because .owl-item might contain entire .Grid
   * 1) There can be just one element in a carousel, which looks ugly inside
   *    relatively wide blocks. So, limiting the width
   * 2) ... and resetting that limit for mobile, where ta:c could be applied
   *    TODO: maybe margin: x auto could be used here? to drop the media query
   * 3) The breakpoint value is found experimentally (a magic number)
   */
  .owl-item > & {
    display: block;
    max-width: 300px; /* 1 */
    margin: 0 auto; /* 1 */
    padding: 0;

    @media (max-width: 500px) { /* 3 */
      max-width: none; /* 2 */
      padding-right: 0;
    }
  }
}

/**
 * A full-width analog of .Grid-item
 * Use this if there is ABSOLUTELY necessary to put something 100% wide right
 * inside .Grid
 * E.g.: "Load More..." button containers
 *
 * 1) Using padding because margin-left and w:100% would make this el wider
 *    than the parent (w:100% is important to make it full-width)
 * 2) Using w:100%, since flex-basis:100% + padding (even not margin) in IE
 *    makes the el wider than the parent.
 * 3) Fallback for browsers without flex
 * 4) Restoring from `font-size: 0` on a container
 * 5) Resetting <li> styles
 */
.Grid-fullWidthBlock {
  display: block; /* 3 */
  flex: 1 0 auto;
  width: 100%; /* 2 */
  margin: 0; /* 1 */
  padding: 0 0 $_grid-item-gap $_grid-item-gap; /* 1 */
  font-size: $font-size-base; /* 3, 4 -- (or 1rem) */

  &::before { /* 5 */
    content: none;
  }
}

/**
 * @define utilities
 * Borrowed from SUIT: https://github.com/suitcss/utils-flex
 */

/* Applies to flex container
  ======================================================================= */

/**
 * Container
 *
 * 1) [Firefox] Otherwise it's broken on print
 * 2) If a browser supports only the 2009 flex standard, don't use flex there.
 *    It's meaningless anyway since flex-wrap won't work.
 */
.u-flex {
  display: flex !important;

  @media print {
    display: block !important; /* 1 */
  }

  .is-noFlexOr2009Only & {
    display: block !important; /* 2 */
  }
}

.u-flexInline {
  display: inline-flex !important;

  @media print {
    display: block !important; /* 1 */
  }
}

/**
 * Direction: row
 */

.u-flexRow {
  flex-direction: row !important;
}

.u-flexRowReverse {
  flex-direction: row-reverse !important;
}

/**
 * Direction: column
 */

.u-flexCol {
  flex-direction: column !important;
}

.u-flexColReverse {
  flex-direction: column-reverse !important;
}

/**
 * Wrap
 */

.u-flexWrap {
  flex-wrap: wrap !important;
}

.u-flexNoWrap {
  flex-wrap: nowrap !important;
}

.u-flexWrapReverse {
  flex-wrap: wrap-reverse !important;
}

/**
 * Wrap for mobiles.
 * Put them after wrap utilities.
 */
.u-flexWrapMobileXL {
  @media (max-width: $screen-mobile-xl) {
    flex-wrap: wrap !important;
  }
}

/**
 * Align items along the main axis of the current line of the flex container
 */

.u-flexJustifyStart {
  justify-content: flex-start !important;
}

.u-flexJustifyEnd {
  justify-content: flex-end !important;
}

.u-flexJustifyCenter {
  justify-content: center !important;
}

.u-flexJustifyBetween {
  justify-content: space-between !important;
}

.u-flexJustifyAround {
  justify-content: space-around !important;
}

/**
 * Align items in the cross axis of the current line of the flex container
 * Similar to `justify-content` but in the perpendicular direction
 */

.u-flexAlignItemsStart {
  align-items: flex-start !important;
}

.u-flexAlignItemsEnd {
  align-items: flex-end !important;
}

.u-flexAlignItemsCenter {
  align-items: center !important;
}

.u-flexAlignItemsStretch {
  align-items: stretch !important;
}

.u-flexAlignItemsBaseline {
  align-items: baseline !important;
}

/**
 * Aligns items within the flex container when there is extra
 * space in the cross-axis
 *
 * Has no effect when there is only one line of flex items.
 */

.u-flexAlignContentStart {
  align-content: flex-start !important;
}

.u-flexAlignContentEnd {
  align-content: flex-end !important;
}

.u-flexAlignContentCenter {
  align-content: center !important;
}

.u-flexAlignContentStretch {
  align-content: stretch !important;
}

.u-flexAlignContentBetween {
  align-content: space-between !important;
}

.u-flexAlignContentAround {
  align-content: space-around !important;
}

/**
 * 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
 */

/* postcss-bem-linter: ignore */

.u-flex > *,
.u-flexInline > * {
  flex-shrink: 1; /* 1 */
}

/* Applies to flex items
   ======================================================================= */

/**
 * Override default alignment of single item when specified by `align-items`
 */

.u-flexAlignSelfStart {
  align-self: flex-start !important;
}

.u-flexAlignSelfEnd {
  align-self: flex-end !important;
}

.u-flexAlignSelfCenter {
  align-self: center !important;
}

.u-flexAlignSelfStretch {
  align-self: stretch !important;
}

.u-flexAlignSelfBaseline {
  align-self: baseline !important;
}

.u-flexAlignSelfAuto {
  align-self: auto !important;
}

/**
 * Change order without editing underlying HTML
 */

.u-flexOrderFirst {
  order: -1 !important;
}

.u-flexOrderLast {
  order: 1 !important;
}

.u-flexOrderNone {
  order: 0 !important;
}

/**
 * Specify the flex grow factor, which determines how much the flex item will
 * grow relative to the rest of the flex items in the flex container.
 *
 * Supports 1-5 proportions
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex
 *    - http://git.io/vllC7
 *
 *    Using `auto`, because 0 (0%) would shrink the items in IE9 and some
 *    webkits
 */

.u-flexGrow1 {
  flex: 1 1 auto !important; /* 1 */
}

.u-flexGrow2 {
  flex: 2 1 auto !important;
}

.u-flexGrow3 {
  flex: 3 1 auto !important;
}

.u-flexGrow4 {
  flex: 4 1 auto !important;
}

.u-flexGrow5 {
  flex: 5 1 auto !important;
}

/**
 * Aligning with `auto` margins
 * http://www.w3.org/TR/css-flexbox-1/#auto-margins
 */

.u-flexExpand {
  margin: auto !important;
}

.u-flexExpandLeft {
  margin-left: auto !important;
}

.u-flexExpandRight {
  margin-right: auto !important;
}

.u-flexExpandTop {
  margin-top: auto !important;
}

.u-flexExpandBottom {
  margin-bottom: auto !important;
}

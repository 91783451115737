/**
 * @define Banner
 *
 * Commercials, banners, etc.
 */

/**
 * The main component. Inside it you can put a banner, a JS call, etc.
 *
 * 1) So that its contents don't stick out of the containing box
 * 2) Without !important it can still be visible (probably due to inline
 *    styles that DFP assigns)
 * 3) In case we want to make it a link
 */
.Banner {
  display: block;
  overflow: hidden; /* 1 */
  max-width: 100%; /* 1 */
  margin: 0 auto 1.25rem;
  text-align: center;
  text-decoration: none; /* 3 */

  @media print {
    display: none !important; /* 2 */
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  // TODO: Copied from `.adv-block a`. Not needed?
  a {
    display: block;
    text-decoration: none;
  }
}

/* Size modifiers
  ========================================================================= */

/**
 * A full-width banner. Gets hidden on small tablet screens in Portrait
 *
 * 1) CROSS-505: apply scaling to fit an 320px Ad (see dfp-mappings-ad-slots.js)
 *    into the 300px container for iPhone 5/5C/SE screens.
 * 2) Hide 728x90 Ad on large tablets since we're not showing there anything
 *    anyway
 */
.Banner--full {
  @media (max-width: $screen-desktop-s - 1) and (min-width: $screen-tablet-l + 1) {
    display: none;  /* 2 */
  }

  @media (max-width: $screen-mobile-xs) {
    transform: scale(0.97);  /* 1 */
    transform-origin: 0 0;  /* 1 */
  }
}

/**
 * A banner of a small-medium size. There is no need in hiding it.
 * Basically, all letter-sized modifiers are for vertical banners (vertically
 * layed out, and in narrow blocks such as grid items and the sidebar)
 *
 * 1) But we are setting the width limit because it can be used outside the
 *    sidebar, and on mobile the sidebar can get 100% wide.
 */
.Banner--SM {
  width: 300px; /* 1 */
}

/**
 * A large-sized vertical banner
 *
 * x) TODO: copying it from the old markup. Is it needed?
 */
.Banner--L {
  width: 420px;

  @media (max-width: $screen-mobile-xl) { /* x */
    display: none;
  }
}

/* Height modifiers.
 * MUST be EXACTLY in sync with ad sizes defined in `dfp-mappings-ad-slots.js`
  ========================================================================= */

/**
 * A sidebar banner
 */
.Banner--WSKY1 {
  height: 600px;

  @media (max-width: $screen-tablet-l - 1) {
    height: 280px;
  }
}

/**
 * LB1: above-the-header banner; banners taking full page width (not just
 * inside .Columns-content)
 */
.Banner--LB1 {
  height: 90px;

  @media (max-width: $screen-tablet-l - 1) {
    height: 100px;
  }
}

/**
 * Wide banners limited by .Columns-content
 */
.Banner--LB2 {
  height: 90px;

  @media (max-width: $screen-desktop-s - 1) {
    display: none;
  }

  @media (max-width: $screen-tablet-l - 1) {
    display: block;
    height: 90px;
  }

  @media (max-width: $screen-tablet-s - 1) {
    height: 100px;
  }
}

/**
 * A fixed-height banner
 */
.Banner--heightML {
  height: 250px;
}

/**
 * A fixed-height banner
 */
.Banner--heightS {
  height: 90px;
}

/* Other modifiers
  ========================================================================= */

/**
 * Vertical gaps modifiers for .Banner.
 * We can't use .Section wrapper here, because if there's no banner and
 * a DFP item would have display: none property,
 * a vertical banner would still exist.
 */
.Banner--gapVertS {
  margin-bottom: 1rem;
}

/**
 * A Banner's modifier with left align & right spacing.
 */
.Banner--alignLeft {
  @media (min-width: $screen-tablet-l) {
    float: left;
    margin-right: 1.25rem;
  }
}

/**
 * The banner above the header
 *
 * 1) Sometimes DFP sets styles on this div, which conflicts with the padding
 *    setting we need to use here (and we can't set the padding on the
 *    .Banner--masthead itself because it would make it visible always).
 *    Overriding those.
 * 2) And we need to limit the size of that ad
 * 3) The inline styles sometimes make this a d:ib. Removing the unnecessary
 *    spacing
 */
.Banner--masthead {
  height: 110px;

  @media (max-width: $screen-tablet-l - 1) {
    display: none;
  }

  > div {
    overflow: hidden; /* 2 */
    width: auto !important; /* 1 */
    height: auto !important; /* 1 */
    max-height: 110px; /* 2 */
    margin: 0 auto;
    padding: 10px 0;
    vertical-align: top; /* 3 */
  }
}

/**
 * A banner that would both be fixed for some "time" and not have empty space
 * below it.
 *
 * 1) So that the content doesn't "float" arount the ad or gets too close to it.
 *    We can afford that since the content part always have the white BG anyway.
 * 2) To prevent the banner container overlapping the content below it.
 * 3) On mobile a 300x100 banner can be used
 */
.Banner--sticky {
  $_scroll-area: 500px;
  $_ad-mtop: 5px;
  $_ad-mbottom: 20px;

  position: relative;
  z-index: 11;
  overflow: visible;
  height: $_scroll-area;
  margin-bottom: -($_scroll-area - 90px - $_ad-mtop - $_ad-mbottom);
  pointer-events: none; /* 2 */

  @media (max-width: $screen-tablet-s - 1) {
    $_scroll-area: 440px;

    height: $_scroll-area;
    margin-bottom: -($_scroll-area - 100px - $_ad-mtop - $_ad-mbottom); /* 3 */
  }

  .Banner-contents {
    top: $header-scroll-height;
    overflow: hidden;
    padding: $_ad-mtop 0 $_ad-mbottom;
    pointer-events: auto; /* 2 */
    background: $white; /* 1 */

    // The same media query as for the sticky header!
    @media (max-width: $screen-tablet-l), (max-height: $screen-tablet-s) {
      top: 0;
    }
  }
}

/**
 * Styles for the in text ad
 * !important is used to override inline styles added by DFP scripts
 */
.Banner--intextAd {
  display: block;
  margin: 0 auto $content-el-mbottom;
  text-align: center;

  div {
    padding: 1rem 0 !important;
    border-top: 1px solid $border-color !important;
    border-bottom: 1px solid $border-color !important;
  }
}

/* The elements
  ========================================================================= */

/**
 * When a Banner only has some static content, like an image inside a link.
 * Mainly need it because without either this (or va:m) the image, which is
 * inline-block by default, would create some line-height-based spacing.
 */
.Banner-img {
  display: block;
  margin: 0 auto;
}

/**
 * This class is for cases when we need .Banner to be not the actual div with
 * an ad, but a wrapper around it. .Banner-contents should be given to the ad
 * div instead then.
 *
 * 1) The wrapper could override this (e.g., for positon: sticky to work).
 */
.Banner-contents {
  position: sticky;
  top: 0.5rem;
  overflow: hidden; /* 1 */

  @media (min-width: 1024px) and (min-height: 780px) {
    top: $header-scroll-height + 0.5rem;
  }
}

/**
 * @define Page
 *
 * The layout for the middle part of the page, for brevity named just Page
 * ("Header, Page, Page-content, Footer" vs "Page-header, Page-middle,
 * Page-footer")
 *
 * Media query values for width must be consistent with the ones from
 * .Container
 */

$_page-sidebar-width: 300px;

/* The component
 * ======================================================================== */

/**
 * The component - the overall wrapper. Can have background, etc.
 *
 * 1) This block should stretch vertically so that the footer's bottom edge is
 *    at least even with the window's bottom edge.
 * 2) On print - don't use any flex, and backgrounds
 *    !important - until removing the old styles
 * 3) To override stuff from modifiers.
 * 4) Cancel space allocation for sticky header.
 * 5) A white background filler under in case the page has a skin banner or
 *    just background
 * 5.1) It needs to be above all background and pseudos
 * 6) Using right + positive translate-x 'cause in IE11 this backplate,
 *    shifted with `left`, would cause a horizontal scrollbar, even with
 *    negative transform-x that should compensate it.
 */
.Page {
  position: relative;
  flex: 1 0 auto; /* 1 */
  background-repeat: no-repeat;
  background-position: center 0;

  @media print {
    display: block; /* 3 */
    border-top: none !important; /* 4 */
    background: $white !important; /* 2, 3 */
    break-before: avoid;
    break-after: avoid;
  }

  &::after { /* 5 */
    position: absolute;
    z-index: 1; /* 5.1 */
    top: 0;
    right: 50%; /* 6 */
    bottom: 0;
    width: 1220px;
    max-width: 100%;
    content: "";
    transform: translate(50%, 0); /* 6 */
    background: $white;
  }
}

/**
 * A modifier for a page with a skin benner
 * TODO: not implemented yet.
 *
 * 1) We don't need any bottom background image. Either put this rule after
 *    all the other modifiers or use !important to override them
 * 2) The entire background area is supposed to be clickable via JS
 * 3) Shift the white filler too.
 */
.Page--skinBannerOn {
  $_page-skin-padding-top: 110px;

  padding-top: $_page-skin-padding-top;
  cursor: pointer; /* 2 */
  background-color: #020001;

  @media (max-width: $screen-desktop-s - 1) {
    padding-top: 0;
    cursor: auto;
  }

  &::before { /* 1 */
    content: none;
  }

  &::after {
    top: $_page-skin-padding-top; /* 3 */
  }

  .Page-inner {
    max-width: 1220px;
  }
}

/* Elements
 * ======================================================================== */

/**
 * The inner part, limited in width. Has "Container" classname as well.
 *
 * 1) not a shorthand `padding: ...` to not mess Container's padding
 * 2) Getting the content above the white-filled pseudo of the parent
 * 3) A white filler behind the content part - in case of a skin banner or if
 *    the page has some bg (Fr1). Not using ::after - it's for clearfix
 * 4) Overriding ::before {d:tb} from .Container's clearfix
 * 5) [Firefox] FF adds side paddings on print 0o
 * x) Overriding corsor prop on body.skin-applied. TODO: after refactoring
 *    the skin banner styles mv inside Page--withSkin
 */
.Page-inner {
  position: relative; /* 2 */
  z-index: 10; /* 2 */
  margin: 0 auto;
  padding-top: 1.25rem; /* 1 */
  padding-bottom: 2.5rem; /* 1 */
  cursor: auto; /* x */
  border-bottom: 1px solid $border-color;
  background: $white; /* 3 */

  @media print {
    padding-right: 0; /* 5 */
    padding-left: 0; /* 5 */
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    break-before: avoid;
    break-after: avoid;
  }

  &:last-child {
    border: none;
  }
}

/**
 * @define Content
 *
 * This component is for blocks that contain user input, such as info blocks,
 * articles WYSIWYG areas, etc.
 * Use cases sometimes differ with just a single element (ul, p, etc.),
 * so modifiers don't always have names descriptive enough
 */

/* Helpers
  ========================================================================= */

/**
 * A placeholder for normal flow elements withoug a leading (hr, table).
 *
 * Using a placeholder because, say, `.Content { hr, table { & + & {}}}` would
 * produce `.Content hr + .Content table {}`, and that wouldn't work.
 *
 * 1) CKEditor might give a table some fixed width. Overriding that.
 * 2) EXCEPTION. Setting top margin is the only way to ensure the gap size
 *    between the block and the preceding text element. Sorry.
 * 3) EXCEPTION. The same, but for cases when the block is followed by some
 *    other block. Sorry.
 */
%_content-block {
  max-width: 100%; /* 1 */
  margin: 1.75rem 0; /* 2 */

  & + & {
    margin-top: 2rem; /* 3 */
  }

  &:first-child {
    margin-top: 0 !important;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  @media (max-width: $screen-mobile-xl) {
    margin: 0.8em 0; /* 2 */

    & + & {
      margin-top: 1.25rem; /* 3 */
    }
  }
}

@mixin _content-items {
  p,
  ul,
  ol,
  li,
  address,
  blockquote,
  cite {
    @content;
  }
}

/* Elements
  ========================================================================= */

%_content-block--compact {
  margin: 0.8em 0;

  & + & {
    margin-top: 1.25rem;
  }
}

/* The default, generic component
  ========================================================================= */

%_content-item {
  margin-bottom: $content-el-mbottom;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

/**
 * Generic styles
 *
 * 1) This block can have ContentAside or some other floated elements inside
 *    it.
 * 2) Having clearfix, the block will have its bottom margin and the floated
 *    child's margin combined. We need to prevent that AND still to have
 *    a regular bottom margin if there is no floated child/it is far at the
 *    top.
 *    WARNING: the value is ContentAside's bottom margin; the trick will
 *    only work if all .Content's floated children have exactly this margin.
 * 3) For the list markers not to go nuts when next to a floated something
 *    TODO: might want to make it a universal rule for lists
 * 4) Blocking inline styles
 */
.Content {
  @include _content-items {
    @extend %_content-item;
  }

  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    display: table;
    clear: both; /* 1 */
    margin: $content-aside-mbottom 0 (- $content-aside-mbottom); /* 2 */
    content: "";
  }

  table,
  hr {
    @extend %_content-block;
  }

  li {
    overflow: hidden; /* 3 */
    list-style: none !important; /* 4 */
  }

  // TODO: move this to base styles?
  small {
    font-size: 80%;
  }
}

/* Stuff inside an Article's header
  ========================================================================= */

/**
 * Used in Product advisory board. (CROSS-205)
 */
.Content--persons {
  img {
    width: auto;
    max-height: 143px;
  }
}

/**
 * Used for Info Pages. (CROSS-261)
 */
.Content--infoPage {
  textarea {
    @extend .Form-control;
    @extend .Form-control--textarea;

    max-width: 520px;
  }
}

.Content--tickLists {
  li {
    @extend %list-tick-item !optional;
  }
}

/* If we need smaller vert gaps between elements
 ========================================================================= */

.Content--compact {
  @include _content-items {
    margin-bottom: 1rem;
  }

  margin-bottom: 1rem;

  .Content-item {
    margin-bottom: 1rem;
  }

  table,
  hr {
    @extend %_content-block--compact;
  }
}

/* Elements
  ========================================================================= */

/**
 * A basic child of a .Content
 * WARNING: in order to avoid maintainance hell DO NOT give this class to
 * something that it to be stored in the databse. Instead, apply it with a JS
 * or a backend content parser.
 */
.Content-item {
  @extend %_content-item;
}

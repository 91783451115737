.cw-Container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.cw-Container--status {
  text-align: center;
}

/** @define CubWidgetOrganization */

/**
 * Organization styles for cub widget
 */
.cub-Organization {
  @include decoration-box;

  max-width: $cub-width;

  > :last-child {
    margin-bottom: 0 !important;
  }

  &.is-unverified {
    border-top-color: $yellow;
  }

  &.is-declined {
    border-top-color: $red-bright-hover;
  }
}

.cub-AddOrganization {
  @include decoration-box;

  max-width: $cub-width;
}

.cub-Organization-header {
  padding-bottom: 1rem;
  border-bottom: 1px solid $border-color;
}

.cub-Organization-name {
  @include font-family($font-family-alt);

  color: inherit;
  font-size: $font-size-ml;
}

.cub-Organization-name,
.cub-Organization-address,
.cub-Organization-phone {
  margin-bottom: 0.3125rem;
}

.cub-MemberPosition-positionInfo,
.cub-MemberPosition-unitInfo,
.cub-MemberPosition-periodInfo {
  margin-bottom: 0 !important;
}

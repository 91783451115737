.cw-StatusMsg {
  display: block;
  color: $red-bright-hover;
  font-size: $font-size-s;
  line-height: 1.5;
}

.cw-StatusMsg--bottom {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.cw-StatusMsg--info {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.cw-StatusMsg--hint {
  color: #ccc;
}

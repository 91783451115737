#sponsor-about {
  overflow: hidden;
  margin: -1px 0 20px;

  h2 {
    margin-bottom: 17px;
    text-transform: capitalize;
  }

  .list-link {
    margin-bottom: 19px;
  }
}

/**
 * @define ContentAside
 *
 * A component for insets (usually floated) into main content items (articles,
 * ect). A container for images, figcaptions and other inset types, as well as
 * "Related..." blocks.
 * It's basically meant to be inside content items created by users (.Content)
 * that's why the name has "Content" in it.
 */

/**
 * A usual, left-floated variant
 */
.ContentAside {
  display: block;
  float: left;
  clear: left;
  width: 300px;
  margin: $content-aside-mtop $content-aside-mside $content-aside-mbottom 0;

  @media (max-width: $screen-mobile-xxxl) {
    float: none;
    width: auto;
    margin-right: 0;
  }
}

/**
 * Image size modifier - "small" images.
 * In some cases it makes sense to have an inset of a floated image (w/o
 * Byline and the like), e.g. About Us on Advertise pages. But the image is to
 * be smaller than 300px. Plus, in CROSS-421 PD told they wanted smaller
 * images, which is not doable with just making this component's width fluid.
 * So here is an attempt to solve all that. Absolutely fluid-width solution
 * won't work 'cause responsive images, physical pixels and browsers shrinking
 * Let's see how it works.
 */
.ContentAside--s {
  width: 150px;

  @if $is-debug == false { // TODO: remove after testing attr solution
    .ContentAside-img {
      min-width: 140px; /* 1 */
    }
  }
}

/**
 * Right-floated modifier
 */

.ContentAside--right {
  float: right;
  clear: right;
  margin-right: 0;
  margin-left: $content-aside-mside;

  @media (max-width: $screen-mobile-xxxl) {
    float: none;
    margin-left: 0;
  }
}

/**
 * A modifier for full, 100% width block with vert borders
 */
.ContentAside--fullWidth {
  float: none;
  clear: both;
  width: auto;
  margin-right: 0;
  margin-left: 0;

  .ContentAside-imgBlock {
    text-align: center;
  }

  .ContentAside-img {
    margin-right: auto;
    margin-left: auto;
  }
}

/**
 * A modifier for a block with borders
 */
.ContentAside--borders {
  padding: 1rem 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: $border-color;
}

/**
 * A modifier for mobile view. It's almost the same as --fullWidth modifier.
 */
.ContentAside--mobileXXXL {
  @media (max-width: $screen-mobile-xxxl) {
    float: none;
    clear: both;
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }

  .ContentAside-imgBlock {
    @media (max-width: $screen-mobile-xxxl) {
      text-align: center;
    }
  }

  .ContentAside-img {
    @media (max-width: $screen-mobile-xxxl) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

/**
 * A modifier for 50% width block with vert borders
 * Example: Manufacturer template
 */
.ContentAside--halfWidth {
  width: 50%;
  margin-top: 0;

  @media (max-width: $screen-mobile-xxxl) {
    width: 100%;
  }
}

/* Elements
 ========================================================================= */

/**
 * Just a convenience styles, in case if the image needs to be inside
 * something, like a link or a <picture> element
 */
.ContentAside-imgBlock {
  display: block;
  margin-bottom: 0.4rem;

  @media (max-width: $screen-mobile-xxxl) {
    text-align: center;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * This is supposed to be an image (or sth similar) in <figure>s
 *
 * 1) Not just to prevent images to accidentaly stack horizontally, but also
 *    to prevent auto adding <br type="_moz"> in CKEditor by Firefox (it does
 *    so when a user clicks on the img or places caret next to is, if the img
 *    is d:ib and is the only child of a block)
 * 2) For alt text to look neater.
 */
.ContentAside-img {
  display: block; /* 1 */

  @if $is-debug == false { // TODO: remove after testing attr solution
    min-width: 300px;
  }

  max-width: 100%;
  margin-bottom: 0.4rem;
  font-size: $font-size-s; /* 2 */

  @media (max-width: $screen-mobile-xxxl) {
    margin-right: auto;
    margin-left: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.ContentAside-imgCaption {
  display: block;
  margin-bottom: 0.4rem;
  color: $text-color;
  font-size: $font-size-s;

  &:last-child {
    margin-bottom: 0;
  }
}

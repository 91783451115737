/**
 * @define Table
 *
 * The Table component can be used with both actual tables and non-table
 * markup. Just like with any other SUIT component, in both cases ALL elements
 * NEED corresponding CLASSES. For example, you can't just have table.Table
 * with bare tr, td inside it. The reasons for that are 1) we have default
 * styles for th/td/etc. tags, and 2) browsers may have their own default
 * styles.
 * The ONLY exception is bare tables inside user-edited areas, they can't have
 * classes by design. For this case we define SOME tag styles inside these
 * components, and mainly for responsive means.
 */

/* Helpers
 ========================================================================= */

$_table-cell-padding-vert: 0.5rem;
$_table-cell-padding-horz: 0.5625rem;
$_table-caption-size: 0.4;

/* The component
 ========================================================================= */

/**
 * A usual table
 *
 * 1) Overriding our defaults (table)
 */
.Table {
  display: table;
  width: 100%;
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  border: none; /* 1 */
  border-collapse: collapse;
}

/**
 * A table with darker-gray heading and lighter-gray even rows
 * E.g.: Grants table (PGH homepage)
 * TODO: are Special/highlighted rows needed here?
 *
 * 1) There is a requirement that the first row should always have gray bg, be
 *    it a table head or the first row with data. And a table can either have
 *    a thead/Table-head or not. So had to cover possible Table-body positions
 *    and Table-row inside it.
 */
.Table--stripes {
  $_table-stripes-head-color: #d1d1d1; // TODO: CROSS-1352

  .Table-row {
    &:hover {
      background: none;
    }
  }

  .Table-head {
    border-top: 1px solid $white;

    &,
    &:hover {
      color: $text-color;
      background: $_table-stripes-head-color;
    }
  }

  .Table-header {
    color: $text-color;
    background: $_table-stripes-head-color;
  }

  > .Table-row:nth-child(2n) { /* 1 */
    background: $gray-light;
  }

  .Table-body {
    &:nth-child(2n) > .Table-row:nth-child(2n),
    &:nth-child(2n+1) > .Table-row:nth-child(2n+1) { /* 1 */
      background: $gray-light;
    }
  }
}

/**
 * A table with spacings fixes placed in the Box
 * E.g.: Grants List (PGH-193)
 * https://praetoriangroup.atlassian.net/browse/PGH-193
 * ?focusedCommentId=199486&page=com.atlassian.jira.plugin.system.
 * issuetabpanels:comment-tabpanel#comment-199486
 * 1) use corrected spacings to compensate the spacings of the .Box
 */

.Table--gapS {
  margin-top: -0.8125rem; /* 1 */

  .Table-cell {
    padding: 0.5rem 0.3725rem; /* 1 */
  }
}

/**
 * Sometimes WYSIWYG tables with many columns (5 and more) can grow too wide
 * tbl:a. So we'll be adding this class to them as well via JS.
 */
.Table--fixed {
  table-layout: fixed;
}

/**
 * A table with borders.
 * Most of the tables that are defined in the code (Jobs, Companies, etc.)
 * don't need any borders since the tables themselves and content that surrounds
 * them are designed so that the tables could stand out. Yet tables created by
 * a user (in a WYSIWYG) almost always need borders, especially if a table
 * doesn't for some reason doesn't have headers.
 */
.Table--borders {
  border-collapse: collapse;
  border-top: 1px solid $border-color;

  .Table-cell,
  .Table-header {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $border-color;
  }

  .Table-header {
    border-color: $white;

    &:first-child {
      border-left-color: $table-head-bg;
    }

    &:last-child {
      border-right-color: $table-head-bg;
    }
  }
}

.Table--lines {
  .Table-head,
  .Table-row {
    border-width: 0 0 1px;
    border-style: solid;
    border-color: $border-color;
  }

  .Table-row {
    &:last-child {
      border: none;
    }
  }
}

/**
 * A table without the hover effect on its rows
 * TODO: make THIS the default and only apply hover effects when needed? like
 * if a table row is a link
 */
.Table--plainRows {
  .Table-row {
    &:hover {
      background: none;
    }
  }

  .Table-row--highlight {
    &,
    &:hover {
      background: $gray-light;
    }
  }
}

/* Responsive modifiers
 ========================================================================= */

/**
 * These make the table cells expand for full witdh on specified display sizes
 * Applies to both in-code tables (the ones with CSS classes) and WYSIWYG
 * tables which have certain classes added to their elements via JS.
 *
 * 1) All items beside td, th, Table-cell are needed for this to work in older
 *    Webkits
 * 2) Resetting styling from Table--stripes
 * 3) Fallback for non-flex browsers
 * 4) WYSIWYG tables are processed with JS and the .Table-cell class is added
 *    to their cells, so no need for a `td` selector here.
 * 5) Use .Table-row--noHover if you don't need to highlight the first cell in
 *    such a row.
 * x) TODO: Reconsicer styling for collapsed mobile tables? Like: the first cell
 *    of a mobile table would be gray (links stay as-is), for the highlighted
 *    rows it would be a color-themed (blue for P1, etc.)
 *    This structure adds way too much specificity and nesting - specifically
 *    because of the need to include generic links. We could replace generic
 *    `a` with `.Table-link`, but that way we'd leave user-added linkd in
 *    WYSIWYG tables unstyled. On the other hand, table cells with gray bg
 *    wouldn't need links to have some special styling, and thus we could break
 *    this block into `.Table-cell:first-child { ... }`,
 *    `.Table-row--noHover > .Table-cell {...}`
 */

@mixin table-make-full-width {
  display: block;

  thead,
  tbody,
  caption,
  .Table-body,
  .Table-caption,
  .Table-head,
  .Table-row, /* 1 */
  tr {
    display: block;
    width: auto;
  }

  .Table-header,
  .Table-cell,
  td,
  th {
    display: block; /* 3 */
    display: flex;
    width: auto;
    text-align: left;
  }

  .Table-row {
    &,
    &:hover {
      color: inherit;
      background: none !important; /* 2 */
    }
  }

  .Table-row--highlight { /* x */
    &,
    &:hover {
      color: $table-row-highlight-color;
      background: $table-row-highlight-bg !important; /* 2 */
    }

    .Table-link,
    .Table-icon {
      color: inherit;
    }
  }

  .Table-row:not(.Table-row--noHover) { /* 5, x */
    > .Table-cell:first-child { /* 4 */
      color: $white;
      border-color: $table-head-bg;
      background: $table-head-bg;
      font-weight: 700;

      a, /* x */
      .Table-link {
        text-decoration: underline;
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .Table-cell:empty {
    display: none;
  }

  .Table-cellCaption {
    display: block;
  }

  .Table-row--hideCollapsed,
  .Table-header--hideCollapsed {
    display: none;
  }
}

/* 768px and below */
.Table--blockTabletS {
  @media (max-width: $screen-tablet-s) {
    @include table-make-full-width;
  }
}

/* 640px and below */
.Table--blockMobileXXXL {
  @media (max-width: $screen-mobile-xxxl) {
    @include table-make-full-width;
  }
}

/* 560px and below */
.Table--blockMobileXXL {
  @media (max-width: $screen-mobile-xxl) {
    @include table-make-full-width;
  }
}

/* 480px and below */
.Table--blockMobileXL {
  @media (max-width: $screen-mobile-xl) {
    @include table-make-full-width;
  }
}

/* 414px and below */
.Table--blockMobileL {
  @media (max-width: $screen-mobile-l) {
    @include table-make-full-width;
  }
}

/* Elements - containers
 ========================================================================= */

/**
 *
 * 1) To be able to use non-table markup
 */
.Table-caption {
  @include titlebar-basis;

  display: table-caption; /* 1 */
  margin: 0;
  border-bottom: 1px solid $white;
}

/**
 * <thead> or an element in its role
 *
 * 1) To be able to use non-table markup
 * 2) Overriding our default th styles
 */
.Table-head {
  display: table-header-group; /* 1 */
  text-transform: none;
  color: $table-head-color;
  border-bottom: 1px solid $white;
  background: $table-head-bg;
  font-weight: 700;
}

/**
 * <tbody> or element represeiting it
 *
 * 1) To be able to use non-table markup
 */

.Table-body {
  display: table-row-group; /* 1 */
}

/**
 * A usual table footer
 */
.Table-footer {
  display: table-footer-group;
}

/**
 * In case we need full-width cell of a table.
 * CSS doesn't allow setting something like `colspan` on a d:tbc element, so
 * to have it 100% wide we have to use a hack with display: table-caption, as
 * it can be 100% wide and have arbitrary content
 * .Table-cell inside this one is not needed
 */

/* Example markup:
  <div
    class="Table-footer Table-footer--fullWidth u-textCenter"
    data-load-more-target
  >
    <a
      class="Button Button--l"
      data-load-more-trigger data-ajax-target="#sponsors-list"
      data-history-update href="..."
    >
      <span class="Button-icon">
        <i class="Icon Icon--arrows-cw"></i>
      </span>
      Load more sponsors
    </a>
  </div>
 */
.Table-footer--fullWidth {
  display: table-caption;
  padding: $_table-cell-padding-vert $_table-cell-padding-horz;
  caption-side: bottom;
}

/**
 * Table row, <tr>
 *
 * 1) To be able to use non-table markup
 * 2) To allow links play a role of table rows (so that the whole row could be
 *    clickable). I.e., overriding a:hover.
 */

.Table-row {
  display: table-row; /* 1 */
  vertical-align: top;

  &,
  &:hover { /* 2 */
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    background: $gray-light;
  }
}

/**
 * Table row without hover effects
 * Used in <thead> and <tfoot> substitutes and in some special cases
 *
 * 1) ! is to override bg added by possible modifiers
 */
.Table-row--noHover {
  &,
  &:hover {
    background: transparent !important; /* 1 */
  }
}

/**
 * A row that is highlighted in a special manner.
 * Use cases are featured elements.
 *
 * NOTICE. Initially the highlighted rows were styled with a site's color
 * scheme's colors (blue-ish for P1, etc.). Later on some of them were replaced
 * with gray backgrounds and some time after those colored rows were removed
 * altogether. We're keeping the colors just in case; if you need them back,
 * create a .Table modifier. Just don't forget to update the mixin for
 * responsive tables
 */
.Table-row--highlight {
  background: $gray-light;
}

/* Elements - cells
 ========================================================================= */

/**
 * <th> or element represeiting it
 *
 * 1) To be able to use non-table markup
 * 2) IE 10 doesn't inherit text-align from <table> here
 * 3) Reset our defaults (th), and possible browser defaults (e.g., Firefox
 *    gives some border for th, td inside table with border="...")
 */

.Table-header {
  display: table-cell; /* 1 */
  padding: $_table-cell-padding-vert $_table-cell-padding-horz;
  text-align: left; /* 2 */
  vertical-align: top;
  color: $white; /* 3 */
  border: none;
  background: $table-head-bg; /* 3 */
  font-weight: 700;
}

.Table-header--2of5 {
  width: 20%;
}

/**
 * Experimental: it's not a bad idea for table columnst to have fixed width
 * so that it doesn't leap on dynamic content loading. Let's try and see how
 * this kind of classnames go
 */
.Table-header--10 {
  width: 10%;
}

.Table-header--15 {
  width: 15%;
}

.Table-header--20 {
  width: 20%;
}

.Table-header--25 {
  width: 25%;
}

.Table-header--30 {
  width: 30%;
}

.Table-header--35 {
  width: 35%;
}

/**
 * A class for a "table cell" element
 *
 * 1) Reset our defaults (th), and possible browser defaults (e.g., Firefox
 *    gives some border for th, td inside table with border="...")
 */
.Table-cell {
  display: table-cell;
  padding: $_table-cell-padding-vert $_table-cell-padding-horz;
  border: none; /* 1 */
  background: none; /* 1 */
  overflow-wrap: break-word;
}

.Table-cell--1of4 {
  width: 25%;
}

/**
 * If a table cell needs space for an icon at the start
 *
 * 1) Making the caption align well with captions from the neighbors
 */
.Table-cell--wIcon {
  $_shift: 1.5rem;

  position: relative;
  padding-left: $_table-cell-padding-horz + $_shift;

  .Table-icon {
    position: absolute;
    top: $_table-cell-padding-vert;
    left: $_table-cell-padding-horz;
    color: $red-link;
  }

  .Table-cellCaption {
    margin-right: $_shift * $_table-caption-size; /* 1 */
    margin-left: -$_shift; /* 1 */
    padding-left: $_shift; /* 1 */
  }
}

/**
 * If a table cell has a numbers inside
 *
 * 1) Align numbers to the right
 */
.Table-cell--number {
  text-align: right;
}

/* Elements inside table cells
 ========================================================================= */

/**
 * An element for a caption text inside a table cell
 * This element is added by JS (or by backend code), and it's needed for
 * collapsed state of a table. Thus it's hidden by default.
 * Not using a pseudo with `content: attr(data-th)`, because such pseuto will
 * occupy space even if the attribute is emtpy/missing.
 */
.Table-cellCaption {
  display: none;
  flex: 0 0 auto;
  width: $_table-caption-size * 100%;
  padding-right: 0.625rem;
}

/**
 * We need the ability to manipulate the looks of links inside a table; having
 * an element for this is the most SUIT way. Otherwise it'd either be a nested
 * generic `a` element, or a nested class of a different component/util.
 */
.Table-link {
  text-decoration: none;
  color: $link-color;

  @include hover {
    text-decoration: underline;
    color: $link-hover-color;
  }
}

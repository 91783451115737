/**
 * @define Embed
 *
 * A component for external objects like iframes from video services (Vimeo,
 * etc), Twitter, etc.
 */

/**
 * The component
 * WARNING: it could be hard to use a class on an Iframe.ly item, inserded by
 * a CKEditor widged, by default. And not necessary (another class in edited
 * content -> better chance to screw up). So AS AN EXCEPTION using a built-in
 * attr to style here. We could place it in `vendor`, of cource, but
 * it's more convenient to have it here, alongside the modifiers made for it.
 * TODO: refine Facebook's alignment health-fitness/articles/373509006
 *
 * 1) To prevent the block from overlapping floated neighbours (Related
 *    content)
 */
.Embed,
[data-oembed-url] {
  overflow: hidden; /* 1 */
  min-width: 220px;
  max-width: 100%;
  margin: 0 auto 1.375rem;

  @media (max-width: $screen-mobile-xl) {
    margin-bottom: 0.75rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/* Align/position modifiers
 ========================================================================= */

.Embed--left {
  float: left;
  max-width: 500px;
  margin-right: 1rem;
  margin-left: 0;
}

.Embed--right {
  float: right;
  max-width: 500px;
  margin-right: 0;
  margin-left: 1rem;
}

/* Size modifiers
 ========================================================================= */

.Embed--xs {
  max-width: 240px;
}

.Embed--s {
  max-width: 360px;
}

.Embed--m {
  max-width: 480px;
}

.Embed--l {
  max-width: 720px;
}

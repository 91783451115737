/**
 * Corrections1-specific variables
 *
 * Most of the variables are defined in <megaton-dir>/Ivelum.Shared/Tone.Web/
 * (the file imported below). Here are only the variables that need to be
 * overriden
 *
 * !! DON'T USE the "!default" keyword here
 */

// Theme color types
// Grays. Differ from the Style Guides, Approved here:
// https://praetoriangroup.atlassian.net/browse/PGH-228
$gray-dark: #262626;
$gray-medium: #5a5a5a;
$gray-light: #efefef;

// colors

$blue-dark: #000;
$blue-dark-2: #4a0000;
$blue-header: #143659;

$blue-link: #750000;
$blue-bright-hover: #cc001e;

$yellow: #febf01;

$red-bright-hover: #a60a0b;

$red-link: #971b23;

// Global color for gray borders, hr-s, etc.
$border-color: $gray-medium;

$image-bg: $black;

// Global textual link color.
$link-color: $blue-link;
$link-hover-color: $blue-bright-hover;

// Special links (like .Link; .List--inline .List-link, etc).
$link-special-color: $blue-link;
$link-special-hover-color: $blue-bright-hover;

// Darker links (E.g., counters in Top-5)
$link-dark-color: $blue-link;

/* Components
 ======================================================================= */

// Icon
$icon-highlight-color: $blue-bright-hover;

// variables for cub widget
$cub-heading-bg: $black;
$cub-menu-color: $white;
$cub-menu-hover-color: $white;
$cub-menu-bg: $blue-link;
$cub-error-color: $link-hover-color;
$cub-info-bg: $gray-medium;
$cub-error-bg: $blue-bright-hover;

// buttons
$btn-bg: $blue-link;
$btn-bg-hover: $blue-bright-hover;
$btn-icon-bg: $blue-dark-2;
$btn-icon-bg-hover: $blue-link;

$box-themed-bg: $blue-dark;
$box-themed-color: $white;

// Heading
$heading-sm-color: $black;

// Pagination
$pagination-bg-active: $link-color;
$pagination-border-active: $blue-link;

// TitleBar
$titlebar-bg: $black;
$titlebar-icon-bg: $link-color;
$titlebar-hover-bg: $link-color;
$titlebar-hover-icon-bg: $blue-bright-hover;
// -warning- variables are used on the Modal-title--warning
$titlebar-warning-bg-color: $blue-dark;
$titlebar-warning-icon-bg-color: $link-hover-color;
$titlebar-warning-hover-icon-bg-color: $link-color;
$titlebar-is-include-badge-styles: false;

// "Editor's note"
$callout-ribbon-bg: $blue-link;
$callout-ribbon-back-bg: #121212;

// Modal
$modal-bg: $black;
$modal-icon-bg: $link-color;
$modal-hover-icon-bg: $blue-bright-hover;
// -warning- variables are used on the Modal-title--warning
$modal-warning-bg: $blue-dark;
$modal-warning-icon-bg: $link-hover-color;
$modal-warning-hover-icon-bg: $link-color;

// Table
// <th>
$table-head-bg: $blue-link;
$table-head-color: $white;
// <tr>
// highlighted
$table-row-highlight-bg: $blue-dark-2;
$table-row-highlight-color: $white;

// Forms
// $form-focus-color: $blue-link;
$form-themed-color: $white;
$form-themed-bg: #6b7073;
$form-themed-text-dedicated-color: inherit;
$form-themed-text-special-color: inherit;
$form-themed-input-borders: $blue-link;
$form-subscription-bg: $black;

// 500.html
$page500-h1-color: $blue-link;

// Logo
// $footer-logo-width: 141px;

// Footer
$footer-top-bg: $blue-dark;
$footer-top-line-color: #121212;
$footer-bottom-bg: $blue-dark;

$footer-color: #989797;
$footer-hover-color: $white;

$footer-menu-color: $white;
$footer-menu-hover-color: $blue-bright-hover;
$footer-menu-margin-lg: 10px;
$footer-menu-border-sm: #4d4d4d;
$footer-menu-bg-sm: $footer-top-line-color;

$footer-menu-color-mobile: $footer-menu-color;
$footer-menu-active-color-mobile: $footer-menu-hover-color;
$footer-menu-hover-color-mobile: $footer-menu-hover-color;

// Header New
$header-new-bg: $black;
$header-new-top-ad-bg: $black;
$header-new-top-link-hover-color: $yellow;
$header-new-logo-bg: #1a1a19;
$header-new-usermenu-bg: #711718;
$header-new-cub-menu-hover-color: $yellow;
$header-new-bottom-bg-theme-image-opacity-option: 0.3;

// Header Menu
$menu-new-item-hover-color: $blue-bright-hover;
$menu-new-item-color-mobile: $white;

$menu-submenus-bg: $black;
$menu-submenu-bg: $blue-link;
$menu-submenu-border-color: $blue-link;

$menu-submenu-link-l1-hover-bg: $blue-link;
$menu-submenu-link-l2-color: $white;
$menu-submenu-link-l2-hover-bg: $blue-bright-hover;

// Main Menu for Tablet Screens
$header-menu-toggle-bg: none;
$header-menu-toggle-active-color: $white;
$header-menu-toggle-active-bg: $blue-bright-hover;

$header-rebrand-logo-width: 262px;

$menu-submenu-bg-tablet: $blue-link;
$menu-submenu-brd-color-tablet: $black;

$menu-item-bg-tablet-hover: $blue-bright-hover;
$menu-item-brd-color-tablet: $gray-dark;

// Headline
// 1) Special Report, Member Update
$headline-bg: $gray-medium;
$headline-title-bg: $black;
$headline-btn-bg-hover: $gray-dark;

// 2) Breaking News
$headline-bg-danger: $blue-bright-hover;
$headline-title-bg-danger: $blue-link;
$headline-btn-bg-hover-danger: $red-link;

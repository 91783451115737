/**
 * @define Menu
 */

// Menu list (ul)
.Menu {
  margin: 0;
  font-weight: 500;
}

// Main Menu in Header
.Menu--header {
  margin: 0 1rem;
  white-space: nowrap;

  @media (max-width: $screen-tablet-l) {
    margin: 0;
    white-space: normal;
  }

  .Menu-item {
    @media (max-width: $screen-tablet-l) {
      transition: background-color 0.7s ease;
      border-bottom: 1px solid $menu-item-brd-color-tablet;
    }

    &.active,
    &.active:hover,
    &:hover,
    &.sfHover,
    &.dropdown-show {
      @media (max-width: $screen-tablet-l) {
        transition: background-color 0.2s ease;
        color: $menu-new-item-color-mobile;
        background: $menu-item-bg-tablet-hover;
        font-weight: normal;
      }
    }
  }
}

// Simple version, that visible on mobile menu
.Menu--simple {
  .Menu-item {
    color: $white;
  }
}

/**
 * Menu item (li)
 *
 * 1) Otherwise it's too bold on Mac
 * 2) Resetting <li> styles. This one must have pos:s for the submenu to be
 *    able to expand beyond it.
 * 3) If JS is disabled, show the submenu on the item hover
 */
.Menu-item {
  position: static; /* 2 */
  display: inline-block;
  margin: 0;
  padding: 0; /* 2 */
  color: $menu-new-item-color;

  @media (max-width: $screen-tablet-l) {
    display: block;
  }

  &::before {
    content: none; /* 2 */
  }

  &:hover {
    .Menu-submenusBlock {
      display: block; /* 3 */
    }
  }

  &:hover,
  &.sfHover,
  &.dropdown-show,
  &.active,
  &.active:hover {
    color: $menu-new-item-hover-color;
  }
}

/**
 * A link inside the menu item
 *
 * 1) For .Menu-linkCaret to be aligned correctly
 * 2) Header has a border, because of that Menu-submenusBlock have a shift
 *    equal to its width. That creates a spacing between where the Menu-item
 *    ends and the submenuBlock starts. If a user moves a cursor throught that
 *    spacing, the Menu-item's hover gets lost making the submenu hide. To
 *    prevent that we create a transparent pseudo with height equal to Header
 *    border-bottom height. Not on the .Menu-item (too tricky, since it can't
 *    be `position`ed), but on the link inside it.
 *    All that to make submenus work without JS.
 */
.Menu-link {
  @include font-family($font: $font-family-header, $caps: true);

  display: block;
  padding: 2rem 0.5rem;
  text-decoration: none;
  color: inherit;
  line-height: 1;

  @media (max-width: $screen-tablet-l) {
    position: relative; /* 1 */
    padding: 1rem 0.625rem;
    line-height: 1.25;
  }

  // Overriding the default <a> styles
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.Menu-link--wIcon {
  position: relative;
  padding-left: 1.9rem;

  @media (max-width: $screen-tablet-l) {
    padding-left: 2.1rem;
  }
}

/**
 * If there should be a custom icon next to a menu link.
 * Place a correspondent span.Icon inside this for an actual icon.
 */
.Menu-linkIcon {
  position: absolute;
  top: 50%;
  left: 0.5em;
  transform: translateY(-50%);

  @media (max-width: $screen-tablet-l) {
    left: 0.625em;
  }
}

/**
 * The caret next to a links or under it that is wisible if there is a submenu
 * under this menu item.
 */
.Menu-linkCaret {
  @extend .Icon--angle-down !optional;

  display: none;
  font-family: $icon-font;

  @media (max-width: $screen-tablet-l) {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    width: 60%;
    height: 100%;
    padding-right: 1rem;
    transition: transform 0.3s ease;
    color: $white;
    align-items: center;
  }

  &::before {
    @media (max-width: $screen-tablet-l) {
      transition: inherit;
      transform: rotate(0deg);
    }
  }

  .dropdown-show & {
    &::before {
      @media (max-width: $screen-tablet-l) {
        transform: rotate(180deg);
      }
    }
  }
}

// TODO: This part of code isn't refactored yet.
// Refactor it with Mobile Menu update.
/**
 * Top container for submenus (div)
 */
.Menu-submenusBlock {
  position: absolute;
  top: auto;
  left: 0;
  overflow: hidden; // Use overflow to correctly animate the submenu height
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: default;
  white-space: normal;
  border: none;
  border-top: 1px solid $color-dusty-gray;
  border-radius: 0;
  background: $menu-submenus-bg;
  box-shadow: none;
  font-weight: normal;

  @media (max-width: $screen-tablet-l) {
    position: relative;
    top: 0;
    left: 0;
    display: none !important; // *1
    overflow: hidden;
    width: 100%;
    height: auto;
    margin: 0;
    cursor: default;
    text-transform: none;
    border: none;
    background: $link-color;
    line-height: 18px;
  }

  &::before {
    @media (max-width: $screen-tablet-l) {
      content: none;
    }
  }

  .dropdown-show & {
    @media (max-width: $screen-tablet-l) {
      display: block !important;
    }
  }

  &:hover {
    display: block;
  }
}

// It's used with .sf-mega class
.Menu-submenusBlock,
.Menu-submenuGroup {
  display: none;
}

// Contains all submenus (div)
.Menu-submenusContainer {
  position: relative;
  overflow: hidden;
  width: 970px;
  height: auto;
  margin: 11px auto 0;

  @media (min-width: $screen-tablet-l + 1) {
    display: block !important;
  }

  @media (max-width: $screen-tablet-l) {
    width: 100%;
    margin: 0;
    padding: 0;
    border-top: 1px solid $menu-submenu-brd-color-tablet;
    background: $menu-submenu-bg-tablet;
  }
}

.Menu-submenuGroup {
  width: auto;
  padding: 0 0 10px 0;
  background: $menu-submenu-bg;

  @media (max-width: $screen-tablet-l) {
    display: none !important;
  }
}

/**
 * Submenu (ul) of any level
 *
 * 1) Some links have ::before as hover background, and without a stacking
 *    context here they won't be seen
 * 2) Overriding our default styles for lists inside lists
 */
.Menu-submenuItems {
  position: relative;
  z-index: 1; /* 1 */
  margin: 0; /* 2 */
  padding-left: 0;
  list-style-type: none;
  text-transform: none;
}

.Menu-submenuItems--1Level,
.Menu-submenuItems--2Level {
  display: block !important; // *1
}

.Menu-submenuItems--1Level {
  float: left;
  overflow: hidden;
  box-sizing: border-box;
  width: 239px;
  min-height: 169px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-left: 1px solid $menu-submenu-border-color;

  @media (max-width: $screen-tablet-l) {
    position: relative;
    float: none;
    width: 100%;
    min-height: inherit;
    margin: 0;
    padding: 0;
    border: none;
  }
}

.Menu-submenuItems--2Level {
  position: relative;
  left: 0;
  display: block;
  float: none;

  .Menu-submenuLink {
    margin: 0;
    padding: 4px 10px 4px 40px;
    line-height: 1;
  }
}

/**
 * Submenu item
 *
 * 1) Resetting <li> styles
 * 2) To make deep sumbenus work without JS
 */
.Menu-submenuItem {
  margin-bottom: -1px;
  padding: 0; /* 1 */
  list-style-type: none;
  transition: all 0.7s ease;
  color: $white;

  &::before { /* 1 */
    content: none;
  }

  &:hover {
    .Menu-submenuGroup {
      display: block; /* 2 */
    }
  }

  &.sfHover > .Menu-submenuLink,
  &.active > .Menu-submenuLink,
  &.dropdown-show > .Menu-submenuLink,
  > .Menu-submenuLink:hover {
    transition-duration: 0.2s;
    color: inherit;
    font-weight: normal;

    .Menu-submenuIcon {
      opacity: 1;
    }
  }
}

.Menu-submenuItem--1Level {
  margin: 0 11px 0 0;

  @media (max-width: $screen-tablet-l) {
    margin: 0;
    color: $white;
  }

  // TODO: too many classes on a <li> to trigger the link's hover. To revise
  &.sfHover .Menu-submenuLink--1Level,
  &.active .Menu-submenuLink--1Level,
  &.dropdown-show .Menu-submenuLink--1Level,
  .Menu-submenuLink--1Level:hover {
    color: inherit;
    background: $menu-submenu-link-l1-hover-bg;

    @media (max-width: $screen-tablet-l) {
      color: $menu-submenu-link-l2-color;
      background: $menu-submenu-link-l2-hover-bg;
    }
  }
}

.Menu-submenuItem--2Level {
  /**
   * Submenu links of the 2nd level
   */
  &.sfHover .Menu-submenuLink,
  &.active .Menu-submenuLink,
  &.dropdown-show .Menu-submenuLink,
  .Menu-submenuLink:hover {
    color: $menu-submenu-link-l2-color;
    background: $menu-submenu-link-l2-hover-bg;
  }
}

// Submenu item (a)
.Menu-submenuLink {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 0 0 25px;
  transition: inherit;
  text-decoration: none;
  color: inherit;
  font-size: 12px;
  line-height: 28px;

  // Overriding the default <a> styles
  &:hover {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: $screen-tablet-l) {
    padding: 0;
    font-size: $font-size-sm;
  }
}

.Menu-submenuIcon {
  @extend .Icon--right-open !optional; // *2

  position: absolute;
  z-index: 100;
  top: 0;
  left: 9px;
  display: block;
  width: 6px;
  height: 9px;
  transition:
    left 0.3s ease,
    transform 0.3s ease,
    border-color 0.3s ease;
  color: inherit;
  font-family: $icon-font;
  font-size: 11px;
  line-height: inherit;

  @media (max-width: $screen-tablet-l) {
    display: none;
  }
}

.Menu-submenuLink--1Level {
  @media (max-width: $screen-tablet-l) {
    position: relative;
    overflow: hidden;
    padding: 9px 15px;
    transition: all 0.7s ease;
    color: inherit;
    font-size: $font-size-sm;
    line-height: 17px;
  }
}

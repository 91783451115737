/** @define CubWidgetControl */

/**
 * Control styles for cub widget
 * 1) Add gap to override :last-child { margin: 0; } rule for the Grant sites.
 *    This gap is needed to compensate the negative margin of an outer wrapper.
 */
.cub-FormControl {
  color: $cub-control-text-color;
  font-size: inherit;
}

.cub-FormControl--combobox {
  padding: 0 !important;
}

.cub-FormControl--input,
.cub-FormControl--select,
.cub-FormControl--textarea {
  @include form-control;

  &:focus {
    @include focused;
  }
}

.cub-FormControl--textarea {
  height: 6rem;
  resize: vertical !important;
}

/**
 * Checkbox and radio labels.
 */
.cub-FormControl--checkbox,
.cub-FormControl--radio {
  margin-top: 0.25em;
}

// TODO: probably temporarily, LID guys should fix this
.cub-FormControl-inputIcon {
  display: block;
  padding-right: 2rem;

  .cub-Icon {
    top: 50%;
    right: 1rem;
    margin: 0;
    cursor: pointer;
    transform: translate(50%, -50%);

    @media (max-width: $screen-tablet-l) {
      font-size: 1.2rem;
    }
  }
}

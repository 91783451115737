// styles for dfp banner skins,
// background-image rule for #page-content writes by dfp script

.responsive {
  &.skin-applied {
    #page-content {
      position: relative;
      padding-top: 110px;
      cursor: pointer;
      background-color: #020001;
      background-repeat: no-repeat;
      background-position: center 0;

      &::before {
        display: none;
      }

      &::after {
        position: absolute;
        z-index: 0;
        top: 110px;
        bottom: 0;
        left: 50%;
        display: block;
        width: 100%;
        max-width: 1220px;
        height: auto;
        margin-left: -610px;
        content: "";
        background: #fff;
      }

      > .container {
        position: relative;
        z-index: 1;
        cursor: default;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .responsive {
    &.skin-applied {
      #page-content {
        padding-top: 0 !important;
        cursor: default !important;
        background: #fff !important;

        &::after {
          display: none !important;
        }
      }
    }
  }
}

// dfp tag ids for the Skin banner
#div-gpt-ad-718958762716565233-10,
#div-gpt-ad-546180090590731748-22,
#div-gpt-ad-213395177806553216-23,
#div-gpt-ad-643255121587703821-11 { // C1
  display: none;
}

/**
 * @define SocialLinks
 *
 * Panels/lists with Social Neworks links
 * TODO: make "mobile" breakpoints incorporate touch media queries?
 */

/* Helpers
 ======================================================================= */

$_social-link-size-default: 1.4375rem;
$_social-item-margin-default: 0.125rem;
$_social-item-margin-tablet: 0.9rem;

$_social-link-size-tablet: 1.875rem;

$_social-link-size-l: 2.5rem;
$_social-link-font-l: 1.5625rem;

/* The component
 ======================================================================= */

/**
 * The list itself (probably a <ul>)
 *
 * 1) Persistent negative margins are for compensating for the left and bottom
 *    margins on children. It's just the only doable way to nullify the bottom
 *    margin on the last row of childern (which we can't know how long will
 *    be). Negative right margin might make the block go beyond its
 *    parent...viewport which would create a horz scrollbar, so using the left
 *    one.
 * 2) We want these "buttons" to always have bigger spacings on mobile/tablet.
 *    So it's rather fine to enforce this value on them universally, which
 *    will also help us avoid defining it again in size modifiers.
 */
.SocialLinks {
  display: block;
  margin: 0 0 (-$_social-item-margin-default) (-$_social-item-margin-default); /* 1 */
  padding: 0;
  font-size: 0;

  @media (max-width: $screen-tablet-l) {
    margin: 0 0 (-$_social-item-margin-tablet) (-$_social-item-margin-tablet) !important; /* 2 */
  }

  @media print {
    display: none;
  }
}

/* Styling modifiers
 ======================================================================= */

/**
 * Social links with colors applied to the icons, not their background
 */
.SocialLinks--noBg {
  .SocialLinks-link {
    background: transparent;

    &:hover {
      background: transparent;
    }
  }
}

/* Size modifiers
 ======================================================================= */

@mixin _social-links-size-modifier($size) {
  $_margin: round($size * 0.09 * 100) / 100;
  $_font-size: round($size * 0.55 * 100) / 100;
  $_no-bg-factor: 0.8;

  margin: 0 0 (-$_margin) (-$_margin);

  &.SocialLinks--noBg {
    .SocialLinks-link {
      width: $size * $_no-bg-factor;
      height: $size * $_no-bg-factor;
      line-height: $_no-bg-factor * $size / $_font-size;
    }
  }

  .SocialLinks-item {
    margin: 0 0 $_margin $_margin;
  }

  .SocialLinks-link {
    width: $size;
    height: $size;
    font-size: $_font-size;
    line-height: $size / $_font-size;
  }
}

.SocialLinks--m {
  @include _social-links-size-modifier(1.875rem);
}

.SocialLinks--l {
  @include _social-links-size-modifier($_social-link-size-l);
}

/**
 * Have the items even bigger on tablet/mobile specifically
 */
.SocialLinks--tabletL {
  $_margin: 1.25rem;

  @media (max-width: $screen-tablet-l) {
    margin: 0 0 (-$_margin) (-$_margin);
  }

  .SocialLinks-item {
    @media (max-width: $screen-tablet-l) {
      margin: 0 0 $_margin $_margin;
    }
  }

  .SocialLinks-link {
    @media (max-width: $screen-tablet-l) {
      width: $_social-link-size-l;
      height: $_social-link-size-l;
      font-size: $_social-link-font-l;
      line-height: $_social-link-size-l / $_social-link-font-l;
    }
  }
}

/**
 * Social links with wider buttons (used on video pages)
 */
.SocialLinks--wide {
  $icon-height: 1.875rem; // 30px
  $_margin: 0.1875rem;

  margin-left: (-$_margin);

  .SocialLinks-item {
    margin-left: $_margin; // 3px

    // TODO: why decreasing the spacing on MOBILE?
    // @media (max-width: $screen-tablet-s) {
    //   margin-left: 0.125rem; // 2px
    // }
  }

  .SocialLinks-link {
    width: 3.75rem;  // 60px
    height: $icon-height;
    font-size: $font-size-base;
    line-height: $icon-height;

    @media (max-width: $screen-tablet-s) {
      width: 3rem;
    }
  }
}

/* Behavior modifiers
 ======================================================================= */

/**
 * Affixed type social icons blocks (used on Article-like pages)
 *
 * 1) Removing it from the normal flow to avoid empty spaces.
 * 2) Using a custom viewport size because on that size this component would
 *    start overlapping with the content.
 * 3) The same for both the base behavior, and in browsers that don't support
 *    position:sticky, thus the `!important` to avoid copy-pasting.
 */
.SocialLinks--affix {
  $icon-height: 1.875rem;

  position: sticky;
  z-index: 50;
  top: 382px;
  left: 0;
  float: left; /* 1 */
  overflow: visible;
  margin: 0 !important;
  padding-left: 0;
  list-style: none;

  @media (max-height: 560px) {
    top: 0.5rem !important; /* 3 */
  }

  .is-noPosSticky & {
    position: absolute;
    top: 100px;
  }

  .SocialLinks-item {
    display: block;
    margin: 0 0 0.125rem;
  }

  .SocialLinks-link {
    width: 3.75rem;
    height: $icon-height;
    font-size: $font-size-base;
    line-height: $icon-height;

    @media (max-width: 1340px) { /* 2 */
      width: $icon-height;
    }

    @media (max-width: $screen-tablet-l) {
      display: none;
    }
  }
}

/**
 * Social links that get bigger on hover
 *
 * 1) Not an optimal solution technically. But we're only adding one extra
 *    prop: this modifier adds transition; depending on the styling, there is
 *    already either bgc or color; so we're only adding either color or bgc.
 *    Which adds a really tiny performance penalty, so we're safe to trade
 *    it for simplicity of not specifying separate values for different styling
 *    modifiers (default vs SocialLinks--noBg)
 */
.SocialLinks--scalable {
  .SocialLinks-link {
    transition-property: transform, color, background-color; /* 1 */
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }
  }
}

/* Elements
 ======================================================================= */

/**
 * The list item (probably a <li>)
 *
 * 1) Resetting <li> styles
 * 2) We want these "buttons" to always have bigger spacings on mobile/tablet.
 *    So it's rather fine to enforce this value on them universally, which
 *    will also help us avoid denining it again in size modifiers.
 * 3) Just in case there are items with different height, and also to allow
 *    for custom contents insite them (like a .Button).
 */
.SocialLinks-item {
  display: inline-block;
  margin: 0 0 $_social-item-margin-default $_social-item-margin-default;
  padding: 0; /* 1 */
  vertical-align: middle; /* 3 */
  font-size: 1rem;

  &::before { /* 1 */
    content: none;
  }

  @media (max-width: $screen-tablet-l) {
    margin: 0 0 $_social-item-margin-tablet $_social-item-margin-tablet !important; /* 2 */
  }
}

/**
 * The actual link. Is a square; should have a helper class of .Icon--facebook,
 * etc. from Fontello for actual symbols
 *
 * 1) Fighting possible specificity quirks caused by the font-family mixin
 *    TODO: might not be necessary
 */
.SocialLinks-link {
  @include mac-font-smoothing;

  display: block;
  width: $_social-link-size-default;
  height: $_social-link-size-default;
  transition: background-color 0.3s ease;
  transition-property: background-color, color;
  text-align: center;
  text-decoration: none;
  color: $white;
  font-family: $icon-font !important; /* 1 */
  font-size: 0.8rem;
  line-height: $_social-link-size-default;

  @media (max-width: $screen-tablet-l) {
    width: $_social-link-size-tablet;
    height: $_social-link-size-tablet;
    font-size: 1.125rem;
    line-height: $_social-link-size-tablet;
  }

  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    transition-duration: 0.2s;
    color: $white;
  }
}

/**
 * A Mixin for setting Social Icons view depending from a parent class.
 */
@mixin _icon-colors($color, $color-hover: darken($color, 15%)) {
  background-color: $color;

  &:hover {
    background-color: $color-hover;
  }

  .SocialLinks--noBg & {
    color: $color;

    &:hover {
      color: $color-hover;
    }
  }
}

/**
 * Defining the actual links: the icons and the background
 *
 * 1) Using !optional to avoid an error because of .Icon--#{iconName} not
 *    being defined (only .Icon--#{iconName}::before is).
 */
.SocialLinks-link--facebook {
  @extend .Icon--facebook !optional; /* 1 */

  @include _icon-colors($color: $color-facebook);
}

.SocialLinks-link--twitter {
  @extend .Icon--twitter !optional;

  @include _icon-colors($color: #14b5ea);
}

.SocialLinks-link--linkedin {
  @extend .Icon--linkedin !optional;

  @include _icon-colors($color: #0077b5);
}

.SocialLinks-link--mail {
  @extend .Icon--mail-alt !optional;

  @include _icon-colors($color: #959595, $color-hover: #696969);
}

.SocialLinks-link--reddit {
  @extend .Icon--reddit !optional;

  @include _icon-colors($color: #b1d2f3);
}

.SocialLinks-link--stumbleupon {
  @extend .Icon--stumbleupon !optional;

  @include _icon-colors($color: #ea4b24);
}

.SocialLinks-link--embed {
  @extend .Icon--code !optional;

  @include _icon-colors($color: $gray-dark);
}

.SocialLinks-link--instagram {
  @extend .Icon--instagram !optional;

  @include _icon-colors($color: #d8708b);
}

.SocialLinks-link--youtube {
  @extend .Icon--youtube !optional;

  @include _icon-colors($color: #e62117);
}

/** @define CubWidgetGrid */

/**
 * Grid styles for cub widget
 */
$_grid-spacing: 1.25rem;

.cub-Row {
  margin-bottom: -1rem;
  margin-left: -$_grid-spacing;
}

.cub-Row-column {
  margin: 0 0 1rem;
  padding: 0 0 0 $_grid-spacing;
}

.cub-Row-column--collapsed {
  margin-bottom: 0;
}

.cub-Row-column--separated {
  margin-bottom: 1.5rem;

  &::after {
    border-color: $border-color;
  }
}

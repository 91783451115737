/**
 * @define Video
 *
 * A component for video player embeds (as well as images)
 */

/**
 Markup examples:
 Just a thumb with 16:9 proportions and a Play btn
  <a class="Video" href="">
    <img class="Video-thumb" src="<%= video.thumb %>" alt="">
  </a>

 This one has a video player AND a thumb which is shown until the video player
 loads, and is a backup as well:
  <div class="Video">
    <iframe class="Video-embed" src="<%= video.youtube %>"></iframe>
    <img class="Video-thumb" src="<%= video.thumb %>" alt="">
  </div>
 */

/**
 * Styles for embeds
 * We don't have full control on what's right inside the Video element (it can
 * be iframe.embedly-embed, video.video-js, like here:
 * https://siteman-policeone.praetoriandigital.com/
 * siteman/edit/default.asp?ct_id=202
 * - can be arbitrary code), so for now we have to have these styles applied
 * to all such elements - thus a mixin.
 * TODO: if that model is changed, remove the mixin and use only one element
 * class
 *
 * * 1) z-index is bigger than of a thumb to be able to have both of them. In
 *    some cases the thumb could show if/while the video isn't loaded.
 */

@mixin _video-embed {
  position: absolute;
  z-index: 5; /* 1 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.Video {
  @include video-button;

  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 1rem;
  background: $black;

  &::before {
    position: relative;
    z-index: 0;
    display: block;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    content: "";
  }

  &:last-child {
    margin-bottom: 0;
  }

  > iframe,
  > video,
  > object,
  > embed {
    @include _video-embed;
  }
}

/**
 * A thumb. This can be used either by itself, or together with Video-embed
 * as its backup
 * TODO: it's unlikely possible to have all the video thumb big enough (i.e.
 * 860px or bigger) to be able to give this `width: 100%`.
 */
.Video-thumb {
  @include center-absolute;

  z-index: 0;
  height: 100%;
}

/**
 * A class for a player: <iframe>, <video>, <object>, etc.
 */
.Video-embed {
  @include _video-embed;
}

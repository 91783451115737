/** @define CubWidgetImage */

.cub-Image--small {
  display: inline-block;
  width: 23px;
  height: 23px;
  vertical-align: middle;

  @media (max-width: $screen-tablet-l) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: $screen-tablet-s) {
    width: 20px;
    height: 20px;
  }

  // TODO: or we could just remove the `u-floatLeft` from it in Cub itself ;)
  .cub-Menu-link--userMenu > & {
    float: none !important;
  }
}

/** @define CubWidgetList */

/**
 * List styles for cub widget
 */

.cub-List--checklist {
  display: flex;
  margin-bottom: 1rem;
  margin-left: -1rem;
  flex-wrap: wrap;

  .cub-FormControl--checkbox {
    width: 0.9375rem;
    height: 0.9375rem;
  }

  .cub-Label--checkbox {
    font-size: 0.9375rem;
  }

  .cub-Label-text {
    font-weight: bold;
  }

  @media screen and (max-width: $screen-mobile-xl) {
    margin-left: 0;
  }

  /**
   * 1) Overriding our styles for <li>
   */
  .cub-List-item {
    flex: 0 0 auto;
    width: calc(25% - 1rem);
    margin-left: 1rem;
    padding: 0; /* 1 */

    @media screen and (max-width: $screen-tablet-l + 1) {
      width: calc(33.3% - 1rem);
    }

    @media screen and (max-width: $screen-tablet-s + 1) {
      width: calc(50% - 1rem);
    }

    @media screen and (max-width: $screen-mobile-xl) {
      width: 100%;
      margin-left: 0;
    }

    &::before { /* 1 */
      content: none;
    }
  }
}

/**
  * A variant of List combined with grid layout
  * 1) Set m-w: 100% to limit List's width by container's width.
  * 2) Set 100% width if there's only one item.
  */
.cub-List--grid {
  max-width: 100%; /* 1 */

  .cub-List-item {
    width: calc(50% - 1rem);

    @media screen and (max-width: $screen-mobile-xl) {
      width: 100%;
    }

    &:only-child { /* 2 */
      width: 100%;

      .cub-FormGroup {
        max-width: inherit;
      }
    }
  }

  .cub-Label-title {
    @include font-family($font-family-alt);

    font-size: $font-size-ml;
  }

  .cub-Label-text {
    font-weight: bold;
  }
}

.cub-FormControl--checkbox {
  margin-top: 4px;
}

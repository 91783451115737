/**
 * @define Crumbs
 *
 * The container for breadcrumbs, action links, tags. List--inline is rich
 * enought to implement the lists themselves, but the wrapper is needed for a
 * font-size, custom bottom gap and the ability to have 2 lists differently
 * floated and unfloated on @media. `.Page-crumbs` could do, but the element
 * is also needed inside Article/Section sometimes, and placing elements of
 * one component into another is a bad idea. That's why had to resort to a
 * dedicated component.
 *
 */

/**
 * The default component
 */
.Crumbs {
  @include clearfix;

  margin-bottom: 2.125rem;
  font-size: $font-size-sm;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Breadcrumbs
 */
.Crumbs-left {
  float: left;

  @media (max-width: $screen-desktop-s) {
    float: none;
  }
}

/**
 * ActionLinks
 */
.Crumbs-right {
  float: right;
  text-align: right;

  @media (max-width: $screen-desktop-s) {
    float: none;
    text-align: inherit;
  }

  @media (max-width: $screen-tablet-l) {
    display: none;
  }
}

/** @define CubWidgetMixins */

/**
 * Mixins styles for cub widget
 */

/**
 * Common styles for certain blocks
 *
 * 1) Since we're adding some background, we need messages to behave differently
 *    when inside these blocks.
 */
@mixin decoration-box {
  @extend %msg-backplate !optional; /* 1 */

  padding: 1rem;
  border: 1px solid $border-color;
  border-radius: 0;
  background: $cub-form-background-color;
}

@mixin form-control {
  height: 1.875rem;
  padding: 0.3125rem 0.375rem;
  border-color: $border-color;
  border-radius: 0;
}

@mixin focused {
  border-color: $form-focus-color;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px lighten($form-focus-color, 0.6);
}

/** @define Container */

$_sidebar-width: 300px;
$_columns-gap-side: 1.25rem;

/**
 * The container around content and sidebar
 * Introducing additional wrapper to give it d:f
 *
 * TODO: The component is a work in progress, and as new layout configs are
 *    added to the sites, it might get rather messy and bloated with modifiers.
 *    As a result it might - and probably should - be revised.
 *    Consider having modifiers on columns (like in Bootstrap), since all
 *    these --sidebarL and --contentM are already starting to look tedious.
 */
.Columns {
  display: block;
  display: flex;
  // width: 100%;
  margin: 0 0 2.5rem (-$_columns-gap-side);

  @media (max-width: $screen-tablet-l - 1) {
    display: block;
    margin-left: 0;
  }

  @media print {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .is-noFlexOr2009Only & {
    @include clearfix;
  }
}

/**
 * If smaller spacings are needed after this block and between its children on
 * narrow screens.
 * E.g.: block with ads (preview pages)
 * TODO: is it needed?
 */
.Columns--compact {
  margin-bottom: 1rem;

  > .Columns-content,
  > .Columns-sidebar {
    @media (max-width: $screen-tablet-l - 1) {
      margin-bottom: 1rem;
    }
  }
}

/**
 * Initially for policegrantshelp.com/individual-subscription
 */
.Columns--contentM {
  > .Columns-content {
    width: 620px;

    @media (max-width: $screen-tablet-l - 1) {
      width: 100%;
    }
  }
}

/**
 * If the sidebar needs to be bigger. Going with a modifier to Columns
 * rather than to Page itself to allow multiple types of columns blocks on
 * one page.
 */
.Columns--sidebarM {
  > .Columns-content {
    width: 710px;

    @media (max-width: $screen-desktop-s - 1) {
      width: 505px;
    }

    @media (max-width: $screen-tablet-l - 1) {
      width: 100%;
    }
  }

  > .Columns-sidebar {
    flex-grow: 1;
    width: auto;
  }
}

/**
 * A variant of Page Columns Layout with large sidebar and narrow content.
 * The Left Column is used as container for Cub Widget.
 * Example of usage: Login.cshtml.
 * 1) Use side spacings for the Columns wrapper (CROSS-449)
 */
.Columns--sidebarL {
  padding-right: 1.25rem; /* 1 */
  padding-left: 1.25rem; /* 1 */

  @media (max-width: $screen-tablet-l - 1) {
    padding-right: 0;
    padding-left: 0;
  }

  > .Columns-content {
    width: 520px;

    @media (max-width: $screen-desktop-s - 1) {
      width: 50%;
    }

    @media (max-width: $screen-tablet-l - 1) {
      width: 100%;
    }
  }

  > .Columns-sidebar {
    flex-grow: 1;
    width: auto;
  }
}

/**
 * If the sidebar needs to have fixed width and be centered in a collapsed view
 * TODO: for now only works with default 300px sidebars to avoid changes in
 *    all existing markup (spread across multiple projects). Revisit it when
 *    media sites are transitioned into Python.
 *
 * 1) In case content+sidebar are not as wide as the container.
 *    CAREFUL! Since it will cause horizontal content jumping after the sidebar
 *    starts diplaying.
 */
.Columns--sidebarFixed {
  justify-content: center; /* 1 */

  > .Columns-sidebar {
    @media (max-width: $screen-tablet-l - 1) {
      width: $_sidebar-width;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

/**
 * If no sidebar is needed in the columns block
 */
.Columns--noSidebar {
  display: block;

  > .Columns-content {
    display: block;
    float: none;
    width: auto;
  }

  > .Columns-sidebar {
    display: none;
  }
}

/**
 * Some meta info - small text at the top. Mostly on the homepage.
 */
.Columns--meta {
  margin-bottom: 0.25rem;
  color: $gray-medium;
  font-size: $font-size-s;

  > .Columns-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
  }

  > .Columns-sidebar {
    text-align: right;

    @media (max-width: $screen-tablet-l - 1) {
      text-align: left;
    }
  }
}

/**
 * TODO: I'm beat, so using a quick shit pile of a solution. Will move this
 * into its own component partial later.
 */
.Columns--crazy {
  > .Columns-content {
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;

    @media (max-width: $screen-desktop-s - 1) {
      width: auto;
    }
  }

  > .Columns-sidebar {
    flex-basis: $_sidebar-width;
    text-align: right;

    @media (max-width: $screen-tablet-l - 1) {
      text-align: inherit;
    }
  }
}

/**
 * The layout for GrantFinder search
 * TODO: this initial version is very rough, to revise
 *
 * 1) EXCEPTION to the overall media:min-width pattern: since the only thing
 *    this is different from the default, we want to skip redefining that
 *    default's rules under media queries.
 */
.Columns--3 {
  > .Columns-content {
    @media (min-width: $screen-desktop-s) { /* 1 */
      width: 300px;
    }
  }

  > .Columns-content--optional {
    width: 540px;

    @media (max-width: $screen-desktop-s - 1) {
      display: none;
    }
  }
}

/* The elements
 ========================================================================= */

/**
 * The left (wide) part with the content
 *
 * 1) Limiting the sidebar's width and stretching this arrordingly would be
 *    much easier. But in that case, .Columns-content would be taking up 100%
 *    while the page is loading and the sidebar is not yet rendered.
 * 2) Fallback for non-flex browsers
 * 3) Make it take the full width on print (the sidebar is hidden anyway);
 *    !important to override this element's width under .Columns
 *    modifiers
 */
.Columns-content {
  display: block;
  float: left; /* 2 */
  flex: 0 0 auto;
  width: 860px; /* 1 */
  margin: 0 0 0 $_columns-gap-side;

  @media (max-width: $screen-desktop-s - 1) {
    width: 620px;
  }

  @media (max-width: $screen-tablet-l - 1) {
    float: none;
    width: 100%;
    margin: 0 0 2.5rem;
  }

  @media print {
    float: none;
    width: 100% !important; /* 3 */
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * The sidebar
 *
 * 1) Fallback for non-flex browsers
 * 2) According to the spec, this one is needed for such a flex item to be truly
 *    inflexible since by default miw is auto
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=1118274
 */
.Columns-sidebar {
  flex: 0 0 $_sidebar-width;
  width: $_sidebar-width;
  min-width: 0; /* 2 */
  margin: 0 0 0 $_columns-gap-side;

  @media (max-width: $screen-tablet-l - 1) {
    flex-basis: auto;
    width: auto;
    margin: 0 0 2.5rem;
  }

  @media print {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .is-noFlexOr2009Only & {
    overflow: hidden; /* 1 */
  }
}

/**
 * Elements inside metaLeft and metaRight, added because a need for multiple
 * different elements has emerged, namely <time> and <h1>..
 */
.Columns-metaItem {
  display: inline-block;
  margin: 0 1rem 0 0;
  font: inherit;

  &:last-child {
    margin-right: 0;
  }
}

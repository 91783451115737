/**
 * Small text in the top left corner
 *
 * 1) [Fallback] Non-flex browsers. We need exactly the float! and the rest
 *    of the blocks will be right-aligned
 * 2) For applying color on Icon item to, this Icon is not inside a link
 * 3) Set these rules for cases when the NewsLine is inside DFP Adv (CROSS-574)
 * xx) Crop extra characters (the limit is approx. 60 chars), CROSS-573
 */
.NewsLine {
  @include font-family($font-family-header); /* 3 */

  display: flex;
  margin: 0;
  color: $news-line-color; /* 3 */
  font-size: $font-size-s; /* 3 */
  line-height: 1.1875;

  &:hover {
    color: $link-hover-color; /* 2 */
  }
}

.NewsLine-icon {
  margin: 0 0.3rem; // ~ 0 5px + word-spacing

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.NewsLine-link {
  transition: color 0.3s ease; /* 3 */
  text-decoration: none; /* 3 */
  color: inherit;

  @media (max-width: $screen-tablet-l) {
    overflow: hidden; /* xx */
    max-width: 355px; /* xx */
    white-space: nowrap; /* xx */
    text-overflow: ellipsis; /* xx */
  }

  @media (max-width: $screen-tablet-s) {
    max-width: 100%;
  }

  &:hover {
    text-decoration: underline; /* 3 */
  }
}

/**
 * An image in that small top-left text
 * E.g. P1 logo in "Brought to you by <P1 logo.png>"
 *
 * 1) [chrome, ie] If it's an svg, mah won't scale it, just limit the height.
 *    Setting `height` to a fixed value + mah surprisingly works
 * 2) [IE] without it maw messes the image
 */
.NewsLine-img {
  display: inline-block;
  width: 1000px; /* 2 */
  max-width: 81px;
  height: 100px; /* 1 */
  max-height: 19px;
  vertical-align: middle;
}

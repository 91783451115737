/**
 * @define CubWidgetMsg
 *
 * Various messages.
 */

/**
 * The default message.
 * Keeping the success and the info messages the same for them to stand out on
 * a white BG (might be next to a .Box-like element), and inside .Box-like
 * elements they don't need to be too agressive.
 */
.cub-Msg {
  color: $cub-msg-color;
  border-color: $cub-info-bg;
  border-radius: 0;
  background-color: $cub-info-bg;

  %msg-backplate & {
    color: $text-color;
    background: $white;
  }
}

/**
 * A warning message. E.g.: not verified Organization blocks in the Profile.
 */
.cub-Msg--warning {
  @extend .Callout;
  @extend .Callout--promo;

  color: $text-color;

  %msg-backplate & {
    margin-top: 0;
  }
}

.cub-Msg--danger {
  &,
  %msg-backplate & {
    color: $cub-msg-color;
    border-color: $cub-error-bg;
    background-color: $cub-error-bg;
  }
}

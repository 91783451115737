.cw-FormControl {
  @extend .Form-control !optional;
}

.cw-FormControl--textarea {
  @extend .Form-control--textarea !optional;

  height: 7rem;
  resize: vertical;
  border: none;
}

.cw-FormControl--checkbox {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.cw-FormGroup--gapLeft {
  margin-left: 2.5rem;

  @media screen and (max-width: $screen-desktop-s) {
    margin-left: 0;
  }
}

/** @define CubWidgetLink */

/**
 * Link styles for cub widget
 **/

.cub-Link {
  &:hover {
    text-decoration: none;
  }
}

.cub-Link--modalClose {
  @extend .Modal-titleIcon;

  padding: 0;

  .cub-Icon {
    display: none;
  }
}

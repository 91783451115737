/**
 * @define Modal
 *
 * This component is to replace _modal-forms.scss. As well as bootstrap's
 * modals.css; if some styles from there are needed, just copy them here to
 * corresponding components/elements
 *
 * The modals are still powered by Bootstrap's JS for now, so its built-in
 * classes have to be used unless/until we replace Bootstrap with sth else
 */

/* Helpers
 ========================================================================= */

$_modal-body-spacing-vert: 1.25rem;
$_modal-body-spacing-horz: 1.25rem;

/* The component
 ========================================================================= */

/**
 *
 * 1) To be able to center the dialog
 * 2) We don't want the gap between the pseudo and the dialog
 * 3) For the dialog to not go to the next line on narrow screens
 * 4) !important to override pr:17px added by Bootstrap's JS
 * 5) Prevent Chrome on Windows from adding a focus outline
 *    https://github.com/twbs/bootstrap/pull/10951
 */
.Modal {
  @include vert-center-ghost;

  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  padding: 10px !important; /* 4 */
  transition: opacity 0.15s linear 0s;
  text-align: center; /* 1 */
  white-space: nowrap;
  outline: 0; /* 5 */
  font-size: 0; /* 2 */

  /* autoprefixer: ignore next */
  -webkit-overflow-scrolling: touch;

  @media (max-width: $screen-mobile-s) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  // When fading in the modal, animate it to slide down
  &.fade {
    opacity: 0;

    .Modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -25%);
    }
  }

  &.in {
    opacity: 1;

    .Modal-dialog {
      transform: translate(0, 0);
    }
  }

  &.is-processing {
    .Modal-titleIcon {
      &::after {
        display: inline-block;
        content: "\e760"; // .Icon--arrows-cw
        animation: spinning 1s linear 0s infinite;
      }
    }
  }

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.Modal--M {
  .Modal-dialog {
    width: 860px;

    @media (max-width: $screen-desktop-s) {
      width: 684px;
    }

    @media (max-width: $screen-tablet-s) {
      width: 100%;
    }
  }
}

.Modal--L {
  .Modal-dialog {
    width: 940px;

    @media (max-width: $screen-desktop-s) {
      width: 684px;
    }

    @media (max-width: $screen-tablet-s) {
      width: 100%;
    }
  }
}

.Modal--fullHeight {
  .Modal-dialog {
    height: 100%;
  }

  .Modal-body {
    flex: 1 1 auto;
  }
}

.Modal--closeOutside {
  &.is-processing {
    .Modal-titleInput {
      padding-right: 3rem;
    }

    .Modal-title {
      &::after {
        display: block;
        animation: spinning 1s linear 0s infinite;
      }
    }

    .Modal-titleIcon {
      &::after {
        content: "\2715"; // .Icon--cancel
        animation: none;
      }
    }
  }

  .Modal-titleIcon {
    position: absolute;
    top: auto;
    bottom: 100%;
    width: auto;
    height: auto;
    padding: 0.3rem 0;
    font-family: $font-family-vanilla;
    font-size: $font-size-s;
    font-weight: normal;
    line-height: $line-height-general;

    &::after {
      position: static;
      margin: 0;
      font: inherit;
      font-size: $font-size-base;
    }

    &::before {
      margin-right: 0.25em;
      content: "Press ESC or";
    }

    &,
    &:hover {
      background: none;
    }
  }

  .Modal-dialog {
    padding: 2rem 0 1rem;
  }

  .Modal-title {
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 1rem;
      display: none;
      margin: -0.65rem 0 0;
      content: "\e760"; // .Icon--arrows-cw
      color: $white;
      font: 1.3125rem/1 $icon-font;
    }
  }

  .Modal-title--wInput {
    padding-right: 1rem;

    &::after {
      right: 2rem;
      color: $text-color;
    }
  }
}

/**
 * A variant of Modal with absolute position to build inside some objects
 * on the page, e.g. inside show modal inside a Form. This type of modal
 * doesn't overlay the full page area, just local one.
 */
.Modal--local {
  position: absolute;

  .Modal-title {
    font-size: $font-size-ml;
  }

  // This's a part of Bootstrap modal component.
  // It should take absolute position to cover only a part of page content.
  + .modal-backdrop {
    position: absolute;
  }
}

.Modal--bg {
  .Modal-title {
    background: $box-themed-bg;
  }

  .Modal-body {
    background: $box-themed-bg;
  }
}

/* Elements - the container
 ========================================================================= */

/**
 * The wrapper for the window itself
 *
 * 1) Vertically centering it
 * 2) Overriding the inherited styles
 * 3) Flexbox is needed to make the modal's body have a proper scrollbar.
 */
.Modal-dialog {
  position: relative;
  display: inline-flex; /* 1, 3 */
  flex-direction: column;
  width: 600px;
  min-width: 320px;
  max-height: 100%;
  margin: 0;
  text-align: left; /* 2 */
  vertical-align: middle; /* 1 */
  white-space: normal; /* 2 */
  background: none;
  font-size: 1rem; /* 2 */

  @media (max-width: $screen-tablet-s) {
    width: 100%;
  }
}

/**
 * 1) The bottom padding would be trimmed when with an active scrollbar. A
 *    margin would make the scrollbar not reach the very bottom of the element.
 *    An extra content block, on the other hand, doesn't have any of these
 *    downsides.
 */
.Modal-body {
  overflow: auto;
  padding: $_modal-body-spacing-vert $_modal-body-spacing-horz 0; /* 1 */
  background-color: $white;

  &::after {
    display: block;
    width: 100%;
    padding-bottom: $_modal-body-spacing-vert; /* 1 */
    content: "";
  }
}

/* Elements - the title
 ========================================================================= */

/**
 * 1) Otherwise it's too bold on Mac
 */
.Modal-title {
  @include mac-font-smoothing; /* 1 */
  @include font-family($font: $font-family-alt, $line-height: 1);

  position: relative;
  margin: 0;
  padding: 0.875rem 2.5rem 0.625rem 1.125rem;
  color: $white;
  background: $modal-bg;
  font-size: 2rem;
}

.Modal-title--warning {
  background: $modal-warning-bg;

  .Modal-titleIcon {
    background: $modal-warning-icon-bg;

    &:hover {
      background: $modal-warning-hover-icon-bg;
    }
  }
}

/**
 * A title with an text input as its only element
 */
.Modal-title--wInput {
  padding: 1rem 3.5rem 1rem 1rem;

  .Modal-titleInput {
    width: 100%;
    height: 2.8rem;
    padding: 0 1rem;
    border: 0;
    font-size: $font-size-m2;
  }
}

/**
 * The "close" icon
 *
 * 1) The icon might be animated, so we have to rely on hard-coded margins
 *    instead of transform here.
 */
.Modal-titleIcon {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 100%;
  transition: none 0.3s ease;
  transition-property: color, background;
  text-align: center;
  text-decoration: none;
  color: $white;
  background: $modal-icon-bg;

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.65rem 0 0 -0.5rem; /* 1 */
    content: "\2715"; // .Icon--cancel
    color: inherit;
    font: 1.3125rem/1 $icon-font;
  }

  &:hover {
    text-decoration: none;
    color: $white;
    background: $modal-hover-icon-bg;
  }
}

/**
 * The horizontal line
 */
.Modal-hr {
  margin: 0 0 1.25rem;
  border: 1px solid $border-color;
  border-width: 1px 0 0;
}

/* Temp styles
 ========================================================================= */

/**
 * This class is added by Bootstrap to the <body> when the modal opening is
 * triggered
 */
.modal-open {
  overflow: hidden;
}

/**
 * Overlay styles. This element is created by Bootstrap when the modal opening
 * is triggered
 * TODO: may not really be needed, as these styles could be used on .Modal
 * itself
 */
.modal-backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.15s linear 0s;
  opacity: 0.75;
  background-color: $black;

  &.fade {
    opacity: 0;
  }

  &.in {
    opacity: 0.75;
  }
}

/** @define Pagination */

$_pagination-spacing: 0.625em;

/* The component
  ======================================================================= */

/**
 * Generic components for pagination
 *
 * 1) Implementing the spinner for the "is loading" state. Having both ::before
 *    and ::after, and with v:h instead of d:n, for the proper centering. That
 *    way there would be no reflow when we show the pseudo element with the
 *    spinner.
 */
.Pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  &::before,
  &::after { /* 1 */
    @include mac-font-smoothing;

    display: inline-block;
    visibility: hidden;
    flex: 0 0 auto;
    width: 1rem;
    content: "";
    font-family: $icon-font;
  }

  &::before {
    margin-right: 0.2em;
  }

  &::after {
    margin-left: 0.2em;
  }

  &.is-processing {
    &::after {
      visibility: visible;
      content: "\e760"; // .Icon--arrows-cw
      animation: spinning 1s linear 0s infinite;
    }
  }
}

/**
 * Pagination that has only the items (e.g. letters)
 * Smaller gaps between items; items always visible.
 */
.Pagination--compact {
  $_pagination-spacing: 0.5em;

  .Pagination-items {
    margin-bottom: (-$_pagination-spacing);
  }

  .Pagination-item {
    margin: 0 ($_pagination-spacing / 2) $_pagination-spacing;
    padding: 0 0.275em;
  }

  .Pagination-nav--prev {
    margin-right: $_pagination-spacing;
  }

  .Pagination-nav--next {
    margin-left: $_pagination-spacing;
  }
}

/* The elements
  ======================================================================= */

/**
 * A container for pagination items or other stuff that should go a the middle
 * of the component
 */
.Pagination-items {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: (-$_pagination-spacing);
}

/**
 * If something other than the actual pagination items should go here, place
 * that inside .Pagination-items>.Pagination-itemBlock
 */
.Pagination-itemBlock {
  display: inline-block;
  flex: 0 1 auto;
  margin: 0 ($_pagination-spacing / 2) $_pagination-spacing;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

/*
 * Pagination item (excluding spacers)
 *
 * 1) A shorthahd with none - for brevity; overriding properties below
 * 2) Prevent clicking the current (and disabled) item event if it's a link
 *    Won't work in IE10-
 */
.Pagination-item {
  display: inline-block;
  flex: 0 0 auto;
  width: auto;
  min-width: 1.375em;
  height: 1.375em;
  margin: 0 ($_pagination-spacing / 2) $_pagination-spacing;
  padding: 0 ($_pagination-spacing / 2);
  transition: none 0.3s ease; /* 2 */
  transition-property: color, background-color, border-color;
  text-align: center;
  color: $gray-medium;
  border: 1px solid transparent;
  background: transparent;
  line-height: 1.25;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus,
  &:active,
  &:hover,
  &.is-current {
    transition-duration: 0.1s;
    text-decoration: none;
    color: $white;
    border-color: $pagination-border-active;
    background: $pagination-bg-active;
  }

  &.is-current,
  &.is-disabled { /* 2 */
    cursor: default;
    pointer-events: none;
  }

  &.is-disabled {
    text-decoration: none;
    opacity: 0.5;
    border: 0;
    background: none;
  }
}

.Pagination-nav {
  position: relative;

  &:hover {
    text-decoration: none;
  }

  &.is-current,
  &.is-disabled {
    cursor: default;
    pointer-events: none;
  }

  &.is-disabled {
    text-decoration: none;
    opacity: 0.5;
  }

  &::after {
    position: absolute;
    top: 50%;
    display: block;
    transform: translate(0, -50%);
    font-family: $icon-font;
    font-size: 1em;
    line-height: 0.8;
  }
}

.Pagination-nav--prev {
  margin-right: $_pagination-spacing;
  padding-left: 1rem;

  &::after {
    left: 0;
    content: "\e801";
  }
}

.Pagination-nav--next {
  margin-left: $_pagination-spacing;
  padding-right: 1rem;

  &::after {
    right: 0;
    content: "\e802";
    font-family: $icon-font;
  }
}

/**
 * @define List
 *
 * Styles for several types of lists: lists with markers, numbered lists,
 * lists with large counter numbers, no-marker lists, etc.
 * The contents of the lists are mostly controlled with separate components.
 * E.g. to have a block with a pic to the left and texts to the right, use
 * .HCard.
 */

$_list-item-mbottom: 1.25rem;

/**
 * Multiple elements are using these styles, so we have to make them reusable
 *
 * 1) Browsers may not print the element's background depending on the
 *    settings. So we emulate the bullet's bg with border.
 */
@mixin list-item-with-bullet {
  position: relative;
  clear: both;
  padding: 0 0 0 1rem;

  &::before {
    position: absolute;
    top: 0.5rem;
    left: 0;
    display: block;
    width: 7px;
    height: 6px;
    content: "";
    background: $gray-dark;

    @media print {
      border: 3px solid $gray-dark; /* 1 */
      background: none;
    }
  }
}

/**
 * Common rules
 *
 * 1) Results in 26px, as per the Style Guide (considering the default lh)
 */
.List {
  display: block;
  margin: 0 0 1.25rem;
  padding: 0;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Plain lists without markers
 *
 * 1) The gap is bigger as such lists are meant to be used with either HCard
 *    that nullifyes the top line-height, or some other image block
 */
.List--noMarker {
  margin-bottom: 1.625rem; /* 1 */

  > .List-item {
    display: block;
    margin-bottom: 1.625rem; /* 1 */
    padding-left: 0;

    &::before {
      content: none;
    }
  }
}

/* Inline list
 ========================================================================= */

/**
 * A List with inline items separated by a marker.
 * E.g: Subcategories list (Product category page)
 *
 * 1) To allow text-wrapping
 * 2) mr needs 1em, but 0.8em is picked to accomodate the inter-word spacing
 *    since there are often whitespaces between HTML elements in the markup
 * 3) The goal: we need the ability to have a bullet at the end of an element.
 *    The discarded options were:
 *    - just add a normal flow bullet (would wrap to a next line alone, and
 *    whs:nw would prevent the whole block from wrapping if there were no
 *    whitespaces between elements);
 *    - pos:a & padding would break the bullet's position if an inline element
 *    wrapped on multiple lines;
 *    The solution was this trick.
 */
.List--inline {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  &.List--noMarker {
    .List-item {
      display: inline;
      padding-right: 1em;

      &::after {
        content: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .List-item {
    display: inline; /* 1 */
    margin: 0;
    padding: 0 2.3em 0 0; /* 3 */
    line-height: 1.625;

    &::before {
      content: none;
    }

    &::after {
      display: inline;
      margin: 0 -1.4em 0 1.1em; /* 3 */
      content: "•";
      color: $gray-medium;
      line-height: 1.082;
    }

    &:last-child {
      padding-right: 0.8em;

      &::after {
        content: none;
      }
    }
  }

  /**
   * This will be a gray kinda-heading text with an arrow
   */
  .List-item--leading {
    color: $gray-medium;

    &::after {
      content: "\f105"; // .Icon--angle-right
      font-family: $icon-font;
      font-weight: 700;
    }
  }

  .List-link,
  .List-itemText { /* 3 */
    white-space: normal; /* 3 */
  }
}

/**
 * A List whose items are supposed to show in columns
 *
 * 1) A dummy flex element to prevent the last el from expanding too much.
 *    Since our content is always limited to 800px + the base width of flex
 *    items, just one dummy as a hack would be enough.
 * 2) Fallback for non-flex browsers. Since it's not a %-based grid, we don't
 *    care about a small lag introduced by a word spacing between
 *    inline-blocks
 * 3) Add the side spacings for an element, compensate them for a container
 *    Using horizontal border instead of margin so that %-based width values
 *    didn't overflow
 * 4) Otherwise it won't be truly inflexible
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=1118274
 */
.List--grid {
  $_list-grid-base-item-width: 300px;

  display: flex;
  margin-left: -1.25rem; /* 3 */
  flex-wrap: wrap;

  &,
  &:last-child {
    margin-bottom: -$_list-item-mbottom;
  }

  &::after { /* 1 */
    flex: 1 1 $_list-grid-base-item-width;
    content: "";
  }

  .List-item {
    display: inline-block; /* 2 */
    flex: 1 1 $_list-grid-base-item-width;
    width: $_list-grid-base-item-width; /* 2 */
    border-left: 1.25rem solid transparent; /* 3 */

    &,
    &:last-child {
      margin-bottom: $_list-item-mbottom; /* 3 */
    }
  }

  // Give the full width for clean item
  .List-item--clean {
    display: block; /* 2 */
    flex: 0 0 100%;
    width: 100%;
    min-width: 0; /* 4 */
  }
}

/* Vertical list, items separated with borders
 ========================================================================= */

.List--stack {
  display: block;
  list-style-type: none;

  &.List--topBorder {
    padding-top: 1.25rem;
    border-top: 1px solid $border-color;
  }

  > .List-item {
    margin: 0;
    padding: 1.25rem 0;
    list-style-type: none;
    border-top: 1px solid $border-color;

    &::before {
      content: none;
    }

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

/**
 * An ordered list with large counter numbers. E.g.: "Top 5"
 * Due to the requirement for the counter numbers to be clickable, we have
 * to place them inside the link. Thus .List-link have to be used.
 *
 * 1) Magic number. An el must be at least as tall as its counter number
 * 2) Magic number. To align top edges of the counter and the text of a link
 * 3) Set width and ta:c to align numbers vertically in a list
 *    (e.g. number "1" is much thinner than "5")
 * 4) Fix for MS Edge/IE10-11.
 *    Wrap text into additional span .List-linkText to prevent underline
 *    on hover for items such as Link-meta, numbers in Top-5 list.
 */
.List--top {
  counter-reset: list-top;

  .List-item {
    display: block;
    padding-left: 0.625rem;
    list-style-type: none;

    &::before {
      content: none;
    }
  }

  .List-link {
    position: relative;
    display: block;
    min-height: 2.2rem; /* 1 */
    padding-left: 2.5rem;

    @include hover {
      text-decoration: none;

      &::before {
        color: inherit;
      }

      .List-linkText { /* 4 */
        text-decoration: underline;
      }
    }

    &::before {
      @include font-family($font: $font-family-alt, $line-height: 1);

      position: absolute;
      top: -0.08em; /* 2 */
      left: 0;
      width: 1.625rem; /* 3 */
      content: counter(list-top);
      counter-increment: list-top;
      transition: color 0.3s ease 0s;
      text-align: center; /* 3 */
      color: $link-dark-color;
      font-size: 3.125rem;
    }
  }
}

/**
 * List with arrows (Fontello "angle right") as markers
 * E.g.: Support Center on "Submit ..." page
 *
 * 1) Reset the hack for printing background from a generic <li>
 */
.List--arrow {
  .List-item {
    &::before {
      top: 0;
      width: auto;
      height: auto;
      content: "\f105"; // .Icon--angle-right
      background: none;
      font-family: $icon-font;
      font-weight: 700;

      @media print {
        border: none; /* 1 */
      }
    }
  }
}

/**
 * List with ticks (Fontello "ok") as markers
 */
%list-tick-item {
  margin-bottom: 0.75rem;
  padding-left: 2rem;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &::before {
    top: 0;
    width: auto;
    height: auto;
    content: "\e80a"; // .Icon--ok
    color: $link-color;
    background: none;
    font-family: $icon-font;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1;

    @media print {
      border: none; /* 1 */
    }
  }
}

.List--tick {
  text-align: left;

  > .List-item {
    @extend %list-tick-item;
  }
}

/**
 * A list of navigation links.
 * The links have padding (for accessibility touchscreens)
 *
 * 1) .List-subLink has some padding, so we need to consider it in subItem
 *    bullet's shift from top
 * 2) Hack for Safary. Without specifying lh the marker's position would be
 *    shifted too much to bottom
 * 3) Reset the hack for printing background from a generic <li>
 */
.List--menu {
  > .List-item {
    margin: 0 0 0.5rem;
    padding: 0;

    &::before {
      content: none;
    }
  }

  .List-link,
  .List-subLink {
    display: block;
    margin-bottom: 0.5em;
    padding: 0.2em;

    &:hover {
      text-decoration: none;
      background: $gray-light;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .List-link {
    position: relative;
    padding-left: 1.2em;
    font-weight: 700;
  }

  // If the root list item has sublist, its link needs an arrow icon
  // to indicate that
  .List-item--withSubList {
    .List-link {
      &::before {
        position: absolute;
        top: 0.3rem; /* 3 */
        left: 0;
        margin: 0;
        padding: 0;
        content: "\f103"; // .Icon--angle-double-down
        font-family: $icon-font;
        font-size: 1.2em;
        font-weight: 400;
        line-height: 1; /* 2 */

        /* stylelint-disable */
        @media print {
          /* stylelint-enable */
          border: none; /* 3 */
        }
      }
    }
  }

  .List-subList {
    margin-left: 1rem;
  }

  .List-subItem {
    @include list-item-with-bullet;

    margin: 0 0 0.5rem;

    &::before {
      top: 0.75rem; /* 1 */
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * An ordered list. Basically, generic <ol> has the same styles except smaller
 * left padding. But if not in a user-edited area, a component should be used
 * - in order to avoid any style collisions.
 *
 * 1) Reset the hack for printing background from a generic <li>
 */
.List--numbered {
  counter-reset: list-numbered;

  > .List-item {
    padding-left: 1.625rem;

    &::before {
      @include font-family($font-family-alt);

      top: 0;
      width: auto;
      height: auto;
      content: counter(list-numbered) ".";
      counter-increment: list-numbered;
      background: none;
      font-size: 1.5rem;

      @media print {
        border: none; /* 1 */
      }
    }
  }
}

/* List items
 ========================================================================= */

/**
 * A generic list item
 *
 * 1) In the assumption that the last list item should never have mb, we use
 */
.List-item {
  @include list-item-with-bullet;

  margin: 0 0 $_list-item-mbottom;
  counter-increment: none;

  &:last-child {
    margin-bottom: 0; /* 1 */
  }

  &.is-active {
    > .List-link {
      color: $link-hover-color;
    }
  }
}

/**
 * A list item without markers. Used, for example, for holding "Load more"
 * buttons that have to be inside a fellow list item for simpler ajax loading
 */
.List-item--clean {
  padding-left: 0;

  &::before {
    display: none;
    content: none;
  }
}

.List-item--shaded {
  background: $gray-light;
}

/**
 * An element to wrap meta items inside the List-link, e.g. a set of Icons
 * inside Top5 list.
 * (It's needed on the Most Popular Police Articles - CROSS-323).
 *
 * 1) Use inline-block to prevent underline on hover.
 */
.List-meta {
  display: inline-block; /* 1 */
  margin: 0 0 0 0.4em;
}

/* Sublists
 ========================================================================= */

.List-subList {
  display: block;
  margin: 0 0 1rem 1rem;
  padding: 0;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
}

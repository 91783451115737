// WARNING!
// As per SUIT philosophy, we should avoid making just about every repeating
// property groups as mixins. Mixins are ok only in cases like:
// - A group of props that are tightly connected to implement certain logic
//    (like a pseudo element for vertical centering)
// - A group of props that implements certain styling, if used by multiple
//    DIFFERENT components (withing one component - use the base class to give
//    element these styles), and these components are the same from the
//    designer's POV (example: titlebar and table caption), not just looking
//    similar.
// In all other cases some copy-pasting is perfectly ok since it maintains
// the components' independence and simplifies the code.
// WARNING!

/**
 * On Mac bold text looks much fuller than on Windows. In many cases that
 * looks ugly. The reason is the anti-aliasing techniques; and we can override
 * them
 * WARNING! Use with caution. In many cases turning per-pixel AA on will hurt
 * the text. E.g., normal text on dark background can become too thin
 *
 * $on [true | false] - to be able to override inherited styles for specific
 *    elements
 */
@mixin mac-font-smoothing($on: true) {
  @if ($on == false) {
    /* autoprefixer: ignore next */
    -webkit-font-smoothing: auto;

    /* autoprefixer: ignore next */
    -moz-osx-font-smoothing: auto;
  } @else {
    /* autoprefixer: ignore next */
    -webkit-font-smoothing: antialiased;

    /* autoprefixer: ignore next */
    -moz-osx-font-smoothing: grayscale;
  }
}

/**
 * A mixin for switching to a main web font from its backup font after the main
 * one has loaded.
 * To avoid FOIT, we're using Fonts Face Observerver to change classes on
 * <html> when our web fonts have loaded.
 * The functionality is that redundant in order to try to avoid reflow caused
 * to switching to a different font as much as possible
 *
 * 1) Using the "Remove Class" approach because the "Add Class" would cause
 *    permanent unwanted specificity issues.
 * 2) NOT using !important to not break elements that call this mixin
 *    initially, but later redefine the font without the need of it. Example:
 *    button (uses the mixin) > some button.Icon/button.owl-prev (icon font,
 *    doesn't need Font observing).
 */
@mixin font-family(
  $font: $font-family-main,
  // Properties to override those from the $fonts-settings
  $line-height: false,
  $font-weight: false,
  // Special mode: uppercase text
  $caps: false,
) {
  $font-config: map_get($fonts-settings, $font);

  // If there are properties whose values from $fonts-settings must be
  // overridden
  $overrides: ();

  @if $line-height {
    $overrides: map_merge($overrides, ( line-height: $line-height ));
  }

  @if $font-weight {
    $overrides: map_merge($overrides, ( font-weight: $font-weight ));
  }

  // For the easier "just inherit everything"
  @if $font == "inherit" {
    &,
    .is-fontsNotLoaded & {
      letter-spacing: inherit;
      text-transform: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  } @else {
    .is-fontsNotLoaded & { /* 1 */
      @if $font-config {
        $backup: map_merge(map_get($font-config, "backup"), $overrides);

        @if $caps and $font-config and map_get($font-config, "caps") {
          $backup: map_merge($backup, map_get($font-config, "caps"));

          text-transform: uppercase;
        } @else {
          text-transform: none;
        }

        @each $prop, $value in $backup {
          #{$prop}: $value;
        }
      } @else {
        font-family: $font-family-vanilla; /* 2 */
      }
    }

    @if $font-config {
      $main: map_merge(map_get($font-config, "main"), $overrides);

      @each $prop, $value in $main {
        #{$prop}: $value;
      }

      @if $caps {
        text-transform: uppercase;
      } @else {
        text-transform: none;
      }
    }

    font-family: $font;
  }
}

/**
 * 1) Ghosting element that forces line-height.
 * For example, if we need an image to be vertically centered inside a div.
 */
@mixin vert-center-ghost {
  &::before {
    display: inline-block;
    height: 100%;
    content: "";
    vertical-align: middle;
  }
}

/**
 * Centering an absolutely positioned element inside a parent both vertically
 * and horizontally. For some reason this combination of all the position
 * props set to zero and margin:auto works. Choosing this over top:50% +
 * left:50% + transform: translate(-50%, -50%) because the latter makes even
 * a perfectly fitting image either shift a bit to the right or the left (on
 * Windows) making the parent's background stick out, or have that background
 * stick out "smoothly", in a form of a transiton from the parent's bg fill to
 * the image (Mac). This happens in FF, Crhome and Safari.
 */
@mixin center-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
}

@mixin hover {
  &:hover,
  .is-hover & {
    @content;
  }
}

// Just a copy-paste of .u-clearfix utility
@mixin clearfix {
  &::after {
    display: table;
    clear: both;
    content: "";
  }
}

/* Component mixins
 ========================================================================= */

// As noted above, we should only move chunks of props to a mixin only if
// it's used in different components. In some cases extens should work ok
// (for example, if we want to make an external component, like from Cub, look
// like ours), and in many cases copy-pasting styles won't hurt.

/**
 * Some basic styles used by titlebar-like elements (TitleBar itself,
 * <caption>, Table-caption).
 * Notes:
 *  - set `display` separately (not setting it here to avoid breaking caption)
 *
 * 1) Vert padding (in combination with line height) are calculated to have
 *    the element's height would be 40px (according to style guite) if
 *    there's one line of text
 * 2) Otherwise it's too bold on Mac
 * 3) Resetting h2's defaults
 * 4) Resetting 3rd-party styles
 */
@mixin titlebar-basis {
  @include mac-font-smoothing; /* 2 */
  @include font-family($line-height: 1.25, $caps: true); /* 1 */

  position: relative;
  margin: 0 0 1rem; /* 4 */
  padding: 0.625rem; /* 1 */
  text-align: left;
  color: $white;
  border: none; /* 4 */
  background: $titlebar-bg;
  font-size: $font-size-base;
  font-weight: 700;
}

/**
 * Big play button for videos. It usually shows over the video thumb or
 * video player.
 */
@mixin video-button($size: 40px) {
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    margin: -0.5em 0 0 -0.5em;
    content: "\25b6"; // .Icon--play
    transition: all 0.3s ease;
    transform: scale(1, 1);
    text-align: center;
    text-decoration: none !important;
    opacity: 0.85;
    color: $white;
    font: #{$size}/1 $icon-font;
  }

  @include hover {
    text-decoration: none;

    &::after {
      transform: scale(1.2, 1.2);
      opacity: 1;
    }
  }
}

/**
 * Spinner. It's used in Cub Forms, Comments widget.
 *
 * 1) Since an animation based on transform is involved, we have no choice but
 *    to hard-code this
 */
@mixin spinner {
  position: relative;
  display: block;
  height: 80px;
  color: $link-color;

  &::after {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    margin: -1.5rem 0 0 -1.15rem; /* 1 */
    content: "\f110"; // .Icon--spinner
    animation: spinning 1s linear 0s infinite;
    font-family: $icon-font;
    font-size: 2.25rem;
  }
}

/**
 * Here we just apply custom fonts
 */

$_ranges: (
  "vietnamese": "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB",
  "latin": "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
  "latin-ext":  "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF",
);

@each $id, $font in $fonts {
  $path: map_get($font, "path");
  $variants: map_get($font, "variants");
  $subsets: map_get($font, "subsets");

  @if $variants or $subsets {
    // For the sake of uniformity (for the @each rules to work), if eigher of
    // the lists is empty, making a default list
    $variants: $variants or [["regular"]];
    $subsets: $subsets or ("latin");

    @each $variant in $variants {
      $weight: nth($variant, 1);
      $style: if(length($variant) == 2, nth($variant, 2), "");

      @each $subset in $subsets {
        $_path:
          $path + "-" + $subset + if(
            $weight == regular and $style == italic,
            "-italic",
            "-#{$weight}#{$style}"
          );

        @font-face {
          font-family: "#{$id}";
          src:
            url("#{$_path}.woff2") format("woff2"),
            url("#{$_path}.woff") format("woff"),
            url("#{$_path}.ttf") format("truetype");
          font-weight: if($weight == "regular", normal, $weight);
          font-style: if($style == "", normal, $style);
          // stylelint-scss mistakes this function call for a string
          // stylelint-disable-next-line scss/function-unquote-no-unquoted-strings-inside
          unicode-range: unquote(map_get($_ranges, $subset));
        }
      }
    }
  }
  // If there is just one variant (fw:n, fs:n) and one set of files
  @else {
    @font-face {
      font-family: "#{$id}";
      src:
        url("#{$path}.woff2") format("woff2"),
        url("#{$path}.woff") format("woff"),
        url("#{$path}.ttf") format("truetype");
      font-weight: normal;
      font-style: normal;
    }
  }
}

/**
 * @define Byline
 *
 * A component for blocks like "About the author"
 */

/**
 * The component
 *
 * 1) For now it only exists inside Articles (or alike), so for ease of use
 *    giving it the "betwee the content items" gap.
 */
.Byline {
  @include clearfix;

  margin: 0 0 $article-item-mbottom; /* 1 */
  color: $gray-dark;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A variant of component with wrapping text. Used on Grants sponsors page
 *
 * 1) In case some text flows "under" this block, make the img on top of it
 */
.Byline--wrappingText {
  .Byline-imgBlock {
    position: relative; /* 1 */
    z-index: 1; /* 1 */
    margin-bottom: 1.25rem;
  }

  .Byline-texts {
    overflow: inherit;
  }
}

/* Image size modifiers
 ======================================================================== */

/**
 * It's easier to have the default Byline without the logic of collapsing on
 * smaller screens and to define it in these modifiers individually, then to
 * have some default collapsing in .Byline and redefine that breakpoint in
 * every size-based modifier.
 *
 * 1) If the width is rather big, have the image max height closer to it
 */
@mixin _byline-img-size-modifier($size, $breakpoint) {
  &.Byline--centerCollapsed {
    @media (max-width: $breakpoint) {
      text-align: center;
    }
  }

  .Byline-imgBlock {
    width: $size;
    max-height: max($size, min($size * 1.5, 200px)); /* 1 */

    @media (max-width: $breakpoint) {
      float: none;
      max-width: 100%;
      margin: 0 auto 1.25rem;
    }
  }
}

.Byline--m {
  @include _byline-img-size-modifier($size: 120px, $breakpoint: $screen-mobile-m);
}

.Byline--l {
  @include _byline-img-size-modifier($size: 200px, $breakpoint: $screen-mobile-xxl);
}

/* Elements
  ======================================================================== */

/**
 * A containing block for an image. Layout-wise used for image size limits,
 * alignment, and can also be a link.
 *
 * 1) Make static room for the image block to prevent the text from jumping
 *    to the right after the image is loaded.
 * 2) To allow for tall images. object-fit is to fix the visible proportions
 *    since we're forcing the image's width attribute in the markup.
 *    ImageResizer should be taking care of this by limiting the height as
 *    well. But for cases it's not, for some reason, we're using object-fit.
 */
.Byline-imgBlock {
  display: block;
  float: left;
  overflow: hidden;
  width: 80px;
  min-height: 5px; /* 1 */
  max-height: 120px; /* 2 */
  margin-right: 1.25rem;
  text-align: center;
  font-size: 0;
}

/**
 * An image itself
 *
 * 1) To avoid unnecessary bottom gap created by a parent's half-leading;
 *    not using fz:0 on a parent to make alt text readable, should it appear
 */
.Byline-img {
  max-width: 100%;
  max-height: inherit;
  object-fit: contain; /* 2 */
  object-position: top;
  vertical-align: middle; /* 1 */
}

/**
 * This wrapper for the Byline heading and other texts needs to be used
 * only in conjunction with the Byline-imgBlock; its main purpose is aligning
 * the texts' and the img's top edges
 *
 * 1) Using pos:r +top instead of negative mt, because the latter breaks
 *    ov:h (and that is needed for longer texts).
 * 2) negative top increases the visual bottom gap. Adjusting that
 */
.Byline-texts {
  position: relative; /* 1 */
  top: -0.25em; /* 1 */
  overflow: hidden;
  margin-bottom: -0.25em; /* 2 */
}

/**
 * If the text don't need to be shifted up (i.e., the first elementis is a
 * div with a button, thus not having "empty" line-height)
 */
.Byline-texts--noShift {
  top: 0;
  margin-bottom: 0;
}

/**
 * "About..." heading.
 * It seems that a 26px gray heading exists only here, and also some custom
 * margin bottom is needed, so decided to try using a dedicated el. class
 * instead of another Heading modifier.
 * TODO: if 26px headings emerge anywhere else, will have to make a Heading
 * mod.
 */
.Byline-heading {
  @include font-family($font-family-alt);

  display: block;
  margin-bottom: 0.3rem;
  text-transform: none;
  font-size: $font-size-l;
}

/**
 * TODO: temporary element. Can't decide what to do with such spacing yet
 * Use case: Social links, Columnist PGH-209
 */
.Byline-textItem {
  display: block;
  margin-bottom: 0.625rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/** @define CubWidgetImageControl */

.cub-ImageControl {
  .cub-Button-icon {
    left: 0;
    margin: 0;
  }

  .cub-Button {
    padding: 0;
  }
}

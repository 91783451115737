/**
 * @define Section
 * A very basic means of organizing content part of the page.
 * For now it is mostly for vertical margins; some additional styles may be
 * added in the future.
 *
 * 1) Assuming that .Section with any modifier still got to have 0 mb, and
 *    there are hardly any exception to this, - adding !important to avoid
 *    adding :last-child to every bottom margin modifier
 */

.Section {
  display: block;
  margin: 0 0 1.5rem;

  @media print {
    display: none;

    .Article &,
    .u-visiblePrintScope & {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0 !important; /* 1 */
  }
}

.Section--withCarousel {
  > .Section-footer {
    margin-right: 30px;
    margin-left: 30px;
  }
}

/* Modifiers for vertical margins
  ======================================================================== */

/**
 * Mixin for building vertical gaps modifiers for .Section
 * Used ONLY HERE, to avoid copy-pasting
 *
 * $margin-bottom — value for .Sections (and possibly it's el-s) mb
 */

@mixin section-gap-vertical($margin-bottom: 1rem) {
  margin-bottom: $margin-bottom;

  // Add this modifier if the margin needs to apply to the elements as well
  &.Section--gapPropagate {
    > .Section-heading,
    > .Section-block,
    > .Section-footer {
      margin-bottom: $margin-bottom;
    }
  }
}

/**
 * The names go as: XS - S - SM - M - LM - L - XL, etc.
 */

.Section--gapVertS {
  @include section-gap-vertical($margin-bottom: 1rem);
}

// ~20px, for the right sidebar
.Section--gapVertSM {
  @include section-gap-vertical($margin-bottom: 1.25rem);
}

// 26px gap
.Section--gapVertM {
  @include section-gap-vertical($margin-bottom: 1.625rem);
}

.Section--gapVertML {
  @include section-gap-vertical($margin-bottom: 2.25rem);
}

.Section--gapVertL {
  @include section-gap-vertical($margin-bottom: 2.5rem);
}

/* Elements
  ======================================================================== */

/**
 * A heading
 */
.Section-heading {
  margin: 0 0 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A block with contents
 */
.Section-block {
  margin: 0 0 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A footer. The main difference from .Section-block for now is that in
 * Sections with carousel it gets shifted
 */
.Section-footer {
  margin: 0 0 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.Section-footer--fixed {
  position: sticky;
  bottom: 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 -8px 7px -5px rgba(0, 0, 0, 0.14);
}

/**
  * Links component.
  * 1) Use standard flow for links to normally render
  * when an inner Text is hidden and an Icon has p:a
  */

.cw-Link {
  @extend .Link;

  display: inline; /* 1 */
}

// Elements
.cw-Link-lastWord {
  @extend .Link-lastWord;
}

// Modifiers
.cw-Link--iconPrefix {
  position: relative;
  padding-left: 1rem;
  text-transform: none;

  .cw-Link-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.cw-Link--voteUp,
.cw-Link--voteDown {
  .cw-Link-icon {
    font-size: 1.0625rem; // 17px
  }

  .cw-Link-text {
    display: none;
  }
}

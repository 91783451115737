.cw-Box {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-sizing: border-box;
  margin-left: 0.3125rem;
  padding: 1rem;
  border: 1px solid $border-color;
  background: $gray-light;

  &:first-child {
    margin-left: 0;
  }
}

.cw-Box--S {
  width: 140px;
  flex-grow: 0;
}

/**
  * 1) Set pos:r to have the inner cw-StatusMsg with pos:a in a right place.
  */
.cw-Box--settings {
  position: relative; /* 1 */
  font-size: 0.9375rem;

  &.is-processing {
    .cw-Text-icon {
      transition: all 1s ease-out;
      transform: rotate(360deg);
    }

    .cw-StatusMsg {
      opacity: 1;
    }
  }
}

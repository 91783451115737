// TODO: refactor this shit
.cw-LinkGroup {
  margin-bottom: 0.5rem;

  .cw-Link {
    margin-left: 1.25rem;
    font-size: inherit;

    &:first-child {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cw-LinkGroup--L {
  font-size: 0.9375rem; // 15px
  line-height: 1.25;

  .cw-Link {
    margin-left: 1rem;
  }

  .cw-Link--iconPrefix {
    padding-left: 1.25rem;
  }
}

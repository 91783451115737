/** @define CubWidgetNewsletterSample */

/**
 * Newsletter Sample styles for cub widget
 */

.cub-NewsletterSample-controls {
  .cub-FormGroup--buttons {
    max-width: 100%;
  }
}

/**
 * Styles for Owl Carousel
 *
 * It would be much easier to just import Owl's SCSS and override stuff.
 * But it has some really nasty defaluts like `.owl-carousel .owl-item img`,
 * which break some layouts. So we have to redefine everything here...
 */

/* Owl Carousel - Core
 ========================================================================= */

/**
 * Root Carousel element
 *
 * 1) fix webkit rendering fonts issue
 * 2) override values from .Grid
 *    TODO: remove !important after refactoring
 */
.owl-carousel {
  position: relative; /* 1 */
  z-index: 1; /* 1 */
  display: none;
  overflow: visible;
  box-sizing: border-box;
  width: auto;
  margin: 0 $owl-carousel-side-margin !important; /* 2 */
  text-align: inherit; /* 2 */
  -webkit-tap-highlight-color: transparent;

  /* No Js */
  .no-js & {
    display: block;
  }
}

/**
 * Such carousels' nav arrows have square background and are on top of the
 * slides
 *
 * 1) In Blink/Webkit browsers the slides fading in/out is quirky: not sure,
 *    what exactly happens, but seems like a slide is either shown too early,
 *    or a delay is added. As a result, the slides are melted into one another
 *    during animation. It's especially clear on text without background.
 *    The solution we came up with: to make all inactive slides transparent,
 *    and change that in the process of dragging.
 * 1a) As a result, when dragging stops "between" the slides, inactive ones
 *    disappear at once. Solving this by adding transition to fadeing out (but
 *    not to fading-in). So transition is here for that purpose ONLY - the
 *    actual animating on prev/next/pager is via .animated classes
 * 2) It's important to not use opacity:0/visibility:hidden on slides when
 *    lazysizes' `loadHidden` is set to false.
 */
.owl-carousel--single-item {
  margin: 0 !important;

  .owl-item {
    transition: opacity 0.4s ease-in 0s; /* 1b */
    opacity: 0.01; /* 1, 2 */

    &.active {
      opacity: 1; /* 1 */
    }
  }

  &.owl-grab {
    .owl-item {
      transition: none; /* 1a */
      opacity: 1; /* 1 */
    }
  }

  .owl-prev,
  .owl-next {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    transition: opacity 0.1s linear 0s;
    text-align: center;
    opacity: 0.5;
    color: #1b132e;
    background: $white;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    font-weight: 600;
    line-height: 40px;

    &::after {
      font-size: 26px;
      line-height: inherit;
    }

    &:hover {
      opacity: 1;
    }

    &.disabled {
      &,
      &:hover {
        cursor: default;
        opacity: 0.1;
      }
    }
  }

  .owl-prev {
    left: 0;
  }

  .owl-next {
    right: 0;
  }
}

/**
 * A slider whose items' content is layed out vertically: text under image;
 * AND the images have proportional height (by default 1:0.88, but can
 * be changed!)
 *
 * 1) Placing the arrow btns at the middle of the image's height, ignoring
 *    all the content below that image.
 * 2) Bugfix. In Safari 6-, Chrome 25-, Android 4.2- % value for vert margins
 *    is based on the container's height, not width. We can't use neighter
 *    translateY (the controls' width is too small), nor padding-top (it will
 *    make them taller), nor transform/padding on .owl-nav. So just using
 *    a MAGIC NUMBER to align the controls ROUGHLY in the middle of the image
 */
.owl-carousel--img-aspect {
  .owl-prev,
  .owl-next {
    top: -20px; /* 1 */
    margin-top: 50% * $slider-img-height-proportion; /* 1 */

    .is-webkitMarginPercentBug & { /* 2 */
      margin-top: 27%;
    }
  }
}

/**
 * An owl.carousel for a slider in the content area of a Post
 *
 * 1) The same conditions as for switching to the post_square crop
 */
.owl-carousel--incontent {
  .owl-prev,
  .owl-next {
    top: -20px;
    margin-top: 50% * $slider-img-height-proportion-wide;

    @media (max-width: $screen-mobile-xxxl) and (orientation: portrait) { /* 1 */
      margin-top: 50% * $slider-img-height-proportion;
    }
  }
}

/**
 * Carousels whose elements have side borders
 * E.g.: Topic article, product article
 *
 * 1) Owl Carousel's autoHeight option gives .owl-stage-outer fixed height
 *    based on the tallest item. Here we propagate that height so that the
 *    inner elements could be stretched vertically
 * 2) Otherwise some items may be shifted up/down in Webkit/Blink
 * 3) Overriding .owl-item > .Grid-item
 * 4) Emulating items' border
 * 5) There is a bug in Webkit/Blink with rounding decimal values with units.
 *    So we a) give the .owl-stage-outer both side borders by default, and
 *    only left one on Webkit/Blink so that its right border doesn't show
 *    together with the rightmost item's. b) shift .owl-stage a bit to the
 *    left so that there is no double border at the left; this shift at the
 *    right will not be visually identifieble
 * 6) So that if the item's contents are too wide, the item wouldn't expand
 *    too much
 */
.owl-carousel--borders {
  .owl-stage-outer {
    min-height: 91px;
    border: 0 solid $border-color;
    border-width: 0 1px; /* 5a */

    .isWebkitBlink & {
      border-width: 0 0 0 1px; /* 5a */
    }
  }

  .owl-stage {
    position: relative; /* 5b */
    left: -1px; /* 5b */
    display: table;
    height: 100%; /* 1 */
    table-layout: fixed; /* 6 */
  }

  .owl-item {
    position: relative; /* 4 */
    display: table-cell;
    float: none;
    height: 100%; /* 1 */
    vertical-align: top; /* 2 */

    &::after { /* 4 */
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      content: "";
      background: $border-color;
    }
  }

  .Grid-item {
    max-width: none; /* 3 */
    height: 100%; /* 1 */
    padding: 0; /* 3 */
  }
}

/**
 * Styles for a carousel that serves as pagination for another slider/carousel
 *
 * 1) Overriding values from base element (most likely .Grid)
 */
.owl-carousel--pagination {
  margin: 0 !important; /* 1 */

  .owl-item {
    cursor: pointer;
    transition: opacity 0.15s ease 0s;
    opacity: 0.5;

    &:hover,
    &.current {
      opacity: 1;
    }
  }
}

/* Built-in state and modifier classes for .owl-carousel
 * ======================================================================= */

.owl-loaded {
  display: block;
}

.owl-loading {
  display: block;
  opacity: 0;
}

.owl-hidden {
  opacity: 0;
}

.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-rtl {
  direction: rtl;

  .owl-item {
    float: right;
  }
}

.owl-refresh {
  .owl-item {
    display: none;
  }
}

.owl-drag {
  .owl-item {
    user-select: none;
  }
}

/* Containers
 * ======================================================================= */

/**
 * Direct child of .owl-carousel. By default also has limited width; it's
 * this element that clips (ov:h) the items that should be invisible.
 */
.owl-stage-outer {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  /* fix for flashing background */
  /* stylelint-disable */
  /* autoprefixer: ignore next */
  -webkit-transform: translate3d(0px, 0px, 0px);
  /* stylelint-enable */
}

/* stylelint-disable */
/**
 * The direct container for .owl-items.
 * It has the items in one line, and gets `traslate`d when the nav happens
 *
 * 1) As per the designer's request, slowing down the transition towards the
 *    end https://praetoriangroup.atlassian.net/browse/PGH-200?focusedCommentId=200524#comment-200524
 *    !important is needed since OwlCarousel when triggering the movement
 *    changes the whole `transition` property.
 */
 /* stylelint-enable */
.owl-stage {
  position: relative;
  box-sizing: border-box;
  transition-timing-function: ease-in-out !important; /* 1 */
  /* stylelint-disable */
  /* autoprefixer: ignore next */
  -ms-touch-action: pan-Y;
  /* stylelint-enable */
  transform-style: flat;

  &::after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ".";
    line-height: 0;
  }
}

/**
 * The carousel items. Will serve as containers to whatever was the children
 * or selected descendents of the element that becomes a carousel
 */
.owl-item {
  position: relative;
  float: left;
  box-sizing: border-box;
  min-height: 1px;
  /* stylelint-disable */
  /* autoprefixer: ignore next */
  -webkit-backface-visibility: hidden;
  /* autoprefixer: ignore next */
  -webkit-tap-highlight-color: transparent;
  /* autoprefixer: ignore next */
  -webkit-touch-callout: none;
  /* stylelint-enable */

  img {
    /* stylelint-disable */
    /* autoprefixer: ignore next */
    -webkit-transform-style: preserve-3d;
    /* stylelint-enable */
  }
}

/* Navigation
 * ======================================================================= */

.owl-nav.disabled,
.owl-dots.disabled {
  display: none;
}

/**
 * The Back, Forward and pagination controls.
 *
 * 1) From some point onward from 2.2.0 these are <button>s. Resetting
 *    default style from browsers.
 */
.owl-prev,
.owl-next,
.owl-dot {
  padding: 0; /* 1 */
  cursor: hand;
  cursor: pointer;
  user-select: none;
  border: none; /* 1 */
  outline: none; /* 1 */
  background: none; /* 1 */
  box-shadow: none; /* 1 */
  font-size: 1rem;
  appearance: none; /* 1 */
}

/**
 * Prev/Next buttons - Font Awesome arrows
 *
 * 1) The actual "prev"/"next" text remains, but made invisible
 * 2) For easier tapping
 * 3) Fixes bug with fz:0 text showing in old Webkits
 * 4) IE9 needs this for the controls not to go "behind" the slides
 * 5) Otherwise the arrows will be slightly shifted down
 * 6) !important is to fight specificity caused by the font-family mixin
 *    (.isFontsNotLoaded button).
 */
.owl-prev,
.owl-next {
  position: absolute;
  z-index: 2000; /* 4 */
  top: 50%;
  overflow: hidden; /* 3 */
  width: $owl-carousel-side-margin; /* 2 */
  margin-top: -22px;
  color: #d6d6d6; // TODO: CROSS-1352
  font-family: $icon-font !important; /* 6 */
  font-size: 0; /* 1 */

  &.disabled {
    &,
    &:hover {
      opacity: 0.5;
    }
  }

  &::after {
    font-size: 44px;
    line-height: 1; /* 5 */
  }
}

.owl-prev {
  left: -$owl-carousel-side-margin;
  text-align: left;

  &::after {
    content: "\f104"; // .Icon--angle-left
  }
}

.owl-next {
  right: -$owl-carousel-side-margin;
  text-align: right;

  &::after {
    content: "\f105"; // .Icon--angle-right
  }
}

/* Owl Carousel - Animate Plugin
 * ======================================================================= */

.owl-carousel {
  /**
   * A generic class added to all elements during THEIR animation
   *
   * 1) transitioning opacity is added to the items for the "after drag" state
   *    in owl-carousel--single-item. In conjunction with animation being set
   *    for these .animated classes, that could cause bugs (and is already
   *    doing so in Safaris).
   */
  .animated {
    animation-fill-mode: both;
    transition: none; /* 1 */
  }

  .owl-animated-in {
    z-index: 0;
  }

  .owl-animated-out {
    z-index: 1;
  }

  /**
   * animateIn and animateOut for slides with background image
   * Pass these classes in init JS for Owl Carousel as `animateIn` and
   * `animateOut` correspondingly
   */
  .isAnimatingFadeIn {
    z-index: 1;
    animation: fadeIn 0.4s ease 0s;
  }

  .isAnimatingFadeOut {
    z-index: 0;
    animation: fadeIn 0.4s ease 0s reverse;
  }

  /**
   * animateIn and animateOut for slides, in which text is by itself, without
   * background image. Using custom animation-timing-function to help reduce
   * "melting" of the slides' text in Webkit/blink
   * Pass these classes in init JS for Owl Carousel as `animateIn` and
   * `animateOut` correspondingly
   */
  .isAnimatingFadeInText {
    z-index: 1;
    // animation: fadeIn 0.3s cubic-bezier(.37,0,.64,1) 0s;
    animation: fadeIn 0.4s linear 0s;
  }

  .isAnimatingFadeOutText {
    z-index: 0;
    // animation: fadeIn 0.3s cubic-bezier(.37,0,.64,1) 0s reverse;
    animation: fadeIn 0.4s ease-out 0s reverse;
  }
}

/* Auto Height plugin
 * ======================================================================= */

.owl-height {
  transition: height 500ms ease-in-out;
}

/* Lazy Load Plugin
 * ======================================================================= */

.owl-lazy {
  transition: opacity 400ms ease;
  opacity: 0;
}

/* stylelint-disable */
img.owl-lazy {
/* stylelint-enable */
  transform-style: preserve-3d;
}

/* Video Plugin
 * ======================================================================= */

.owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-video-play-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  cursor: pointer;
  transition: transform 100ms ease;
  background: url("owl.video.play.png") no-repeat;
  /* stylelint-disable */
  /* autoprefixer: ignore next */
  -webkit-backface-visibility: hidden;
  /* stylelint-enable */

  &:hover {
    transform: scale(1.3, 1.3);
  }
}

.owl-video-playing {
  .owl-video-tn,
  .owl-video-play-icon {
    display: none;
  }
}

.owl-video-tn {
  height: 100%;
  transition: opacity 400ms ease;
  opacity: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.owl-video-frame {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

/* @define Header */

$_header-font-size: $font-size-base;
$_header-top-font-size: $font-size-s;
$_header-top-right-side-padding: 1.25rem;

/**
 * There are several Header elements that are links and that look and behave
 * the same way.
 * TODO: for now using a mixin for them, but ideally it should be a class.
 *
 * 1) Resetting defaults from <button>
 */
@mixin _header-link {
  cursor: pointer;
  color: $header-new-top-link-color;
  border: none;
  background: none;
  font-family: inherit /* 1 */;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    transition: color 0.3s ease;
    text-decoration: none;
    color: $header-new-top-link-hover-color;
  }
}

/**
 * The component
 *
 * 1) [IE] The header would sometimes get taller for no apparent reason
 *    otherwise.
 * 2) Applying the bottom part's bg here. It will serve as a bg around an
 *    opened submenu in tablet/mobile view.
 */
.Header {
  @include font-family($font-family-header);

  position: sticky;
  z-index: 9993;
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  background: $header-new-bg; /* 2 */
  font-size: $_header-font-size;
  line-height: 1.1875;

  @media print {
    display: none;
  }

  @media (max-width: $screen-tablet-l) {
    font-size: $font-size-sm;
  }

  // The same media query as in .Banner--sticky!
  @media (max-width: $screen-tablet-l), (max-height: $screen-tablet-s) {
    position: relative;
  }

  .is-noPosSticky & {
    position: relative;
  }
}

/* Top part
 * ======================================================================= */

.Header-topBlock {
  display: flex;
  min-height: $header-top-min-height;
  color: $black;
  background: $white;
  font-size: $_header-top-font-size;
  line-height: 1.1875;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $screen-tablet-s) {
    min-height: inherit;
    flex-wrap: wrap;
  }
}

/**
 * The top left part of the header (well, duh!), for some featured news link
 * or a small text-only add.
 * As of now, the ads render the whole NewsLine component, and if the padding
 * is on its site, Chrome collapses the empty Header-topLeft even if its
 * parent has `align-items: stretch;`. So, since there is always some kind of
 * a NewsLine inside here (judjing from the current markup), we can move the
 * padding from it to this element, which solves that problem.
 */
.Header-topLeft {
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: $screen-tablet-l) {
    width: 40%;
  }

  @media (max-width: $screen-tablet-s) {
    width: 100%;
    max-width: none;
    white-space: normal;
  }

  &:empty {
    @media (max-width: $screen-tablet-s) {
      display: none;
    }
  }
}

/**
 * 1) [IE11] By default it gets aligned to the top, but this would also help
 *    if the top block got taller for some reason.
 */
.Header-topRight {
  display: flex;
  align-self: stretch; /* 1 */
  min-height: inherit;
  text-transform: uppercase;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: $screen-tablet-s) {
    width: 100%;
  }
}

/**
 * The Top Right Container for the Search Button and Cub Menu.
 *
 * 1) To prevent the block from jumping after the Cub menu gets loaded.
 * 2) In the design on desktop the login form is to the rightmost, while on
 *    mobile we decided to move it to the left. yet there are cases when there
 *    is no Search form here, but we still want the login at the left on mobile.
 */
.Header-topRightGroup {
  position: relative;
  display: flex;
  flex-direction: row-reverse; /* 2 */
  min-width: 360px; /* 1 */
  margin-left: 50px;
  padding: 0.5rem $_header-top-right-side-padding;
  color: $white;
  background: $header-new-usermenu-bg;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  align-self: stretch;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 100%;
    content: "";
    transform: skewX(-20deg);
    transform-origin: 0 0;
    background: $header-new-usermenu-bg;
  }

  @media (max-width: $screen-tablet-l) {
    min-width: 0; /* 1 */
    margin-left: 40px;
    justify-content: space-between;
  }

  @media (max-width: $screen-tablet-s) {
    flex-direction: row; /* 2 */
    align-items: flex-start;
    width: 100%;
    margin-left: 0;

    &::before {
      content: none;
    }
  }
}

/**
 * A wrapper for Cub's login widget menu
 *
 * 1) In case of long names, and a userpic in the future this block is limited
 *    in witdh by default. The overflowing/text-overflow is on the el with
 *    text itself (inside the widget).
 * 2) To have the menu in the collapsed centered in mobile view.
 *    [chrome] the magic number ~ parent height, because mih:100% won't work in
 *    webkits
 * 3) [IE11] Otherwise max-width would wreck its position.
 * 4) [IE11] Older browsers have elements with pos:a in flex flow, and thus
 *    alignment might not work properly. Fixing that by manually setting low
 *    `order` value on the child that should go first. For some reason, setting
 *    order on a pseudo doesn't work in IE11.
 * 4a) And fixing it from being overlapped by the skewed pseudo
 */
.Header-topLogin {
  position: relative; /* 4a */
  z-index: 10; /* 4a */
  order: -1; /* 4 */
  flex-grow: 0; /* 3 */
  width: $header-login-max-width;
  margin: 0 0 0 2.5rem;

  @media (max-width: $screen-tablet-l) {
    margin-left: 1.5rem;
  }

  @media (max-width: $screen-tablet-s) {
    display: flex; /* 2 */
    width: auto;
    max-width: inherit;
    min-height: 18px; /* 2 */
    align-items: center; /* 2 */
    margin-left: 0;
  }

  &:only-child {
    margin-left: 0;
  }
}

/**
 * EXCEPTION. Unfortunately, the LID widget does not provide a way to customize
 * either what items to render or not render, or classnames for that. So mixing
 * components is the only way to differentiate between layouts where the Org
 * item should be visible (GrantFinder) and those that don't need it (everything
 * else)
 */
.Header-topLogin--noOrg {
  .cub-Menu-item--org {
    display: none;
  }
}

/**
 * EXCEPTION. Unfortunately, the LID widget does not provide a way to disable
 * registration elements. So mixing components is the only way to hide them.
 */
.Header-topLogin--noReg {
  .cub-Menu-link--login {
    padding-right: 0;

    &::after { content: none; }
  }

  .cub-Menu-link--register {
    display: none;
  }
}

/**
 * Links inside the Header. Usecase: Search link with icon.
 *
 * 1) Have to set font-size value, because .Form has 14px font-size.
 * x) TODO: too many hacks because of the fact that we're using .Form there and
 *    possibly the <button>/<a> dichotomy for that link. The first part is a
 *    very good examply why we must not mix components. To be revised.
 */
.Header-link {
  @include _header-link;
  @include font-family($font: $font-family-header, $caps: true);

  position: relative;
  height: 1.5em; /* 1, x */
  padding: 0 0 0 1.5rem;
  vertical-align: middle;
  font-size: $_header-top-font-size; /* 1 */
  line-height: 1rem; /* x */

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    content: "\e801"; // .Icon--search
    transform: translateY(-50%);
    font-family: $icon-font;
    font-size: $font-size-sm;
    line-height: 0.8;

    @media (max-width: $screen-mobile-xl) {
      right: 0;
      left: auto;
    }
  }
}

.Header-link--help {
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    content: "\e811"; // .Icon--help
    transform: translateY(-50%);
    font-family: $icon-font;
    font-size: $font-size-sm;
    line-height: 0.8;

    @media (max-width: $screen-mobile-xl) {
      right: 0;
      left: auto;
    }
  }
}

.Header-linkText {
  display: inline;
  vertical-align: middle;

  @media (max-width: $screen-mobile-xl) {
    display: none;
  }
}

/**
 * 1) In case of a long text inside Header-topLeft
 */
.Header-topSocials {
  white-space: nowrap; /* 1 */

  @media (max-width: $screen-tablet-s) {
    display: none;
  }
}

/* Middle part
 * ======================================================================= */

/**
 * The bottom part of the Header - the logo and the menu's container
 * To be exact - the outermost wrapper with unlimited widht (and inside it
 * there is a Container)
 *
 * 1) Repeating the .Container behaviour to align Header with Page Content
 * properly.
 */
.Header-bottomBlock {
  position: relative;
  display: flex;
  color: $white;

  @media (max-width: $screen-tablet-l) {
    max-width: 960px; /* 1 */
    margin: 0 auto;
    flex-wrap: wrap;
  }

  @media (max-width: $screen-tablet-l - 1) {
    max-width: 748px; /* 1 */
  }
}

/**
 * The logo in the header
 *
 * 1) We need to limit the logo's height for a) tall images not stick out of
 *    the header (apocaliptic case), and b) relatively tall images not stick
 *    out of the expanded search form on tablet.
 */
.Header-logo {
  width: $header-rebrand-logo-width;
  height: 50px;
  max-height: 50px;
  margin: 15px 20px;
  padding: 0;
  border: none;
  background: none;

  @media (max-width: $screen-tablet-l) {
    width: auto;
    max-width: 255px;
    height: 48px; /* 1 */
    max-height: 48px; /* 1 */
    margin: 8px 16px;
  }

  @media (max-width: $screen-mobile-xl) {
    max-width: 200px;
    height: 34px; /* 1 */
    max-height: 34px; /* 1 */
  }
}

/**
 * A block for a logo aligned to the right [CROSS-1006]
 */
.Header-logo--right {
  @include _header-link;

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: auto;
  border: none;
  background: none;
}

/**
 * A container for social links at the bottom of the menu. Becomes visible on
 * medium-sized tablets when the social links at the top get hidden.
 */
.Header-socialBar {
  display: none;
  margin: 0 0 0 0.625rem;

  @media (max-width: $screen-tablet-s) {
    display: block;
  }
}

/**
 * A piece of text not related to anything interactive CROSS-1006
 * Will possibly reconsider it.
 *
 * 1) All this junk is to make the "take minimum width" work in Edge and IE.
 */
.Header-strayText {
  @include font-family($font: $font-family-header, $caps: true);

  // autoprefixer off
  // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix
  display: -ms-grid; /* 1 */
  -ms-grid-columns: min-content; /* 1 */
  // stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix
  // autoprefixer on
  width: min-content;
  margin: 0 0.4rem;
  text-align: center;
  font-size: $font-size-s;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

/* Menu
 * ======================================================================= */

/**
 * A container for the Mobile Menu Button (needed for layout allocation)
 */
.Header-menuToggle {
  position: relative;
  display: none;
  width: 61px;
  height: 100%;
  perspective: 75px;
  margin-left: 0.625rem;

  @media (max-width: $screen-tablet-l) {
    display: block;
  }

  @media (max-width: $screen-mobile-xl) {
    width: 50px;
    height: 50px;
  }
}

/**
 * Mobile Menu hamburger-button
 *
 * 1) Overriding <a> defaults
 * 2) Fallback for flex-less browsers (center the element inside it at least
 *    somehow)
 */
.Header-menuToggleBtn {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1em 0.8em; /* 2 */
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: transform, color;
  transform: rotateY(0deg);
  transform-origin: center center;
  color: $white;
  border: none;
  background: none;
  font-size: 20px;
  line-height: 1.2;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;

  @media (max-width: $screen-mobile-xl) {
    font-size: 16px;
    line-height: 1.25em;
  }

  &:focus {
    outline: none;
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 1em;
    right: 0.8em;
    bottom: 1em;
    left: 0.8em;
    content: "";
    transition-timing-function: ease-in-out;
    transition-duration: 0.5s;
    transition-property: top, right, bottom, left, border-radius, background-color;
    border-radius: 3px;
    background: $header-menu-toggle-bg;

    @media (max-width: $screen-mobile-xl) {
      top: 0.875em;
      right: 0.75em;
      bottom: 0.875em;
      left: 0.75em;
    }
  }

  &:hover { /* 1 */
    text-decoration: none;
    color: $white;
  }

  &.active,
  &.is-active,
  .sfHover & {
    transform: rotateY(-180deg);
    color: $header-menu-toggle-active-color;

    @media (max-width: $screen-mobile-xl) {
      margin: 0;
      line-height: 3.125;
    }

    &,
    &::before {
      transition-timing-function: ease;
      transition-duration: 0.35s;
    }

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 0;
      background-color: $header-menu-toggle-active-bg;
    }
  }

}

/**
 * The Main menu. Contains the hamburger toggler (visible only on <tablet) and
 * the menu itself
 * Using manual media queries to switch between the default and the mobile
 * views instead of util classes because the switching is not tied to any other
 * layout breakpoints (Grid and Page layout changes, etc.) that those util
 * classes should be tied to.
 *
 * 1) [IE 11] An attempt to fix an elusive bug, when IE11 would add a large
 *    bottom border to this element.
 */
.Header-menu {
  border: none; /* 1 */

  @media (max-width: $screen-tablet-l) {
    width: 100%;
  }
}

/**
 * The contents of the menu. Introducing a separate element (instead of just
 * putting everything in the component) for the tablet view of the main menu -
 * the contents there should be hidden by default
 *
 * 1) Or the bg image of the Header-bottomBlock sticks out at the bottom
 *    "overflowing" this container's padding
 */
.Header-menuContent {
  @media (max-width: $screen-tablet-l) {
    display: none;
    padding: 1rem 0;
    background: $header-new-bg; /* 1 */

    .active & {
      display: block;
    }
  }
}

/**
 * The main menu contents; just a block.
 */
.Header-menuMain {
  display: block;
  margin: 0;
}

.Header-menuMobile {
  display: none;

  @media (max-width: $screen-tablet-l) {
    display: block;
  }
}

/* Cub menu. TODO: merge this section into Cub styles when Header-new goes live
 * ======================================================================= */

.cub-Menu {
  @media (max-width: $screen-tablet-s) {
    padding: 0;

    &.is-menuOpened {
      background: none;
    }
  }
}

.cub-Menu-item {
  @media (max-width: $screen-tablet-l) {
    font-size: inherit; // TODO: overriding old styles
  }
}

/**
 * There are cases (GrantFinder) when the Organization menu item should be
 * visible as well.
 *
 * 1) The submenu is floaded; clearing that
 */
.cub-Menu-item--user,
.cub-Menu-item--org {
  display: inline-block;

  @media (max-width: $screen-tablet-s) {
    display: block;
    float: none;
    overflow: hidden; /* 1 */
  }
}

.cub-Menu-item--org {
  margin-left: 1.25rem;

  @media (max-width: $screen-tablet-s) {
    margin-top: 0.625em;
    margin-left: 0;
  }
}

.cub-Menu-link,
.cub-Menu-submenuLink {
  &:hover {
    color: $header-new-cub-menu-hover-color !important;
  }
}

.cub-Menu-link {
  line-height: 1.4375rem;

  @media (max-width: $screen-tablet-l) {
    // TODO: reflow
    line-height: 1.875rem;
  }

  @media (max-width: $screen-tablet-s) {
    line-height: inherit;
  }
}

.cub-Menu-link--userMenu {
  @media (max-width: $screen-tablet-l) {
    text-align: left;
    line-height: 30px;
  }

  @media (max-width: $screen-tablet-s) {
    max-width: 280px;
    line-height: 20px;
  }
}

/**
 * The User's dropdown menu
 *
 * 1) Keeping it sticking to the bottom edge of the Header-top
 * 2) The top border is intended, making sure to not reset it as well
 */
.cub-Menu-itemsContainer {
  position: absolute;
  top: 31px; /* 1 */
  right: (- $_header-top-right-side-padding);
  left: auto;
  width: 340px;
  border-bottom: 1px solid $header-new-cub-menu-border;
  border-left: 1px solid $header-new-cub-menu-border;
  background: $header-new-usermenu-bg;

  @media (max-width: $screen-tablet-l) {
    position: absolute;
    top: 30px; /* 1 */
  }

  @media (max-width: $screen-tablet-s) {
    position: relative;
    top: 0;
    right: auto;
    width: 100%;
    border-bottom: none; /* 2 */
    border-left: none; /* 2 */
  }

  &::after {
    content: none;
  }
}

.cub-Menu-submenuItem {
  @media (max-width: $screen-tablet-l) {
    font-size: inherit;
  }
}

.cub-Image--medium {
  @media (max-width: $screen-mobile-xs) {
    width: 115px;
    height: 115px;
  }
}

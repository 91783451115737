/**
 * @define Callout
 *
 * This component usually is for blocks of text that are somewhat part of the
 * main article but stand out visually. E.g.: Promo text under a video,
 * "Editor's note", etc.
 */

$_callout-promo-arrow-bg-width: 1.875rem;
$_callout-ribbon-offset-top: 1rem;
$_callout-ribbon-offset-left: 1.25rem;

/**
 * The vanilla Callout
 * Not yet used or even designed.
 * ? If the intro text here http://responsive.policeone.com/
 * policeone/LODD-archive-template.html
 * is supposed to be a vanilla Callout, then it's confusingly similar to
 * a .Box--bg (with smaller padding).
 */
.Callout {
  position: relative;
  z-index: 1;
  margin: 0 0 1.625rem;
  padding: 1.125rem;
  border: 1px solid $border-color;
  background: $gray-light;

  @media (max-width: $screen-mobile-xl) {
    margin-bottom: 1em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A Callout with an chevron on a blue-ish bg at the right.
 * E.g.: Promo text under a video (CROSS-140).
 *
 * 1) Using 2 pseudos - ::after for the chevron and ::before for its bg) as
 *    the bg has pos:a, and the chevron needs to be centered vertically, thus
 *    needs pos:a + transform. This is achievable with flex, but that won't
 *    work in IE9, and we also turn off flex for Android 4.3-
 * 2) So that it overlaps the border
 * 3) This block needs a visually 40px gap before and after it in all cases.
 *    It's an exception of a top margin, by the designer requst
 *    https://praetoriangroup.atlassian.net/browse/CROSS-140?focusedCommentId=
 *    161846&page=com.atlassian.jira.plugin.system.issuetabpanels:
 *    comment-tabpanel#comment-161846
 */
.Callout--promo {
  margin: $article-item-mbottom 0; /* 3 */
  padding-left: 1.125rem + $_callout-promo-arrow-bg-width;
  font-size: $font-size-sm;

  @media (max-width: $screen-mobile-xl) {
    margin: 1rem 0;
  }

  @media print {
    background: none;
  }

  &::before { /* 1 */
    position: absolute;
    z-index: 10;
    top: -1px; /* 2 */
    bottom: -1px; /* 2 */
    left: -1px; /* 2 */
    width: $_callout-promo-arrow-bg-width;
    content: "";
    background: $link-color;

    @media print {
      content: none;
    }
  }

  &::after { /* 1 */
    position: absolute;
    z-index: 11;
    top: 50%;
    left: $_callout-promo-arrow-bg-width / 2;
    content: "\f105"; // .Icon--angle-right
    transform: translate(-50%, -50%);
    color: $white;
    font-family: $icon-font;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
  }

  &:first-child {
    margin-top: 0;
  }
}

/**
 * "Editor's note"
 *
 * 1) Had to rely on bit top margin as otherwise the small margin "for the
 *    ribbon" would collapse with the preceding element's bottom margin.
 *    Decided to not use "prevent negative-margins collapsing" trick as here
 *    it's a) unpredictable (what if the not is placed in the middle of the
 *    content), and b) more fragile, as this one has positive top margin for
 *    a ribbon.
 * 2) Bigger top padding, otherwise the ribbon would be too close to the text
 * 3) The ribbon's main part with the text is via a pseduo
 * 4) The ribbon's back part is via a pseudo as well. That way we don't need
 *    any extra elements.
 */
.Callout--ribbon {
  margin-top: 2rem + $_callout-ribbon-offset-top; /* 1 */
  margin-left: $_callout-ribbon-offset-left;
  padding-top: 1.8rem; /* 2 */

  @media (max-width: $screen-mobile-xl) {
    margin-top: 2.2rem; /* 1 */
  }

  @media print {
    background: none;
  }

  &::before { /* 3 */
    @include font-family($font: $font-family-alt, $line-height: 1);

    position: absolute;
    z-index: 10;
    top: - $_callout-ribbon-offset-top;
    left: - $_callout-ribbon-offset-left;
    padding: 0.45rem 0.6rem 0.3rem;
    content: "Editor's Note:";
    color: $white;
    background: $callout-ribbon-bg;
    font-size: 1.25rem;

    @media print {
      background: $white;
    }
  }

  &::after { /* 4 */
    position: absolute;
    z-index: -1;
    top: 0.94rem;
    left: - $_callout-ribbon-offset-left;
    content: "";
    border-width: 0.6rem 0 0 $_callout-ribbon-offset-left;
    border-style: solid;
    border-color: $callout-ribbon-back-bg transparent;

    @media print {
      content: none;
    }
  }

  &:first-child {
    margin-top: $_callout-ribbon-offset-top; /* 1 */
  }
}

/**
 * @define TooltipHolder
 */

$tooltip-arrow-size: 5px;

// TODO: refactor this shit
.TooltipHolder {
  position: relative;

  &:hover {
    .Tooltip {
      z-index: 9999;
      transition: opacity 0.2s ease 0s, z-index 0s linear 0s;
      opacity: 1;
    }
  }
}

/**
 * @define Tooltip
 ========================================================================= */

/**
 * Base class for tooltips
 *
 * 1) To emulate the fade effect we need opacity (visual side) and z-index for
 *    actual hiding. z-index is the only prop that a) allows to hide an el
 *    (with negative value) and b) can be used in `transition`. So when
 *    fading out, we first transition opacity for the visual effect and AFTER
 *    THAT quickly hide the el with negative z-index.
 *    The fade-in is to be applied where the element needs to get shown
 * 2) The arrow, emulated via borders (set in placement modifiers)
 * 3) pos:a elements do shrink-to-fit, so using strict width
 * 4) Leaving some empty space for the arrow to ju-ust touch the target's edge
 */
.Tooltip {
  position: absolute;
  z-index: -10; /* 1 */
  width: 200px; /* 3 */
  padding: 0.5rem;
  transition: opacity 0.2s ease 0s, z-index 0s linear 0.2s; /* 1 */
  opacity: 0; /* 1 */
  color: $white;
  border: $tooltip-arrow-size solid transparent; /* 4 */
  background: $btn-bg-hover;
  background-clip: padding-box; /* 4 */

  &::before { /* 2 */
    position: absolute;
    content: "";
    border-style: solid;
    border-color: transparent;
  }
}

/**
  * A little bit wider Tooltip variant
  */
.Tooltip--m {
  width: 230px;
}

/* Placement modifiers
 ======================================================================= */

.Tooltip--bottom {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);

  &::before {
    bottom: 100%;
    left: 50%;
    margin: 0 0 0 (- $tooltip-arrow-size / 2);
    border-width: 0 $tooltip-arrow-size $tooltip-arrow-size;
    border-bottom-color: $btn-bg-hover;;
  }
}

.Tooltip--top {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);

  &::before {
    top: 100%;
    left: 50%;
    margin: 0 0 0 (- $tooltip-arrow-size / 2);
    border-width: $tooltip-arrow-size $tooltip-arrow-size 0;
    border-top-color: $btn-bg-hover;;
  }
}

.Tooltip--left {
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);

  &::before {
    top: 50%;
    left: 100%;
    margin: (- $tooltip-arrow-size / 2) 0 0;
    border-width: $tooltip-arrow-size 0 $tooltip-arrow-size $tooltip-arrow-size;
    border-left-color: $btn-bg-hover;;
  }
}

.Tooltip--right {
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);

  &::before {
    top: 50%;
    right: 100%;
    margin: (- $tooltip-arrow-size / 2) 0 0;
    border-width: $tooltip-arrow-size $tooltip-arrow-size $tooltip-arrow-size 0;
    border-right-color: $btn-bg-hover;;
  }
}

/**
  * A mix of the "--top" and "--left" modifiers,
  * when a Tooltip is placed on the very right and should pop up at the top,
  * but with a left shift.
  */
.Tooltip--topLeft {
  right: -1rem;
  bottom: 100%;

  &::before {
    top: 100%;
    right: 1rem;
    margin: 0 0 0 (- $tooltip-arrow-size / 2);
    border-width: $tooltip-arrow-size $tooltip-arrow-size 0;
    border-top-color: $btn-bg-hover;;
  }
}

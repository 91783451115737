@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*
 * 1) Set p:r for make top works (e.g. for Grid)
 */
@keyframes toTop {
  0% {
    position: relative; // *1
    top: 2rem;
  }

  100% {
    position: relative; // *1
    top: 0;
  }
}

/**
 * @define responsive
 * Helper classes for dealing with screens of different types
 *
 * Only here `!important` is allowed for general use
 */

/* Hiding/showing stuff
 ====================================================== */

// ~480px
.u-hiddenMobileXL {
  @media (max-width: $screen-mobile-xl) {
    display: none !important;
  }
}

.u-visibleMobileXL {
  @media (min-width: $screen-mobile-xl + 1) {
    display: none !important;
  }
}

// ~560px
.u-hiddenMobileXXL {
  @media (max-width: $screen-mobile-xxl) {
    display: none !important;
  }
}

.u-visibleMobileXXL {
  @media (min-width: $screen-mobile-xxl + 1) {
    display: none !important;
  }
}

// ~640px
.u-hiddenMobileXXXL {
  @media (max-width: $screen-mobile-xxxl) {
    display: none !important;
  }
}

.u-visibleMobileXXXL {
  @media (min-width: $screen-mobile-xxxl + 1) {
    display: none !important;
  }
}

/**
 * 768 (regular tablets in Portrait)
 */
// Hiding or showing on UP TO certain screen sizes and below
.u-hiddenTabletS {
  @media (max-width: $screen-tablet-s) { display: none !important; }
}

.u-visibleTabletS {
  @media (min-width: $screen-tablet-s) { display: none !important; }
}

// Hiding or showing on UP TO certain screen sizes and below
.u-hiddenUptoTabletS {
  @media (max-width: $screen-tablet-s + 1) { display: none !important; }
}

.u-visibleUptoTabletS {
  @media (min-width: $screen-tablet-s + 1) { display: none !important; }
}

/**
 * 1024 (regular tablets in Landscape)
 * TODO: convert all other utils to this format
 */
// Hiding or showing on UP TO certain screen sizes and below
.u-hiddenTabletL {
  @media (max-width: $screen-tablet-l) { display: none !important; }
}

.u-visibleTabletL {
  @media (min-width: $screen-tablet-l) { display: none !important; }
}

// Hiding or showing on UP TO certain screen sizes and below
.u-hiddenUptoTabletL {
  @media (max-width: $screen-tablet-l + 1) { display: none !important; }
}

.u-visibleUptoTabletL {
  @media (min-width: $screen-tablet-l + 1) { display: none !important; }
}

/**
 * 1220 (small desktop - but actually a magic number derived from our design)
 */
.u-hiddenDesktopS {
  @media (max-width: $screen-desktop-s) { display: none !important; }
}

.u-visibleDesktopS {
  @media (min-width: $screen-desktop-s) { display: none !important; }
}

.u-hiddenUptoDesktopS {
  @media (max-width: $screen-desktop-s + 1) { display: none !important; }
}

.u-visibleUptoDesktopS {
  @media (min-width: $screen-desktop-s + 1) { display: none !important; }
}

/**
 * The print mode
 */
.u-hiddenPrint {
  @media print {
    display: none !important;
  }
}

.u-visiblePrint {
  @media not print {
    display: none !important;
  }
}

/**
 * Sets a scope, in which we don't hide CERTAIN components/elements on print.
 * Of cource it doesn't apply to all components, for example SocialLinks are
 * not needed on print in any possible case; but Section might be.
 */
.u-visiblePrintScope {
  @media print {
    display: block !important;
  }
}

/**
 * If JS is enabled or disabled
 *
 * 1) We DO NOT normally use tag selectors. This one is an exception since
 *    there is no other way to make it work without breaking a previously set
 *    `display` value.
 */
.u-visibleNoJs {
  html:not(.is-noJs) & { /* 1 */
    display: none !important;
  }
}

.u-hiddenNoJs {
  .is-noJs & {
    display: none !important;
  }
}

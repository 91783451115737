/** define Spinner */

/**
 * A generic component for a spinner that should indicate that something is
 * loading.
 * TODO: Might want to replace .Form-spinnerBlock with this.
 */
.Spinner {
  @include spinner;

  display: block;
}

/**
 * When running above a background fill
 */
.Spinner--invertedColor {
  color: $white;
}

/**
 * It's tricky to page-center the spinner that is placed deeply in the markup
 * (.Page > .Page-inner > .Component> ... > .Spinner). So using this trick
 * This is NOT supposed to be an overlay, only a generic spinner when preferable
 * styling options of the "loading" state - such as replacing the button icon -
 * are not applicable.
 *
 * 1) Just in case this might actually overlap with anything.
 * 2) To avoid shifting the tall footer too much.
 */
.Spinner--fullPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* 1 */

  @media (max-width: $screen-tablet-l) {
    position: relative;
    height: 80px; /* 2 */
  }
}

$_cw-avatar-width: 75px; /* 1 */
$_cw-avatar-margin-r: 1.25rem;
$_cw-avatar-margin-l: 0.625rem;

.cw-Comment {
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
  padding: 0.625rem 0 0;
  border-top: 1px solid $border-color;

  &.is-ratingNegative {
    .cw-Comment-avatar,
    .cw-Comment-controls,
    .cw-Comment-text,
    .cw-Comments--reply {
      display: none;
    }
  }
}

.cw-Comment-content {
  display: flex;
}

/**
 * User Avatars.
 */
.cw-Comment-avatar {
  width: $_cw-avatar-width; /* 1 */
  margin: 0 $_cw-avatar-margin-r 0 $_cw-avatar-margin-l;
  align-self: flex-start;

  @media (max-width: $screen-mobile-xl) {
    margin-left: 0;
  }
}

.cw-Comment-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - #{$_cw-avatar-width} - #{$_cw-avatar-margin-r});
}

.cw-Comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: $red-link;
  font-size: $font-size-s;
  line-height: 1.17;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $screen-mobile-xl) {
    flex-direction: column;
  }
}

.cw-Comment-info {
  margin-bottom: 0;

  @media (max-width: $screen-mobile-xl) {
    margin-bottom: 0.5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cw-Comment-author {
  margin-right: 1.25rem;
  font-weight: bold;

  @media (max-width: $screen-mobile-xl) {
    margin-right: 0;
  }
}

.cw-Comment-date {
  color: $gray-dark;

  @media (max-width: $screen-mobile-xl) {
    display: block;
  }
}

.cw-Comment-controls {
  @media (max-width: $screen-mobile-xl) {
    align-self: flex-end;
  }
}

/**
 * Comment Text
 * 1) Comments text truncation. As we're using white-space: pre-wrap (*2)
 *    and because it's a user input, the text truncation is needed.
 * 2) The text could be a quite long, e.g. contents URLs.
 */
.cw-Comment-text {
  overflow: hidden; /* 1 */
  width: 100%; /* 1 */
  margin-bottom: 0.5rem;
  white-space: pre-wrap; /* 2 */
  text-overflow: ellipsis; /* 1 */
  color: $gray-dark;
}

.cw-Comment-footer {
  align-self: flex-end;
}

.cw-Comments--reply {
  margin: 0 0 0 5rem;

  @media (max-width: $screen-mobile-xl) {
    margin-left: 2.25rem;
  }
}

/* @define Switch */

/* The component
 ========================================================================= */

/**
 * A very basic switch.
 * There are two variants of what is visible and it's controlled by the state
 * of the checkbox. Both variants are in the root of the component, Switch-inner
 * is not needed.
 */
.Switch {
  display: inline-block;
}

.Switch-checkbox {
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;

  &:checked ~ {
    .Switch-on { display: block; }

    .Switch-off { display: none; }
  }
}

// .Switch-inner { }

/**
 * Visible when the checkbox is checked
 */
.Switch-on { display: none; }

/**
 * Visible when the checkbox is NOT checked
 */
.Switch-off { display: block; }

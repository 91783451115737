/** @define Card */

/* Markup examples
• "Featured article" with icons next to a heading
  <div class="Card Card--featured">
    <a href="..." class="Card-imgTitleWrap">
      <div class="Card-imgBlock">
        <img class="Card-img" src="..." alt="...">
      </div>
      <div class="Card-title">
        <h3 class="Card-titleText">Some title...</h3>
        <i class="Icon Icon--comment"><sub class="Icon-subText">(1)</sub></i>
      </div>
    </a>
    <div class="Card-text">
      <p class="Card-subtitle" href="">Some text.</p>
    </div>
    <div class="Card-footer">
      <a class="Link" href="">
        More of <span class="Link-lastWord">something</span>
      </a>
    </div>
  </div>

• Product (in carousels, etc.)
  <div class="Card Card--imgL">
    <a
      class="Card-imgTitleWrap"
      data-js-hover
      href="Some external link"
      target="_blank"
    >
      <div class="Card-imgBlock">
        <img class="Card-img" src="..." alt="...">
      </div>
      <h3 class="Card-title">the title</h3>
    </a>
    <div class="Card-text">
      <p class="Card-subtitle">From _company name_</p>
    </div>
    <p class="Card-footer">
      <a class="Link" href="_internal link_">
        Request <span class="Link-lastWord">info</span>
      </a>
    </p>
  </div>
 */

/**
 * A very generic, usually small block (product, sponsor, video, etc)
 * Has an image, a title, sometimes a footer and other staff
 * The image is centered vertically & horizontally in a link most of the time
 */

.Card {
  display: block;
  margin: 0;
  padding: 0;
}

/* Flex-ible card
  ========================================================================= */

/**
 * These are for making `flex`ible cards, mainly to be able to stretch them
 * so that all cards inside .Grid-item in a row were of the same height
 *
 * 1) If image/video and title are wrapped inside a link - it will be the
 *    filler for height. If not, then Card-text will.
 * 2) Otherwise it could shrink inside flex-ed parent.
 * 3) Giving margins for children instead of padding for .Card itself so that
 *    Card-footer's border could be full-width.
 * 4) Overriding the general zero mb for :last-chlid, because here it has to
 *    rely on it (an EXCEPTION). Card can have a footer that sticks to its
 *    bottom, so Card itself can't have bottom padding as negative mb on the
 *    footer won't work
 * 5) To stretch vertically in a flex parent.
 *    Using height (in case the parent is not flex, but has height set for
 *    example) would actually break the stretching in not so old Chromes and
 *    ecpecially Safaris.
 */
.Card--flex,
.Card--flexSimple {
  display: flex;
  flex-direction: column;
  width: 100%; /* 2 */
  align-self: stretch; /* 5 */
  border: 1px solid $border-color;

  .Card-imgTitleWrap {
    flex: 1 0 auto; /* 1 */
  }

  .Card-imgBlock {
    height: 6.875rem;
    max-height: 6.875rem;
    margin: 1rem 1rem 1.5rem; /* 3 */
  }

  .Card-videoLink {
    margin: 1rem 1rem 1.5rem; /* 3 */
  }

  .Card-text {
    flex-grow: 1; /* 1 */

    &,
    &:last-child { /* 4 */
      margin: 0 1rem 1.25rem;
    }

    &:first-child {
      margin-top: 1rem;
    }
  }

  .Card-footer {
    padding: 0.625em 1.25em;
    border-top: 1px solid $border-color;
  }
}

/**
 * This one just has min-height for text block and centered text.
 * Use case: Sponsors list (CROSS-99)
 */
.Card--flex {
  text-align: center;

  .Card-text {
    min-height: 5em;
  }
}

/**
 * This flex variant is different in several parts: the img is smaller and
 * aligned to the bottom, the text is not centered and is without min-height,
 * and it also has a fancy title (seems to be not that simple actually :))
 * E.g.: Network page (CROSS-132)
 */
.Card--flexSimple {
  .Card-imgBlock {
    height: 5rem;
    max-height: 5rem;
    margin: 1rem 2rem 1.25rem;
  }

  .Card-title {
    @include font-family($font-family-alt);

    font-size: $font-size-ml;
  }

  .Card-footer {
    margin: 0;
  }
}

/* Cards with a fixed size image link
 ========================================================================= */

/**
 * 1) .Picture needs this to inherit from.
 */
.Card--imgL,
.Card--imgM,
.Card--imgXS {
  .Card-imgBlock {
    height: 150px;
    max-height: 150px; /* 1 */
    margin-bottom: 0.4rem;
  }

  .Card-title {
    margin-bottom: 0.4rem;
  }

  .Card-text,
  .Card-subtitle {
    margin-bottom: 0.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * Absolute copy of .Card--imgL, except the image is 1.5 times shorter
 * Use case: Deals, Featured deals (Product Deals page)
 *
 * 1) .Picture needs this to inherit from.
 */
.Card--imgM {
  .Card-imgBlock {
    height: 100px;
    max-height: 100px; /* 1 */
  }
}

/**
 * A modifier for a Card with S-sized image
 * Use Case: Key Statistics on Marketing pages. Moving this size part to
 * a separate class since the --fancyStat is needed in other places where
 * limiting an image isn't needed.
 *
 * 1) .Picture needs this to inherit from.
 */
.Card--imgS {
  .Card-imgBlock {
    height: 64px;
    max-height: 64px; /* 1 */
  }
}

/**
 * Absolute copy of .Card--imgL, except the image is 3x shorter
 * Use case: List of logos on the Sing In page
 *
 * 1) .Picture needs this to inherit from.
 */
.Card--imgXS {
  .Card-imgBlock {
    height: 50px;
    max-height: 50px; /* 1 */
  }
}

/* Card with bordered image
  ========================================================================= */

/**
 * This one has a border around an image link, small title font size.
 * Example case: Featured distributors (Product cat., sidebar; Product cat.
 * unsponsored).
 *
 * 1) If it has ov:h, then part of the letters can be clipped at the bottom
 *    due to small line-height (required by design). Fixing that.
 * 2) .Picture needs this to inherit from.
 */
.Card--imgBordered {
  text-align: center;

  .Card-imgBlock {
    max-width: 200px;
    height: 138px;
    min-height: 138px;
    max-height: 136px; /* 2 */
    margin-bottom: 0.375rem;
    border: 1px solid $border-color;
  }

  .Card-title {
    padding-bottom: 0.2em; /* 1 */
    font-size: $font-size-s;
    line-height: 1.167;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* "Featured" Card
  ========================================================================= */

/**
 * Example case: Featured Arcitle (Product cat., sidebar; Product cat.
 * unsponsored);
 *
 * 1) The title's mb differs for if it's inside imgTitleWrap (mainly - before
 *    an image block) or in the parent itself (before subtitle)
 * 2) Not resetting title and imgBlock's mb for :last-child 'cause there has
 *    to be different gap between "imgBlock and subtitle" and "title and
 *    subtitle". And since they can be wrapped in imgTitleWrap, that could
 *    nullify different bottom margins
 * 3) Otherwise it's too bold on Mac
 */
.Card--featured {
  .Card-imgTitleWrap {
    margin-bottom: 0.5rem; /* 2 */
  }

  .Card-imgBlock {
    margin: 0 0 1.25rem; /* 2 */
  }

  .Card-videoLink {
    margin: 0 0 1.25rem;

    &::after {
      font-size: 70px;
    }
  }

  .Card-title {
    @include mac-font-smoothing; /* 3 */
    @include font-family($font-family-alt);

    display: block;
    margin: 0 0 0.5rem; /* 1, 2 */
    font-size: $font-size-ml;

    &:last-child { /* 2 */
      margin-bottom: 0.5rem;
    }
  }

  > .Card-title {
    margin-bottom: 0.6em; /* 1, 2 */

    &:last-child {
      margin-bottom: 0; /* 2 */
    }
  }

  .Card-subtitle {
    font-size: $font-size-sm;
    line-height: $line-height-general;
  }
}

/**
 * A modifier to remove visual effects from Card's image (Play icon, bg,
 * borders, etc.)
 */

.Card--imgBare {
  .Card-imgBlock {
    background: none;
  }

  .Card-img {
    border: none;
  }

  .Card-videoLink {
    &::after {
      display: none;
    }
  }
}

/**
 * A card with proportional images and fancy heading
 * E.g.: Columnists' page (DESIGN-1837)
 */
.Card--portrait {
  .Card-imgTitleWrap {
    margin-bottom: 0.2rem;
  }

  .Card-imgBlock {
    position: relative;
    width: auto;
    max-width: none;
    margin-bottom: 0.375rem;
    background: $gray-light;

    &::before {
      display: block;
      height: auto;
      padding-top: 88%;
    }
  }

  .Card-img {
    @include center-absolute;
  }

  .Card-title {
    @include font-family($font-family-alt);

    font-size: 1.125rem;
  }
}

/**
 * Not sure how to describe it :)
 * E.g: Items in Key statistics block (Marketing page), items under Grants
 * GrantFinder Lead form (PGH-207)
 *
 * 1) Not limiting the image block in height by default - leaving it for
 *    Card--img... modifiers. For now. Since there are cases where no limiting
 *    is needed.
 * 2) Otherwise it's too bold on Mac
 */
.Card--fancyStat {
  text-align: center;

  .Card-imgBlock { /* 1 */
    margin-bottom: 0.8rem;
  }

  .Card-title {
    @include mac-font-smoothing; /* 2 */
    @include font-family($font: $font-family-alt, $line-height: 1.033);

    margin-bottom: 0.1rem;
    font-size: $font-size-xl;
  }

  .Card-subtitle {
    font-size: $font-size-base;
  }
}

/* Elements - image
  ========================================================================= */

/**
 * This container is for a blocklink for an image and a title
 */
.Card-imgTitleWrap {
  display: block;
  margin-bottom: 0.4rem;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

/**
 * A container for the main Card's image
 * Previousely it was supposed to be a link, but in CROSS-128 it was decided
 * that links will contain an image and a title, and this one will usually be
 * just a wrapper.
 *
 * Unsing ghostering instead of flex because in FF ~38 and below images
 * as flex items lose proportions
 *
 * 1) Ghosting element that forces line-height
 * 2) Removing extra gap
 * 3) So that a full-width image doesn't go on the next line
 */
.Card-imgBlock {
  @include vert-center-ghost;

  display: block;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto 0.625rem;
  text-align: center;
  white-space: nowrap; /* 3 */
  font-size: 0; /* 2 */

  &:last-child {
    margin-bottom: 0 !important;
  }
}

/**
 * Element modifier. If there is no img, but the space needs to be preserved
 */
.Card-imgBlock--empty {
  background: #f8f8f8;
}

/**
 * An image inside a link. Centered vertically & horizontally using ghostering
 *
 * 1) To respect `vertical-align`
 */
.Card-img {
  display: inline-block; /* 1 */
  max-width: 100%;
  max-height: inherit;
  margin: 0 auto;
  vertical-align: middle;
}

/**
 * To be able to use an icon inside Card-imgBlock
 *
 * 1) Prevent an icon with round border from stretching, and also to avoid
 *    unnecessary whitespace
 */
.Card-img--icon {
  line-height: 1; /* 1 */
}

/**
 * A container for a video preview
 * Has proportions of 16:9 and a "play" icon, so the contents have to be
 * absolutely positioned
 *
 * 1) This one will set the container height proportional to its width
 * 2) Setting the proportion
 * 3) Adding a "play" icon
 * 4) ?? (from the prev. version of the styles)
 */
.Card-videoLink {
  @include video-button; /* 3 */

  position: relative;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  margin-bottom: 0.625rem;
  background: $black;
  transform-style: preserve-3d; /* 4 */

  &::before { /* 1 */
    display: block;
    width: 100%;
    padding-top: 56.25%; /* 2 */
    content: "";
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

/**
 * A video (most likely a thumbnail) inside a videoLink
 * Absolutely positioned since the container maintains proportions with
 * a pseudo element. And centered.
 *
 * 1) An image that is marked up with any of HTML5 responsive markup options
 *    is distributed over physical pixels (i.e. not upscaled). So if an img
 *    is not wide enough for a high-dpr screen's `CSS width x DPR`, it will
 *    be smaller than it is expected to be.
 *    The only way to solve this is to set a fixed width/bottom limit. The
 *    img will not look as sharp on a high-dpr screen, but it won't shrink,
 *    and it will at least be not worse a usual 1x image (in many cases -
 *    better).
 */
.Card-video {
  @include center-absolute;

  width: 100%; /* 1 */
}

/* Elements - text
  ========================================================================= */

/**
 * A container for main Card texts. Could contain title, subtitle, arbitraty
 * text
 */
.Card-text {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A title (company name, etc.)
 *
 * 1) This might be on h2-h4 etc, so overriding their styles
 */
.Card-title {
  @include font-family;

  display: block;
  margin: 0 0 0.625rem;
  text-align: inherit; /* 1 */
  text-transform: none; /* 1 */
  font-size: 1rem; /* 1 */
  font-weight: 400; /* 1 */
  line-height: $line-height-general; /* 1 */

  &:last-child {
    margin-bottom: 0 !important;
  }
}

/**
 * A class for the actual Card-title's text, for cases when the title needs
 * to have something else (like icons, date, texts, etc.) on the same line
 * as the text. Namely, give this class to <h2> or <h3>
 */
.Card-titleText {
  @include font-family(inherit);

  display: inline;
  margin-bottom: 0;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  &::after {
    content: none;
  }
}

/**
 * Meta info next to the title (date, icons)
 *
 * 1) Needs to be on the same line with title. d:ib won't work because it will
 *    force the element to a new line if it doesn't fit the remaining width on
 *    the current one
 * 2) Card-meta is supposed to have a left margin ALWAYS. That's because a
 *    :first-child rule will work even if there is only text before an it. If
 *    you don't need that margin (e.g., if it's truly the 1st or the only
 *    child), just use `u-noMargin`.
 */
.Card-meta {
  display: inline; /* 1 */
  margin: 0 0 0 0.4em; /* 2 */
}

/**
 * Additional text
 */
.Card-subtitle {
  display: block;
  margin: 0 0 0.625rem;
  color: $text-color;
  font-size: $font-size-s;
  line-height: $line-height-general;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.Card-footer {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

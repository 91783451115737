/**
 * @define Box
 *
 * Styles for blocks with gray border (and sometimes gray background)
 */

/* Helpers
 * ======================================================================= */

$_box-padding-horz: 1.5rem;
$_box-padding-vert: 1.5rem;
$_box-bg: $gray-light;

/* The component
 * ======================================================================= */

/**
 * A generic Box
 * E.g.: Market Advertising (CROSS-111)
 */
.Box {
  padding: $_box-padding-vert $_box-padding-horz;
  border: 1px solid $border-color;
}

/* Looks-based modifiers
 * ======================================================================= */

/**
 * A .Box without a border
 */
.Box--noBorder {
  border-color: $_box-bg;
}

/**
 * A .Box with gray background
 */
.Box--bg {
  background: $_box-bg;
}

/**
 * A box with gray-ish radial gradient as background
 * E.g.: the 3 blocks on Homepage, PGH.
 */
.Box--bgRoundShadow {
  $_box-shadow-color: #d6d6d6;

  border: none;
  background: $_box-shadow-color;
  background: radial-gradient(circle closest-side, $white, $_box-shadow-color);
}

/**
 * A box with background color according to the site's theme
 */
.Box--bgThemed {
  color: $box-themed-color;
  border: none;
  background: $box-themed-bg;
}

/**
 * A box that has some background at the top right corner. The bg can be
 * either a background image, or and img.Box-bgEl
 *
 * 1) If the bg is bgi, it is set via `style="..."`. To reset it high
 *    specificity is needed.
 */
.Box--bgRight {
  position: relative;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 40% auto;

  @media (max-width: $screen-mobile-xxxl) {
    background-image: none !important; /* 1 */
  }

  > .Box-bgEl {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 40%;
    max-height: 100%;

    @media (max-width: $screen-mobile-xxxl) {
      display: none;
    }
  }

  > .Box-child {
    margin-right: 47%;

    @media (max-width: $screen-mobile-xxxl) {
      margin-right: 0;
    }
  }
}

/* Geometry modifiers
 * ======================================================================= */

/**
 * A Box with smaller margins
 * The variables are in _variables.scss 'cause they are used in other
 * components as well, not only here (Tabs--contentPane, etc.)
 * E.g.: Related article content
 */
.Box--s {
  $_box-small-padding-horz: 0.625rem;
  $_box-small-padding-vert: 1rem;

  padding: $_box-small-padding-vert $_box-small-padding-horz;

  .Box-inset {
    margin: 0 (-$_box-small-padding-horz) $_box-small-padding-vert;
    padding: $_box-small-padding-vert $_box-small-padding-horz;

    &:first-child {
      margin-top: -$_box-small-padding-vert;
    }

    &:last-child {
      margin-bottom: -$_box-small-padding-vert;
    }
  }
}

.Box--m {
  $_box-m-padding: 1rem;

  padding: $_box-m-padding;

  .Box-inset {
    margin: 0 (-$_box-m-padding) $_box-m-padding;
    padding: $_box-m-padding;

    &:first-child {
      margin-top: -$_box-m-padding;
    }

    &:last-child {
      margin-bottom: -$_box-m-padding;
    }
  }
}

/* Elements
 * ======================================================================= */

.Box-logo {
  margin: 0 1rem;
}

/**
 * A box inside of a Box
 */
.Box-inset {
  margin: 0 (-$_box-padding-horz) $_box-padding-vert;
  padding: $_box-padding-vert $_box-padding-horz;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  &:first-child {
    margin-top: -$_box-padding-vert;
    border-top: none;
  }

  &:last-child {
    margin-bottom: -$_box-padding-vert;
    border-bottom: none;
  }
}

/**
 * Use this element as a child of .Box if you need to neglect .Box's padding
 * E.g.: Sites' logos list on Market Advertising
 */
.Box-inset--bare {
  padding: 0 !important;
  border: none !important;
}

/**
 * A multi-purpose element for child elements of a Box.
 * WARNING: most of the time you DON'T NEED TO USE THIS! Just place whatever
 * elements directly inside the Box. Box-child is needed when some contents
 * have to be specifically layed out. E.g., inside Box--bgLeft, etc.
 */
.Box-child {
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.cw-Form {
  margin-bottom: 1rem;
  border: 1px solid $border-color;
  background: $gray-light;

  &:last-child {
    margin-bottom: 0;
  }
}

// Modifiers
/**
  * A variant of the Form for posting comments with a big textarea and footer,
  * included Button and Label.
  */
.cw-Form--comment {
  .cw-Button--close {
    display: none;
  }

  .cw-Form-footer {
    padding: 0.625rem 0 0;
  }

  @media (max-width: 650px) {
    .cw-Button--comment {
      width: 100%;
      text-align: center;
    }
  }

  @media (min-width: 650px) {
    .cw-Form-footer {
      padding: 0 0 0 1rem;
    }
  }

  @media (max-width: 400px) {
    .cw-Button {
      .cw-Button-icon {
        display: none;
      }
    }
  }
}

/**
  * A variant of the Form for writing abuses.
  */
.cw-Form--abuse {
  margin-top: 1rem;
}

/**
  * A variant of the Form for replying to comments.
  */
.cw-Form--reply {
  margin-top: 1rem;

  .cw-Form-label--login {
    display: none;
  }

  .cw-Form-label--reply {
    display: inline;
  }
}

// Elements
/**
  * 1) To keep Spinner block with pos:a centered.
  */
.cw-Form-body {
  @extend .Form-body !optional;

  position: relative; /* 1 */
}

.cw-Form-footer {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  text-align: center;
  border-top: 1px solid $border-color;

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

// Label and it's variants
.cw-Form-label {
  @media screen and (max-width: 650px) {
    display: block;
  }
}

.cw-Form-label--reply {
  display: none;
}

.cw-Form-label--login,
.cw-Form-label--reply,
.cw-Form-label--abuse {
  @include font-family($caps: true);

  margin-right: 1rem;
  font-size: $font-size-s;
  font-weight: bold;
  line-height: 1.2;

  @media (max-width: 650px) {
    margin-right: 0;
    margin-bottom: 0.625rem;
  }
}

.cw-Form-label--checkbox {
  margin-right: 1.25rem;
}

/**
  * 1) Set pos:r to have the inner cw-StatusMsg with pos:a in a right place.
  */
.cw-Form-group {
  position: relative; /* 1 */
}

.cw-Form-spinnerBlock {
  @include spinner;

  position: absolute;
  z-index: 2;
  display: none;
  width: 100%;
  height: 100%;
}

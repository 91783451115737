/** @define CubWidgetProfile */

/**
 * Styles for cub widget profile page.
*/

.cub-Link--changeUsername,
.cub-Link--changeEmail {
  font-weight: bold;
}

/**
 * @define LogoList
 *
 * Components for lists of single images (possibly with links).
 * Decided to make the naming more specific, as the components were created
 * specifically for lists of logos and for now don't seem to have any other
 * area of application.
 */

$_logolist-item-height: 6.25rem;
$_logolist-item-padding-horz: 0.5rem;

/**
 * A simple list of logos
 * E.g. Clients slider on Market Advertising (CROSS-111)
 *
 * 1) Removing gaps between d:ib children
 * 2) Removing browser defaults
 */

.LogoList {
  margin: 0 0 -2rem;
  padding: 0; /* 3 */
  list-style-type: none;
  text-align: center;
  font-size: 0; /* 1 */

  &.owl-carousel {
    margin-bottom: 0;
  }
}

/**
 * Force a LogoList to show only one row (extra elements will be hidden).
 * Use case: using this as a base for carousels (so that on the carousel
 * init there were no major page "judder"). E.g.: Marketing / "Featured
 * Clients and Partners"
 *
 * 1) When the carousel is initialized, avoid cropping the controls.
 */
.LogoList--nowrap {
  flex-wrap: nowrap;
  overflow: hidden;
  height: $_logolist-item-height;
  margin-bottom: 0;

  &.owl-loaded {
    overflow: visible; /* 1 */
  }
}

/**
 * Logos that have bg and border, stretch and form bricks-like grid
 * E.g.: About block on Market Advertising (CROSS-111), Log in page
 *
 * 1) Fallback for browsers without flex
 * 2) Had to make it smaller, as 10.5rem (a should-be) made IE11 create the
 *    2nd line of items when there shouldn't be
 * 3) Compensating for the children's negative margins
 * 4) Let's slack off for now and have the vert padding just equal to horz
 * 5) Giving .Picture something to inherit.
 * 5a) The element has padding, so taking it into account for the inheritable
 *    max-height, otherwise taller images would break the makrup. Also setting
 *    min-height so that image that are not that tall would behave.
 */
.LogoList--fancy {
  $_logolist-fancy-item-height: 4rem;

  display: flex;
  margin: 1px; /* 3 */
  flex-wrap: wrap;

  &.LogoList--nowrap {
    height: $_logolist-fancy-item-height;
  }

  .LogoList-item {
    flex: 1 0 8.5rem; /* 2 */
    width: 10.5rem; /* 1 */
    height: $_logolist-fancy-item-height;
    margin: -1px;
    padding: 0;
    text-align: center;
    border: 1px solid $border-color;
    background: $gray-light;
  }

  .LogoList-link {
    height: $_logolist-fancy-item-height;
    min-height: $_logolist-fancy-item-height; /* 5a */
    max-height: $_logolist-fancy-item-height - 2 * $_logolist-item-padding-horz; /* 5 */
    padding: $_logolist-item-padding-horz $_logolist-item-padding-horz; /* 4 */
  }

  .LogoList-link--showOnHover {
    right: 0;
    left: 0;
    width: 100%;
  }
}

/* Elements
 * ======================================================================== */

/**
 * A single logo - a container like <a> or <li>. In a default case has fixed
 * height, so just d:ib
 *
 * 1) Centering the image inside (by adding a 100% height inline pseudo that
 *    will expand this element's line height to its 100% height and make
 *    vertical-align on the image work). fz:0 come in handy
 * 2) On hover hide the default logo and show its alt.version
 * 3) Remove fixed width and bottom margin if inside a carousel (the carousel
 *    will do the sizing then)
 * 4) Gemoving whitespaces, especially since they could have td:u. Repeating
 *    this rule here because the el. can be used outside of LogoList
 * 5) [webkits] Prevent the ghost pseudo from wrapping on the next line if
 *    there is a -link inside this.
 */
.LogoList-item {
  position: relative;
  display: inline-block;
  width: 9rem;
  height: $_logolist-item-height;
  max-height: $_logolist-item-height;
  margin: 0 0 2rem;
  padding: 0 $_logolist-item-padding-horz;
  vertical-align: top;
  white-space: nowrap; /* 5 */
  font-size: 0; /* 4 */

  &::before {
    content: none;
  }

  &::after { /* 1 */
    display: inline-block;
    width: 0;
    height: 100%;
    content: "";
    vertical-align: middle;
  }

  &:hover { /* 2 */
    > .LogoList-link--hideOnHover {
      opacity: 0;
    }

    > .LogoList-link--showOnHover {
      opacity: 1;
    }
  }

  .owl-carousel & { /* 3 */
    width: auto;
    max-width: 100%;
    margin: 0;
  }
}

/**
 * A logo can be wrapped in a link, which is almost (padding excluded) full
 * size of a LogoList-item
 *
 * 1) Also centering the image inside it
 * 2) [webkits] Otherwise the parent's ghost pseudo wraps on the next line
 */

.LogoList-link {
  position: relative;
  z-index: 1;
  display: inline-block; /* 2 */
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease 0s;

  &::after { /* 1 */
    display: inline-block;
    width: 0;
    height: 100%;
    content: "";
    vertical-align: middle;
  }
}

/**
 * Considering a copy of a logo - to show on hover.
 */
.LogoList-link--showOnHover {
  position: absolute;
  z-index: 10;
  top: 0;
  right: $_logolist-item-padding-horz;
  left: $_logolist-item-padding-horz;
  width: auto;
  opacity: 0;
}

/**
 * The actual logo image
 *
 * 1) So that it doesn't shrink in a responsive images markup on high DPR
 *    screens (for cases when the image is not large enough, i.e. not DPR x
 *    designed dimensions).
 */
.LogoList-img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  object-fit: contain; /* 1 */
}

/**
 * If it's a vector image, we need to limit its width, otherwise a cell with
 * it could grow too much.
 */
.LogoList-img--svg {
  width: 200px;
}

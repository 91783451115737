/* @define Logo */

/**
 * The core Logo component
 *
 * 1) To prevent underline on whitespaces (for a vertical centering trick, for
 *    example). And to prevent undesired gaps between the image and some d:ib
 *    pseudos (like the one for the "center it vertically" hack)
 */
.Logo {
  display: block;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background: none;
  font-size: 0; /* 1 */
}

.Logo--centered {
  margin-right: auto;
  margin-left: auto;
}

/**
 * We need to restrain the component if we want to use it on an image to avoid
 * extra markup when it's not needed (img.Logo). But we also have to restrain
 * the image element because max-width/max-height in % on a child does not
 * respect `max-` props on a parent, only the exact width/height. We could use
 * a hack with setting width/height to a big value
 * https://jsfiddle.net/bq4Wu/291/
 * but that would make the parent to always stretch to at least that `max-`
 * prop value.
 *
 * Using restriction of both width and height to accomodate both wide images
 * and taller ones.
 * WARNING: if we end up creating too many modifiers becaues of this "both
 * witdh and height" restraining, might consider separate modifiers for width
 * and height (I'd choose not to make it that complex though).
 */
.Logo--S {
  &,
  .Logo-image {
    max-width: 155px;
    max-height: 35px;
  }
}

.Logo--SM {
  &,
  .Logo-image {
    max-width: 220px;
    max-height: 55px;
  }
}

.Logo--M {
  &,
  .Logo-image {
    max-width: 278px;
    max-height: 75px;
  }
}

.Logo--L {
  &,
  .Logo-image {
    max-width: 360px;
    max-height: 105px;
  }
}

/**
 * Use with SVG logos in cercumstances when they shrink to zero, for example
 * inside some flex layouts.
 *
 * 1) Otherwise the visible image is distorted vertically in IE.
 *    WARNING: be careful when using inside blocks unrestrained in height.
 */
.Logo--forceExpand {
  &,
  .Logo-image {
    width: 1000px;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: 1000px; /* 1 */
    }
  }
}

/* Elements
 * ======================================================================== */

/**
 * The image
 *
 * 1) The component has fz:0, so restoring it for the alt text to be visible
 * 2) Centering the image vertically
      (This is used with the vert-center-ghost mixin in Logo--header)
 */
.Logo-image {
  display: inline-block; /* 2 */
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle; /* 2 */
  font-size: 1rem; /* 1 */
}

/**
 * Image modifier with styles, specific to svg
 *
 * 1) [chrome, ie] If it's an svg, mah won't scale it, just limit the height.
 *    Setting `height` to a fixed value + mah surprisingly works
 * 2) [IE] without it maw messes the image, should be overriden with max-width
 */
.Logo-image--svg {
  width: 1000px; /* 2 */
  height: 100px; /* 1 */
}

// Logo for a Preview Form Block
.Logo-image--preview {
  max-height: 2.1875rem;
}

/* @define Link */

/* Helpers
 ========================================================================= */

$_link-icon-margin: 0.5em;

/**
 * The component.
 * The main idea was to have a component for red all-caps links with a right
 * arrow at the end. There are variations, like usial case letters, other
 * icons and even different color. But since the all-caps red arrow links
 * BY FAR prevail in the code, for simplicity and convenience the main
 * component is based on that variant. And everything else is via modifiers.
 *
 * Example:
 * <a href="" class="Link">More <span class="Link-lastWord">options</span></a>
 * will produce: "MORE OPTIONS >"
 *
 * 1) The link may be a toggler to showing/hiding something and have content
 *    that is only visible/hidden when that something is shown/hidden
 *    TODO: might want to make it a utility, if more components use this
 *    TODO: is using state classes appropriate for content?
 * 2) To avoid a link getting zero height and shifting all the way down in case
 *    there is no text / the text is invisible (for links with icons).
 */
.Link {
  @include hover {
    text-decoration: underline;
    color: $link-special-hover-color;
  }

  @include font-family($line-height: 1.143, $caps: true);

  display: inline-block;
  min-height: 1em; /* 2 */
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  color: $link-special-color;
  font-size: $font-size-sm;

  .is-shownOnActive { /* 1 */
    display: none;
  }

  &.is-active { /* 1 */
    .is-hiddenOnActive {
      display: none;
    }

    .is-shownOnActive {
      display: inline-block;
    }
  }
}

/**
 * If a Link needs an icon at the start which shouldn't be floated by the
 * text
 *
 * Example:
 * <a class="Link Link--vanilla Link--iconPrefix" href="..." >
 *  <i class="Link-icon Icon Icon--download"></i>Download Catalog
 * </a>
 */
.Link--iconPrefix {
  position: relative;
  padding-left: 1.5em;

  .Link-icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.14em;
  }
}

/**
 * The same, but if we want space for an icon at the end
 */
.Link--iconSuffix {
  position: relative;
  padding-right: 1.5em;

  .Link-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.14em;
  }
}

/**
 * This link doesn't have a ">" bullet and any other built-in icons
 *
 * Example:
 * <a href="" class="Link Link--noBullet">
 *  Email <span class="Link-lastWord">us
 *  <i class="Icon Icon--mail-alt"></i></span>
 * </a>
 * Will produce: "EMAIL US ✉"
 *
 * E.g. any link with a different icon: "All news", "Videos" (Breaking News)
 */
.Link--noBullet {
  .Link-lastWord::after {
    content: none;
  }
}

/**
 * This link is a usual blue link w/o uppercase and w/o bullets
 * Includes noBullet's styles instead of being a separate mod., because
 * there is not a single case for blue no-caps link with a bullet (and thus
 * we have less modifiers to use)
 * E.g.: Manufacturer's Page, Email/Print/Comment (article pages)
 *
 * Example:
 * <a href="" class="Link Link--vanilla">
 *  Email <span class="Link-lastWord">us
 *  <i class="Icon Icon--mail-alt"></i></span>
 * </a>
 * Will produce: "Email us ✉"
 *
 * 1) Shifting icons down a bit to center them vertically: due to tt:n the text
 *    inside .Link now is visually shifted down.
 * 2) To reset the tt:u set in the mixin call for the main component
 */
.Link--vanilla {
  @include font-family($line-height: $line-height-general); /* 2 */

  color: $link-color;
  font-size: inherit;

  @include hover {
    color: $link-hover-color;
  }

  .Link-lastWord::after {
    content: none;
  }

  .Link-icon {
    top: 0.1em; /* 1 */
  }
}

/**
 * This link's icon is gray (on hover it inherits the link's color)
 * E.g.: Email/Print/Comment (article pages)
 */
.Link--iconGray {
  .Link-icon {
    color: $gray-medium;
  }

  @include hover {
    .Link-icon {
      color: inherit;
    }
  }
}

/* Elements
  ========================================================================= */

/**
 * Wrap this around the last word of a Link if you need the arrow at the end.
 * Can also have arbitrary .Link-icon (used with Link--noIcon modifier)
 *
 * 1) Setting different value in em, but should give the same computed value
 *    since Icon and the builtp-in arrow has different font size
 * 2) Removing unnecessary leading
 */
.Link-lastWord {
  display: inline-block;
  max-width: 100%;
  text-decoration: inherit;

  &::after {
    position: relative;
    top: 0.07em;
    display: inline-block;
    margin-left: 0.42em; /* 1 */
    content: "\f105"; // .Icon--angle-right
    font-family: $icon-font;
    font-size: 1.35em;
    line-height: 0.8; /* 2 */
  }

  .Link-icon {
    margin-left: $_link-icon-margin; /* 1 */
  }
}

/**
 * A wrapper for an Icon, icon-like char (or maybe some image)
 *
 * 1) Independent icons will have smaller font-size than the built-in icon:
 *    the latter (>) has to be 19px; envelopes, cameras, etc. - 16px (with
 *    defatul 14px font size). Not bothering with setting fz for individual
 *    icons.
 */
.Link-icon {
  position: relative;
  top: 0;
  font-size: 1.14em; /* 1 */
  line-height: 1;
}

/**
 * @define Tabs
 */

/**
 * A generic Tabs; just a container
 */
.Tabs {
  display: block;
  margin: 0;
  padding: 0;
  border: 1px solid $border-color;

  @media print {
    break-inside: avoid;
  }
}

/**
 * A Tabs that should be built in another component, probably with a border
 * E.g. Breaking news (Homepage, News page)
 *
 * 1) Keeping top borders, but shifting 1px up so that in a normal view
 *    the border is invisible, but it needs to show up should the Tabs and
 *    the preceding content line up vertically. Not doing so for bottom
 *    for the sake of non-flex browsers (no vertical stretching => stray
 *    bottom border)
 */
.Tabs--inset {
  margin-top: -1px; /* 1 */
  border-width: 1px 0 0; /* 1 */
}

/**
 * Tabs with bigger Tab nav text
 * E.g. Breaking news (Homepage, News page)
 *
 * 1) On this screen size and below the container with the tabs can become
 *    smaller, so to prevent them from wrapping vertically - doin this.
 *    Basically, it's a MAGIC NUMBER for the particular case - Breaking news
 *    with the slider at the left
 */
.Tabs--navL {
  .Tabs-navItem {
    padding-right: 0.6rem;
    padding-left: 0.6rem;
    text-align: left;
    font-size: $font-size-base;

    @media (max-width: $screen-desktop-s) { /* 1 */
      font-size: $font-size-sm;
    }
  }
}

/**
 * TODO a temporary solution for GF search demo
 */
.Tabs--reset {
  display: block;
  border: none;

  .Tabs-nav {
    margin: 0 0 1rem;
    background: none;
  }

  .Tabs-navItem {
    display: block;
    margin: 0;
    padding: 0;
    text-align: inherit;
    text-transform: none;
    color: inherit;
    border: none;
    background: none;
    font: inherit;

    &.is-active {
      display: none;
    }
  }

  .Tabs-contentPane {
    padding: 0;
  }
}

/* Elements -- navigation
 * ====================================================================== */

/**
 * The tabs themselves
 *
 * 1) Fallback for browsers that don't have flex (or those we ignore in our
 *    autoprefixer config)
 * 2) Fallback for browsers w/o linear-gradient support
 * 3) Not fixing the width (but it still should be at least 100%) and negative
 *    margin right - to compensate for the item's border-right (which they
 *    always have)
 * x) TODO: make these variables
 */
.Tabs-nav {
  display: flex;
  overflow: hidden;
  min-width: 100%; /* 3 */
  margin: 0 -1px 0 0; /* 3 */
  padding: 0;
  list-style-type: none;
  background: #dbdbdb; /* 2, x */
  background: linear-gradient(to bottom, #fafafa 0%, #dbdbdb 100%); /* x */
  flex-wrap: wrap;

  .is-noFlexOr2009Only & { /* 1 */
    display: table;
    width: 100%;
    margin-right: 0;
    border-collapse: collapse;
  }
}

/**
 * A navigation item
 *
 * 1) Fallback for non-flex browsers
 * 2) Using .active instead of .is-active for Bootstrap
 */
.Tabs-navItem {
  @include font-family($caps: true, $line-height: 1.3);

  display: block;
  flex: 1 1 auto;
  margin: -1px 0 0;
  padding: 0.75rem 0.2rem;
  cursor: pointer;
  text-align: center;
  color: $gray-dark;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: $border-color;
  outline: none;
  background: none;
  font-size: $font-size-sm;
  font-weight: 700;

  &,
  &:hover {
    text-decoration: none;
    color: inherit;
  }

  .is-noFlexOr2009Only & { /* 1 */
    display: table-cell;
    border-top: none;
  }

  &.is-active { /* 2 */
    background: $white;
  }

  &::before {
    content: none;
  }
}

/* Elements -- content
 * ====================================================================== */

/**
 * A container for tab content panes
 */
.Tabs-content {
  display: block;
}

/**
 * The tab content pane
 * These elements are being toggled on Tab-navLink click
 * If there's no need in a dedicated container around the panes, they can be
 * used right inside the .Tabs (w/o .Tabs-content)
 */
.Tabs-contentPane {
  display: none;
  padding: 1rem 0.625rem;

  &.is-active {
    display: block;
  }
}

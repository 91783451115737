/**
 * @define utilities
 * Text: colors.
 * Use these if the text only needs to be colored and no component/element is
 * required
 *
 * Only here `!important` is allowed for general use
 */

/**
 * This class is for making elements fit into context
 * Use case: when putting <h2> *inside* some other element
 * WARNING!! Use with caution since it could mess with the font-family mixin
 */
.u-textInherit {
  letter-spacing: inherit !important;
  text-transform: inherit !important;
  color: inherit !important;
  font: inherit !important;
}

/**
 * This's a combination of resetting rules for text.
 * WARNING!! Use with caution since it could mess with the font-family mixin
 */
.u-textReset {
  letter-spacing: 0 !important;
  text-transform: none !important;
  font-size: $font-size-base !important;
  font-weight: 400 !important;
}

/* Coloring
 ====================================================== */

.u-textGray {
  color: $gray-medium !important;
}

.u-textGrayDark {
  color: $gray-dark !important;
}

.u-textWarning {
  color: $color-warn !important;
}

.u-textColorDefault {
  color: $text-color !important;
}

.u-textColorLink {
  color: $link-color !important;
}

.u-textColorSpecial {
  color: $link-special-color !important;
}

.u-textColorInherit {
  color: inherit !important;
}

/* Alignment
 ====================================================== */

.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight {
  text-align: right !important;
}

/* Size
 ====================================================== */

/**
 * WARNING!! Use with caution since these could mess with the font-family mixin
 */
.u-textSizeInherit {
  font-size: inherit !important;
}

.u-textMicro {
  font-size: $font-size-xs !important;
  line-height: 1.375 !important;
}

.u-textSmall {
  font-size: $font-size-s !important;
  line-height: 1.375 !important;
}

.u-textMedium {
  font-size: $font-size-sm !important;
  line-height: 1.375 !important;
}

.u-textBase {
  font-size: $font-size-base !important;
  line-height: 1.375 !important;
}

.u-textSizeM2 {
  font-size: $font-size-m2 !important;
  line-height: 1.375 !important;
}

.u-textSizeGiga {
  font-size: $font-size-giga !important;
}

/* Weight/style
 ====================================================== */

.u-textRegular {
  font-weight: 400 !important;
}

.u-textBold {
  font-weight: 700 !important;
}

.u-textItalic {
  font-style: italic !important;
}

.u-textNormal {
  font-style: normal !important;
}

/* Case
 ====================================================== */

/**
 * WARNING!! Use with caution since these could mess with the font-family mixin
 */
.u-textCaseNone {
  text-transform: none !important;
}

.u-textCaseUpper {
  text-transform: uppercase !important;
}

.u-textCaseLower {
  text-transform: lowercase !important;
}

.u-textCaseCapitalize {
  text-transform: capitalize !important;
}

/* Link imitation and resetting
 ====================================================== */

// Imitates a regular link
// TODO: make this `d:ib` to override underline behavior and color?
.u-textLink {
  text-decoration: none !important;
  color: $link-color !important;

  &:hover {
    text-decoration: underline !important;
    color: $link-hover-color !important;
  }
}

// Imitates an alternative/special link (like .Link): i.e., has its color,
// gets underlined on hover, but with normal text case.
// To just give a text this color use .u-textColorSpecial
.u-textLinkSpecial {
  text-decoration: none !important;
  color: $link-special-color !important;

  @include hover {
    text-decoration: underline !important;
    color: $link-special-hover-color !important;
  }
}

// Makes a link not behave as a link
.u-textLinkReset {
  &,
  &:hover {
    cursor: inherit !important;
    text-decoration: none !important;
    color: inherit !important;
  }
}

/**
 * Restores otherwise reset link effects on its child
 */

.u-textInsideLink {
  text-decoration: inherit !important;

  a:hover & {
    text-decoration: inherit !important;
  }
}

/* Clipping
 ====================================================== */

.u-textClip {
  display: block !important;
  overflow: hidden !important;
  max-width: $screen-mobile-xs !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

/* Misc
 ====================================================== */

.u-textNoWrap {
  white-space: nowrap !important;
}

/**
 * Brightcove player style overrides.
 * Using !important because even if we repeat Brightcove's selector structure
 * class-by-class, Brightcove's styles are still imported later and will
 * override our styles. And we can't use an ID selector as a wrapper because
 * there can be more than one player on a page.
 * (yet if someone comes up with an idea of how to avoid that - we're all ears
 * :) ).
 */
$_brightcove-menu-selected-color: $white !default;

/**
 * The Play button
 */
.vjs-big-play-button {
  @include video-button;

  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  border-radius: 0 !important;
  background: none !important;

  &,
  .vjs-icon-placeholder {
    &::before {
      content: none !important;
    }
  }
}

/**
 * The player volume bar
 */
.vjs-volume-bar {
  &::before {
    box-shadow: none !important;
  }
}

/**
 * In the old styles there was pos:r here. Don't use it, as it flips the
 * control upside-down
 */
.vjs-volume-level,
.vjs-play-progress,
.vjs-quality-menu-button-HD-flag::after,
.vjs-quality-menu-wrapper .vjs-selected {
  background: $link-color !important;
}

/**
 * Quality Control styles
 */
.vjs-quality-menu-button-HD-flag::after {
  color: $_brightcove-menu-selected-color !important;
}

.vjs-quality-menu-wrapper {
  .vjs-selected {
    color: $_brightcove-menu-selected-color !important;
  }

  .vjs-menu-item {
    border-radius: 0 !important;

    &:focus,
    &:hover {
      outline: none !important;
      text-shadow: none !important;
    }

    &:hover {
      text-decoration: underline !important;
    }
  }
}

/**
 * Context menu item
 *
 * 1) Overriding our <li> styles
 */
.vjs-menu-item {
  margin: 0 !important; /* 1 */

  &::before {
    content: none; /* 1 */
  }
}

/**
 * Headings
 *
 * 1) override spicificity for BC player
 */
.vjs-dock-text {
  display: none !important;
}

/** @define CubWidgetForm */

/**
 * Form styles for cub widget
 */

.cub-Form--imageUpload {
  margin-bottom: 0;
}

.cub-Form--memberPosition {
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }
}

.cub-Form--profile {
  @include decoration-box;

  max-width: $cub-width;
}

/**
  * Member Benefits Form.
  * 1) hide the caption. It's duplicated in the site side.
  */
.cub-Form--manageMemberBenefits {
  .cub-Caption--form {
    display: none;
  }
}

.cub-Form-error {
  margin: 0;
  // TODO: make this red universally?
  color: $link-hover-color;
  font-size: 0.75rem;
  line-height: 1.2;
}

.cub-Form-error--top {
  max-width: $cub-width;
  margin-bottom: 1rem;
  color: $cub-error-color-top;
  border-radius: 0;
  font-size: inherit;
  line-height: 1.3;
}

.cub-Form--login,
.cub-Form--registration {
  .cub-Caption {
    display: none;
  }
}

.cub-Form--continue {
  max-width: $cub-width;
  border-top: 1px solid $border-color;
}

/**
 *
 * E.g.: Most lead forms, with "i" icon in Submit buttons
 * 1) Reset Top Error max-width for Cub Lead Forms
 */
.cub-Form--custom {
  .cub-Form-error--top {
    max-width: inherit; /* 1 */
  }

  // Have to increase specificity to apply style only for submit btn and
  // dont' change the styles for other btns inside Generic Form
  // TODO: refactor this
  .cub-Button--submit {
    .cub-Button-icon {
      &::before {
        content: "\f105"; // .Icon--angle-right
        font-family: $icon-font;
      }
    }
  }
}

/** @define Button */

/* Mixins, variables
 ======================================================================== */

$_btn-height: 1.875rem;
$_btn-height-l: 2.5rem;
$_button-padding-horz: 0.75rem;

/* The component
  ======================================================================= */
/**
 * The default button
 * ... will be the "Small button" from the styleguide with red (on P1) bg.
 * The main trick is a) allow it to just insert an icon, and keep padding left
 * for the text w/o using a modifier, b) that icon being 100% high.
 * [Firefox v <51] <button> can't have flex and d:tb. So the trick with
 * left-aligned icon with 100% height won't work with those techniques, and
 * we'll have to give up on having multiline buttons (and a.Button for
 * consistency) and just clip all text that doesn't fit one line.
 * Assuming the icon is ALWAYS TO THE LEFT!
 *
 * 1) Otherwise it's too bold on Mac
 * 2) Cropping the text that sticks out.
 * 3) To avoid unnecessary small extra gap (below the baseline). And the
 *    button should also play nicely with some text, if it's middle-aligned
 * 4) Set animation on Icon item since Button-icon is an Icon container
 * 5) !!! TODO In the end, @extend is bad as it changes the order of rulesets
 *    in the resulting .css. Rewrite them into mixins after migrating to Dart
 *    Sass
 * 6) Placeholder selector for blocks with background inside which a Button
 *    should have some border
 */
%button { /* 5 */
  $btn-on-bg-border: null !default;

  @include mac-font-smoothing; /* 1 */
  @include font-family($caps: true);

  display: inline-block;
  overflow: hidden; /* 2 */
  max-width: 100%;
  height: $_btn-height;
  padding: 0 $_button-padding-horz;
  cursor: pointer;
  transition: none 0.3s ease 0s;
  transition-property: color, background-color, outline-color;
  text-align: left;
  vertical-align: middle; /* 3 */
  text-decoration: none;
  color: $white;
  border: none;
  border-radius: 0;
  background: $btn-bg;
  font-size: $font-size-sm;
  font-weight: 700;
  line-height: $_btn-height;

  @media print {
    display: none;
  }

  &:hover {
    text-decoration: none;
    color: $white;
    background: $btn-bg-hover;
  }

  &:focus {
    outline: 1px dotted $black;
  }

  // "Something is working" state: loading more entries, a form being sent
  &.is-processing {
    .Icon::before { /* 4* */
      display: inline-block;
      content: "\e760"; // .Icon--arrows-cw
      animation: spinning 1s linear 0s infinite;
    }
  }

  @if ($btn-on-bg-border) {
    %background-for-buttons & { /* 6 */
      outline: 1px solid $btn-on-bg-border;

      &:hover {
        outline-color: $btn-on-bg-border-hover;
      }
    }
  }
}

%button-hover-icon {
  background: $btn-icon-bg-hover;
}

.Button {
  @extend %button;

  &:hover {
    .Button-icon {
      @extend %button-hover-icon !optional;
    }
  }

  // The attr is for <button> and <input>, the class is for <a>, <span>, and
  // everything else. But you could combine them.
  &[disabled],
  &.is-disabled {
    cursor: default;
    background: $gray-medium;

    .Button-icon {
      background: $gray-dark;
    }
  }
}

/**
 * Large Button (as per the Style Guide)
 */
%button-l {
  height: $_btn-height-l;
  line-height: $_btn-height-l;
}

%button-l-icon {
  width: $_btn-height-l;
}

.Button--l {
  @extend %button-l;

  .Button-icon {
    @extend %button-l-icon;
  }
}

/**
 * A wider version of a button meant to be a dominant
 */
.Button--wide {
  width: 100%;
  max-width: 300px;
  text-align: center;
}

/**
 * An "empty" button with no background
 */
.Button--bare {
  color: $btn-bg;
  background: $white;

  &,
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $btn-bg;
  }

  &:hover {
    color: $btn-bg;
    background: $white;

    .Button-icon {
      background: none;
    }
  }

  .Button-icon {
    border-right: 1px solid $btn-bg;
    background: none;
  }
}

/* Elements
  ======================================================================= */

/**
 * The icon. Putting it here for the mixin to be as mobile as possible
 *
 * 1) Nullifying the btn's left/right padding - shifting left by the exact
 *    pl of the btn. Pos:r because that shift will effectively create a gap
 *    between the icon and the text.
 * 2) And the inherited lh is equal to the Button's height
 * 3) Otherwise animation won't work in some browsers [firefox]
 * 4) We have .Icon-comment slightly shiftet to the top by default, but we
 *    don't want it here.
 */

%button-icon {
  position: relative; /* 1 */
  top: 0; /* 4 */
  left: - $_button-padding-horz; /* 1 */
  float: left;
  width: $_btn-height;
  height: 100%;
  margin: 0;
  transition: color 0.3s ease 0s, background-color 0.3s ease 0s;
  text-align: center;
  color: inherit;
  background: $btn-icon-bg;
  font-size: 1.285em;
  line-height: inherit; /* 2 */

  &::before {
    display: inline-block; /* 3 */
    margin: 0;
  }
}

.Button-icon {
  @extend %button-icon;
}


/* Embeds
 ========================================================================= */

/**
 * Shit-fix for a Facebook widget to humbly ask it to be so kind and - I don't
 * know - respect the container's width?
 */
.fb-post {
  &,
  > span,
  iframe {
    width: 100% !important;
  }
}

/**
 * The element right inside .fb-page shown while the widget's code still being
 * loaded.
 *
 * 1) Resetting our defaults for <blockqoute>
 */
.fb-xfbml-parse-ignore {
  &,
  blockquote {
    min-height: 0; /* 1 */
    margin-left: 0; /* 1 */
    padding: 0; /* 1 */

    &::before { /* 1 */
      content: none;
    }
  }
}

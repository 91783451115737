/** @define CubWidgetButton */

/**
 * Button styles for cub widget
 * This css contains !important to override more specific site selectors.
 * After optimizing site css !important can be removed from this code.
 * 1) Use !important to use spinner icon in srocessing state always,
 *  even when rule has higher specificity.
 * 2) Override Cub styles. TODO: refactor this.
*/

.cub-Button {
  @extend %button !optional;

  &:hover {
    .cub-Button-icon {
      @extend %button-hover-icon !optional;
    }
  }

  &.is-processing {
    .cub-Button-icon {
      &::before {
        content: "\e760" !important; // .Icon--arrows-cw
        font-family: $icon-font;
      }
    }
  }
}

.cub-Button--submit {
  .cub-Form--registration & {
    @extend %button-l !optional;
  }

  .cub-Button-icon {
    .cub-Form--registration & {
      @extend %button-l-icon !optional;
    }
  }
}

// WARNING: DON'T DO THIS kind of "base class + modifier" combinations
// elsewhere! Having this here only because we have our whole Button system
// based on stupid @extends, and they move rulesets around.
// TODO: remove after rewriting in dart sass and deploying @use
.cub-Button.cub-Button--facebook {
  background: $color-facebook;

  &:hover {
    background: darken($color-facebook, 15%);

    .cub-Button-icon {
      background: darken($color-facebook, 20%);
    }
  }

  .cub-Button-icon {
    background: darken($color-facebook, 15%);
  }
}

.cub-Button-icon {
  @extend %button-icon !optional;

  &::before {
    width: auto; /* 2 */
  }
}

.cub-Button-text {
  line-height: 30px;
}

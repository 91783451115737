/** @define CubWidgetReactWidgets */

/**
 * ReactWidgets styles for cub widget
 * 1) Overriding our styles for <li>
 * 2) Override base styles
 */

// Container
.rw-widget-container {
  margin: 0;
  padding: 0;
  border-color: $border-color !important;
  border-radius: 0;
  background: $white;
}

// Widget
.rw-widget {
  &.rw-state-focus,
  &.rw-state-focus:hover {
    .rw-widget-container {
      @include focused;
    }
  }

  &.rw-state-focus {
    .rw-popup-container {
      z-index: 9992;
    }
  }
}

.rw-widget-picker {
  height: 1.875rem;
}

// Inputs
.rw-input,
.rw-input-reset {
  height: 1.75rem;
  padding: 0.3125rem 0.375rem;
  border-radius: 0;
  font-size: inherit;
}

.rw-input,
.rw-input-reset {
  &:focus {
    border-color: transparent !important; /* 2 */
    box-shadow: none !important; /* 2 */
  }
}

// List
.rw-list {
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}

// List option
.rw-list-option,
.rw-list-empty {
  margin: 0; /* 1 */
  padding: 0.3125rem 0.375rem;
  border-color: transparent;
  border-radius: 0;
  line-height: 1.25rem;
}

.rw-list-option {
  /* TODO: rm ! if wysiwyg content styles get refactored (CROSS-1606) */
  margin-bottom: 0 !important;

  &:focus,
  &.rw-state-focus,
  &.rw-state-selected,
  &.rw-state-focus:hover {
    background-color: $gray-light;
  }
}

.rw-list-option,
.rw-list-empty,
.rw-multiselect-tag {
  &::before { /* 1 */
    content: none;
  }
}

// Multiselect
.rw-multiselect-tag {
  height: 1.5rem;
}

/** @define CubWidgetText */

/**
 * Text styles for cub widget
 * 1) TODO: Remove CubWidgetText class after refactoring
 * (after `.article-template p` removing)
 */

/* 1 */
.cub-Text {
  margin: 0 0 1rem;

  &:last-child {
    // TODO: remove !important after refactoring
    margin-bottom: 0 !important;
  }
} /* 1 */

.cub-Text--forgotPassword {
  text-transform: uppercase;
}

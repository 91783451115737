/**
 * @define SponsoredBy
 *
 * A component for "Sponsored By" block in the right sidebar.
 * Using a separate component instead of a modifier for .Card because 1) there
 * are variants of the block with and without a border, 2) we might want
 * to use the intro (a gray box "sponsored by") separately, which is
 * impossible with .Card-intro - a modifier parent wrapper would be required,
 * 3) it would much more convenient for the backend devs to switch between
 * the usual block and the borderless (TitleBar + "sponsored by" + grid of
 * elements) by just swithcing a modifier class
 */

/*
  Markup example:
  <div class="SponsoredBy">
    <div class="SponsoredBy-heading">
    	<a href="#" class="TitleBar">
    	  <h2 class="TitleBar-heading">VIEVU</h2>
    	</a>
    </div>
    <p class="SponsoredBy-intro">Sponsored By</p>
    <a href="#" class="SponsoredBy-imgTitleWrap">
      <div class="SponsoredBy-imgBlock">
        <img class="SponsoredBy-img" src="..." alt="...">
      </div>
      <p class="SponsoredBy-title">VIEVU</p>
    </a>
    <div class="SponsoredBy-block">
      <ul class="SocialLinks SocialLinks--tiles">...</ul>
    </div>
    <p class="SponsoredBy-footer">
      <a class="Button "href="#">
        <span class="Button-icon">
        	<i class="Icon Icon--info"></i>
        </span>
        Some test
      </a>
    </p>
  </div>
*/

/* Helpers
 ======================================================================= */

$_sponsored-by-content-padding-vert: 1rem;
$_sponsored-by-content-padding-horz: 1rem;
$_sponsored-by-img-max-width: 12.875rem;

/* The component
 ======================================================================= */

/**
 * The default "Sponsored by" block in the right sidebar.
 * Considers TitleBar as a heading
 */
.SponsoredBy {
  text-align: center;
  border: 1px solid $border-color;
  line-height: $line-height-general;
}

/**
 * A variant without a border. Usually - just a heading, "sponsored by" and
 * a grid of companies' .Cards. So we don't need such things as -content's
 * vert padding, and do need -intro's mb.
 * Also its text is left-aligned mostly
 */
.SponsoredBy--noBorder {
  text-align: left;
  border: none;

  .SponsoredBy-heading {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .SponsoredBy-intro {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .SponsoredBy-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/**
 * A variant with gray background.
 * To be honest, it's pretty close case to .Box .Box--bg.
 * TODO: think about union.
 */
.SponsoredBy--bg {
  background: $gray-light;
}

/* Elements
 ======================================================================= */

/**
 * A heading. Most likely with a .TitleBar inside
 * 1) To hide a parent's border around it
 */
.SponsoredBy-heading {
  margin: -1px -1px 0; /* 1 */
}

/**
 * "Sponsored by" text on gray bg
 *
 * 1) It usually inherits a parent's ta:c, but setting it explicitly so that
 *    the element could be used independetnly.
 * 2) So that it could be inside a blocklink for an img and title as well
 */
.SponsoredBy-intro {
  display: block;
  margin: 0;
  padding: 0.625rem;
  text-align: center; /* 1 */
  background: $gray-light;
  font-size: $font-size-base;
  line-height: $line-height-general;

  .SponsoredBy-content & { /* 2 */
    margin: (-$_sponsored-by-content-padding-vert) (-$_sponsored-by-content-padding-horz) 1rem;
  }
}

/**
 * A variant without background and bold font
 * E.g.: Sponsored article
 */
.SponsoredBy-intro--clean {
  background: none;
  font-weight: 700;
}

/**
 * E.g. "Powered by" block
 */
.SponsoredBy-intro--border {
  border: 1px solid $border-color;
}

/**
 * Wrapper for everything after the intro. Mainly - to have padding
 */
.SponsoredBy-content {
  display: block;
  padding: $_sponsored-by-content-padding-vert $_sponsored-by-content-padding-horz;
}

.SponsoredBy-imgTitleWrap,
.SponsoredBy-imgBlock,
.SponsoredBy-text,
.SponsoredBy-block {
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * An image and a title could be placed inside this container. Mostly used
 * as a common link (<a>) wrapper
 */
.SponsoredBy-imgTitleWrap {
  display: block;
  margin-bottom: 0.675rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A wrapper for an image
 *
 * 1) The width is limited irrespective of the responsive img markup status,
 *    because due to the nature of .Columns, in some cases large images
 *    could make the sidebar stretch too much (e.g., a big SVG like on Grant
 *    Finder pages, won't respect the default `max-width: 100%`)
 *    Having it here too for the sake of FR1's Jobs block - for the small text
 *    to align nicely with the image (couldn't think of a more neat solution
 *    for cases with an SVG iamge :( ).
 * 2) Avoiding extra gaps/line-height (inside is only an image, so it's ok)
 * 3) For .Picture to inherit
 */
.SponsoredBy-imgBlock {
  max-width: $_sponsored-by-img-max-width; /* 1 */
  max-height: 150px; /* 3 */
  margin-right: auto;
  margin-left: auto;
  font-size: 0; /* 2 */
}

/**
 * A class for an actual image.
 *
 * 1) Having this limit in case the image is used without the imgBlock
 * 2) For tall images to fit into responsive images markup.
 */
.SponsoredBy-img {
  max-width: $_sponsored-by-img-max-width; /* 1 */
  max-height: inherit;
  object-fit: contain; /* 2 */
}

/**
 * 1) SVG shouldn't need the `object-fit: contain` by default, plus keeping it
 *    was causing images to shring in webkits, although just by a little bit.
 */
.SponsoredBy-img--svg {
  object-fit: fill; /* 1 */
}

/**
 * A title, usually a company name
 *
 * 1) Assuming it's always a link/inside a link
 */
.SponsoredBy-title {
  display: block;
  margin-bottom: 0.675rem;
  color: $link-special-color; /* 1 */
  font-size: $font-size-sm;

  @include hover {
    color: $link-special-hover-color; /* 1 */
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Just a usual container. No additional styles needed for now
 */
// .SponsoredBy-block { }

/**
 * A container element for text. Different from SponsoredBy-block in smaller
 * font size and left-aligned text.
 */
.SponsoredBy-text {
  text-align: left;
  font-size: $font-size-s;
  line-height: 1.2;
}

/**
 * A contatier for bottom btn/"Get more info" link, etc.
 *
 * 1) (negative spacings) to accound spacings around for child elements
 * 2) Not max-width - to make room for it, and not have the width flexible;
 *    visually that is - object-fit on the base element will take care here.
 */
.SponsoredBy-grid {
  $_footer-left-padding: 1rem;
  $_footer-bottom-padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 (-$_footer-bottom-padding) (-$_footer-left-padding); /* 1 */

  .SponsoredBy-imgBlock,
  .SponsoredBy-block {
    margin: 0;
    padding: 0 0 $_footer-bottom-padding $_footer-left-padding; /* 1 */
  }

  .SponsoredBy-imgBlock {
    width: 6.25rem + $_footer-left-padding; /* 2 */
  }
}

/*
 * 1) EXCEPTION (the top marigin) This element should _always_ have 16px gap
 *    before it (and a preceding el's mb could be smaller)
 */
.SponsoredBy-footer {
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
}

/* @define Form */

/* Helpers
========================================================================= */

$form-table-cell-gap: 0.625rem;
$_form-input-height: 1.875rem;
$_form-control-height-l: 2.5rem;
$_form-spacing: 1.25rem;

/**
 * 1) It can be inside blocks with, e.g., white color. And we don't want to
 *    inherit that
 */
@mixin form-decoration {
  padding: $_form-spacing;
  color: $text-color; /* 1 */
  border: 1px solid $border-color;
  background: $gray-light;
}

/* The component
========================================================================= */

/**
 * A component for a generic form
 */
.Form {
  @include form-decoration;

  position: relative;
  margin: 0;
  font-size: $font-size-sm;

  @media print {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  // success state
  &.is-success {
    .Form-title,
    .Form-body {
      display: none;
    }

    .Form-successContent,
    .Form-title--successMessage {
      display: block;
    }
  }

  // error state
  &.is-error {
    .Form-errorMessage {
      display: block;
    }

    .Form-hint--error {
      display: block;
    }
  }

  // "Loading"/"request in progress" state
  &.is-processing {
    // TODO: this dependency is not the best idea. Using a state on a form
    // AND on a button components would be better, but at the moment we'd
    // have to copy-paste some logics between multiple classes. To be
    // revisited while refactoring JS
    .Button-icon {
      .Icon::before {
        display: inline-block;
        content: "\e760"; // .Icon--arrows-cw
        animation: spinning 1s linear 0s infinite;
      }
    }
  }

  /**
   * Show modal state. (Example: Submit Diretcoty Listing Free Form).
   * 1) Set pos: relative to get modal inside form with absolute position
   */
  &.is-showModal {
    position: relative;
    border-color: transparent;

    .Form-modal {
      display: block;
    }
  }
}

/**
 * Forms that are in popups
 *
 * 1) Overriding the default
 */
.Form--popup {
  padding-bottom: 0;
  border: none;
  background: none;

  .Form-title {
    @include mac-font-smoothing;
    @include font-family($font-family-alt);

    margin: 0;
    text-align: left;
    text-transform: none;
    color: $gray-dark;
    font-size: $font-size-ml;
  }

  .Form-body {
    @media screen and (max-width: $screen-desktop-s) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .Form-footer {
    @media screen and (max-width: $screen-desktop-s) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .Form-footer {
    &:last-child { /* 1 */
      margin-bottom: 0;
    }
  }
}

/* Visual/geometry modifiers
  ========================================================================= */

/**
 * Form container without default styling
 *
 * 1) The overlay's z-index is 999
 * 2) Not using the transform because the `spinning` animation would reset
 *    it since it's using a rotate function
 * 3) Overriding the default from .Form
 */
.Form--reset {
  padding: 0;
  border: none !important;
  background: none;

  &.is-processing {
    &::before {
      top: 0; /* 3 */
      right: 0; /* 3 */
      bottom: 0; /* 3 */
      left: 0; /* 3 */
    }
  }
}

/**
 * We want to keep the Form--reset clean of any borders/bg, but in some
 * cases we want the success block to be boxed. This mod is for that.
 */
.Form--successBoxed {
  .Form-successContent {
    @include form-decoration;
  }
}

/**
 * A form with bg color more-or-less corresponding to the site's theme
 * (blue-ish for P1, etc.)
 * E.g.: Search for Grants, PGH
 *
 * 1) Otherwise it's too bold on Mac
 */
.Form--bgThemed {
  @extend %background-for-buttons !optional;

  color: $form-themed-color;
  border: none;
  background: $form-themed-bg;

  .Form-label {
    @include mac-font-smoothing; /* 1 */

    font-size: $font-size-base;
    font-weight: 700;
  }

  .Form-control {
    border-color: $form-themed-input-borders;
  }

  /**
   * This class is for text whose color should or should not be redefined
   * here. Example: Search grants form; "more options" link color should be
   * redefined to white on all GH sites, but stay camarin on EDUGH. Since
   * there is no color value like "don't apply any color" in CSS, we have to
   * have a condition here to be able to not add that rule at all.
   */

  @if ($form-themed-text-dedicated-color != false) {
    .Form-textPainted {
      &,
      &:hover {
        color: $form-themed-text-dedicated-color;
      }
    }
  }

  /**
   * The same as Form-textPainted, but simpler: it's either some fancy color
   * (Jungle Green on EDUGH) or just inherited (white on all other GH).
   */
  .Form-textPaintedSpecial {
    &,
    &:hover {
      color: $form-themed-text-special-color;
    }
  }
}

/**
 * A regular Subscription form
 *
 * 1) The correct way to do this would be have a modifier on the cub-Form, or
 *    even better on the buttons. But unfortunately there are cases when we
 *    can't control the markup of 3rd-party widgets at all and have to resort
 *    to hacks like this.
 */
.Form--subscription {
  .cub-Button { /* 1 */
    @extend %button-l !optional;
  }

  .cub-Button-icon { /* 1 */
    @extend %button-l-icon !optional;
  }
}

/**
 * A mix of subspription form & a form with background
 * (CROSS-291)
 * E.g.: https://www.policeone.com/police-jobs/
 */
.Form--subscriptionBgThemedHorz,
.Form--subscriptionBgThemed {
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  text-align: center;
  background-color: $form-subscription-bg;
  background-repeat: no-repeat;
  background-position: center top;

  .Form-bgImage {
    width: 100%;
  }

  .Form-successContent {
    margin-bottom: 0;
  }

  // TODO: refactor this, mv to .cub-Form--subscription styles.
  // To do this an update to Cub styles is needed. The parameter could be
  // passed to options via cub-form-init.js
  .cub-Label {
    margin-bottom: 0.3125rem;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.25rem;
  }

  .cub-Label-asterisk {
    display: none;
  }

  .cub-Form-error {
    position: absolute;
    right: 0;
    bottom: auto;
    text-align: right;
    color: $white;
  }

  .cub-Form-error--top {
    position: relative;
    margin-bottom: 0.5rem;
    padding: 0;
    border: none;
    background: none;
  }

  .cub-Button {
    .cub-Button-icon::before {
      content: "\f0e0";
    }
  }
}

/**
 * A form with background that uses a horizontal layout (ex.: Subscription
 * form on the NL Archive Page, CROSS-336)
 */
.Form--subscriptionBgThemedHorz {
  max-width: none;

  .Form-content {
    top: 50%;
    bottom: auto;
    left: 1.25rem;
    width: 49%;
    padding: 0;
    transform: translateY(-50%);

    @media (max-width: $screen-tablet-s) {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding: 1.25rem;
      transform: translateY(0);
    }
  }

  .Form-bgImage {
    @media (max-width: $screen-tablet-s) {
      display: none;
    }
  }

  .cub-Button-icon {
    display: none !important;
  }

  .cub-Button {
    width: 100%;

    @media (max-width: $screen-mobile-xl) {
      width: auto;
    }
  }
}

/**
 * The form for "Embed this video's code"
 *
 * 1) For the content not to stick out when maw is animated out to 0.
 * 2) Some browsers don't transition border width and/or don't transition
 *    subpixel values (bdw is transitioned from 1 to 0), so using steps()
 *    timing function to correctly play with border width.
 * 3) The "code" contents are created in the backend code so they most
 *    likely have spaces at the start.
 * 4) Overriding .Form-footer:last-child
 * 5) The form is not part of any component, so even if it's hidden, its
 *    parent still has margin. To fix that we a) allow for margin transition
 *    here, b) add .Article-item to the form element.
 *    TODO: probably not the most solid solution
 */
.Form--embed {
  @mixin _form-transition($steps-value) {
    transition:
      margin 0.5s ease 0s,
      max-height 0.5s ease 0s,
      border-width 0.5s steps(1, $steps-value) 0s; /* 2, 5 */
  }

  @include _form-transition(start);

  overflow: hidden; /* 1 */
  max-height: 250px;
  margin-bottom: $article-item-mbottom;
  padding: 0;
  border: 1px solid $border-color;

  &.is-collapsed {
    @include _form-transition(end);

    max-height: 0;
    margin: 0; /* 5 */
    border-width: 0 1px;
  }

  .Form-control--textarea {
    display: block;
    width: 100%;
    height: 91px;
    padding: 0.33em 0.5em;
    white-space: pre-line; /* 3 */
    color: $gray-dark;
    border: none;
    border-radius: 0;
    background: $white;
    box-shadow: none;
    font-size: $font-size-s;
    line-height: 1.2;
  }

  .Form-footer {
    overflow: hidden;
    margin: 0 !important; /* 4 */
    padding: 0;
    text-transform: uppercase;
    border-top: 1px solid $border-color;
    font-size: $font-size-s;
    font-weight: 700;
    line-height: 1.2;
  }

  .Form-hint {
    display: block;
    float: left;
    padding: 0.75rem 0.625rem;
    font-size: $font-size-s;

    @media (max-width: $screen-mobile-xxxl) {
      float: none;
    }
  }

  .Form-btn {
    float: right;
    text-align: center;

    @media (max-width: $screen-mobile-xxxl) {
      float: none;
      width: 100%;
    }
  }
}

/* Elements - misc
  ========================================================================= */

/**
 * A background image for a form, the main example is
 * .Form--subscriptionBgThemed.
 *
 * 1) No need for visible alt, as an image is mostly for decoration
 */
.Form-bgImage {
  display: block;
  font-size: 0; /* 1 */
}

.Form-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $_form-spacing;
}

/* Header elements
  ========================================================================= */

/**
 * Form's header
 *
 * 1) Form's padding must not be considered
 *    TODO: remove padding from Form, instead give it to its elements?
 * 2) We have to use flex, because: a) css tables have issues with setting
 *    the logo's max-width (we need it <=110px), b) d:ib would require
 *    whs:nw on this one, and whs:n on Form-title, which would make it 100%
 *    as wide as .Form-header, i.e. exceeding its boundaries.
 *    Flex has a limitation too (see .Form-logo)
 */
.Form-header {
  display: block;
  display: flex; /* 2 */
  overflow: hidden;
  margin: -#{$_form-spacing} -#{$_form-spacing} $_form-spacing; /* 1 */
  padding: 1rem;
  border-bottom: 1px solid $border-color;
  background: $white;
  align-items: center;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

/**
 * A modified variant of Form header column
 * with layout transformed into a column for mobile screens.
 * Example of usage: the Saarch Form on /police-training/events/
 */
.Form-header--collapsed {
  @media (max-width: $screen-mobile-xl) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .Form-logo,
  .Form-title {
    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: $screen-mobile-xl) {
      margin: 0 0 0.5rem;
    }
  }
}

/**
 * A header with a smaller vert margins.
 * E.g. Deals search form (CROSS-105)
 */
.Form-header--condensed {
  padding: 0.625rem 1rem;
}

/**
 * A container for the logo
 *
 * 1) `0 0 auto` would work great, if not for Blink/Webkit that would srhink
 *    the element. So the only option we have is to give it a limited width
 *    in such a way.
 * 2) Flex fallbacks
 * 3) Otherwise it won't be truly inflexible
 *    https://bugzilla.mozilla.org/show_bug.cgi?id=1118274
 * 4) To avoid any spacing caused by the vertical centering pseudo, which is
 *    inline-block.
 */
.Form-logo {
  $_logo-size: 100px;

  @include vert-center-ghost;

  float: left; /* 2 */
  flex: 0 0 $_logo-size; /* 1 */
  width: auto;
  min-width: 0; /* 3 */
  max-width: $_logo-size; /* 2 */
  height: 100px;
  max-height: 100px;
  margin: 0 1.125rem 0 0;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0; /* 4 */
  font-size: 0; /* 4 */
  transform-style: preserve-3d;
}

.Form-logo--ml {
  $_logo-size: 250px;

  flex: 1 1 100%;
  max-width: $_logo-size;
  height: 60px;
  max-height: 60px;
}

.Form-logoImage {
  max-height: 100%;
  margin: 0;
  vertical-align: middle;
}

/**
 * If it's an SVG image, stretch it to the maximum...
 *
 * 1) Taking into account that .Form-logo MUST always have its height fixed
 */
.Form-logoImage--svg {
  width: 1000px;
  height: 100%; /* 1 */
}

/**
 * Usually this is for the text inside Form-header
 */
.Form-title {
  @include font-family($caps: true);

  flex: 1 1 auto;
  margin: 0;
  font-size: $font-size-base;
  font-weight: 700;
  line-height: $line-height-general;
}

.Form-title--successMessage {
  margin-bottom: 1rem;
  font-size: $font-size-base;
  line-height: $line-height-general;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Messages
  ========================================================================= */

/**
 * Form success content
 * It's the message, that shows after a successful form submit
 */
.Form-successContent {
  display: none;
  margin-bottom: $_form-spacing;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A variant of Form success content
 * with limited width and aligning to center.
 * 1) Not using $screen-tablet-xs var in order to have
 *    more solid position relatively to other elements.
 */
.Form-successContent--narrow {
  max-width: 580px; /* 1 */
  margin-right: auto;
  margin-left: auto;
}

/**
 * Form error message
 * It's the message, that shows when getting an error
 */
.Form-errorMessage {
  display: none;
  color: $article-form-error-color;
  line-height: 1.2rem;
}

/**
 * Form modal block
 * It's the block, that overlay the Form content.
 */
.Form-modal {
  display: none;
}

/* Other elements
  ========================================================================= */

/**
 * Form body
 * It's main holder for a form controls. E.g. can be applied to fieldset
 *
 * 1) It can be a fieldset, which could have border (like in Bootstrap)
 */
.Form-body {
  margin: 0 0 1rem;
  padding: 0;
  border: none; /* 1 */

  &:last-child {
    margin-bottom: 0;
  }
}

.Form-footer {
  margin: 0 1rem;
  padding: 0.375rem 0;
  text-align: center;
  border-top: 1px solid $border-color;

  &:last-child {
    margin-bottom: -#{$_form-spacing};
  }
}

.Form-hint {
  margin-bottom: 0;
  font-size: $font-size-xs2;
  line-height: 1.2;
}

// TODO: CubWidgetForm-error is basically the same. Unite?
.Form-hint--error {
  display: block;
  margin: 0;
  color: $link-hover-color;
  font-size: 0.75rem;
  line-height: 1.2;
}

/**
 * Horz line
 * The generic one is for use inside element groups
 */
.Form-hr {
  margin: 0 0 0.8125rem;
  border: none;
  border-top: 1px solid $border-color;
}

/**
 * Container for form component.
 * Includes label and control fields.
 *
 * 1) So that error messages and some icons could be absolutely positioned
 */
.Form-group {
  position: relative; /* 1 */
  margin-bottom: 0.8125rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.Form-group--s {
  max-width: 330px;
}

.Form-group--m {
  max-width: 600px;
}

/**
 * This one adds the ability to collapse a group of items. To be more precise,
 * they are collapsed by default, and clicking on the header should reveal them.
 */
.Form-group--collapsible {
  &.is-open {
    > .Form-groupHeader {
      padding-bottom: 0.5em;
      // TODO: make this a variable/mixin
      box-shadow: 0 8px 7px -5px rgba(0, 0, 0, 0.14);

      &::before {
        content: "\e80a";
      }
    }

    > .Form-groupBody {
      display: block;
    }
  }

  > .Form-groupHeader {
    position: relative;
    padding-left: 1.35em;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 0.24em;
      left: 0;
      display: inline-block;
      content: "\e809";
      color: $gray-dark;
      font-family: $icon-font;
      font-size: 1em;
      line-height: 0.8;
    }
  }

  > .Form-groupBody {
    display: none;

    .is-noJs & {
      display: block;
    }
  }
}

.Form-group--horizontal {
  display: flex;
  align-items: center;

  .Form-label,
  .Form-control {
    max-width: 300px;
    margin: 0;
  }

  .Form-label {
    flex-shrink: 0;
    padding-right: 0.9em;
  }
}

.Form-groupHeader {
  display: block;
}

.Form-groupBody {
  overflow-y: auto;
  max-height: 300px;
  padding: 0.65em 0;
}

/* Table-like form group
  ========================================================================= */

/**
 * These create structure of a 3-column table
 * For now assuming that all 3 columns are present, the middle one being
 * the widest. This one also normally doesn't need extra spacing thanks to
 * paddings on its children, but feel free to wrap it in a .Form-group should
 * you feel you need some extra bottom margin.
 */
.Form-table {
  display: flex;
  margin-left: -$form-table-cell-gap * 2;
  align-items: center;

  @media (max-width: $screen-mobile-xxxl) {
    display: block;
    margin-left: 0;
  }
}

/**
 * Use this if a "table" has 4 "cells" and more, and should not get too cramped
 * up on smaller screens.
 */
.Form-table--collapseEarly {
  @media (max-width: $screen-tablet-s) {
    display: block;
    margin-left: 0;
  }

  > .Form-td {
    @media (max-width: $screen-tablet-s) {
      display: block;
      width: auto;
      padding-right: 0;
      padding-left: 0;
      text-align: left;
    }

    &:empty {
      @media (max-width: $screen-tablet-s) {
        display: none;
      }
    }
  }
}

/**
 * Sometimes we don't want this "table" to collapse into blocks on mobile. For
 * exampe, when the fields are rather small as they are.
 */
.Form-table--noCollapse {
  @media (max-width: $screen-mobile-xxxl) {
    display: flex;
    margin-left: -$form-table-cell-gap * 2;
  }

  > .Form-td {
    @media (max-width: $screen-mobile-xxxl) {
      display: inline-block;
      padding: $form-table-cell-gap 0 $form-table-cell-gap $form-table-cell-gap * 2;
    }
  }
}

/**
 * Sometimes the gaps created by vert. paddings of "cells" are unwanted.
 * E.g.: Search Grants form, PGH
 */
.Form-table--compact {
  &:first-child {
    margin-top: - $form-table-cell-gap;
  }

  &:last-child {
    margin-bottom: - $form-table-cell-gap;
  }
}

/**
 * A cell in a form's "table"
 * By default all "cells" auto expand and shrink
 *
 * 1) It can have some elements (icons, etc) that should be visually
 *    absolutely positioned towards a control (which is impossible by
 *    default)
 * 2) A fallback
 */
.Form-td {
  position: relative; /* 1 */
  display: inline-block; /* 2 */
  flex: 1 1 auto;
  padding: $form-table-cell-gap 0 $form-table-cell-gap $form-table-cell-gap * 2;
  vertical-align: middle;

  @media (max-width: $screen-mobile-xxxl) {
    display: block;
    padding-right: 0;
    padding-left: 0;
  }

  &:empty {
    @media (max-width: $screen-mobile-xxxl) {
      display: none;
    }
  }
}

// Fixed-width "cells" don't expand, but do shring (flex-shrink's not reset)
.Form-td--left {
  width: 25%;
  text-align: right;
  flex-grow: 0;

  @media (max-width: $screen-mobile-xxxl) {
    width: auto;
    text-align: left;
  }
}

.Form-td--middle {
  width: 45%;
  flex-grow: 0;

  @media (max-width: $screen-mobile-xxxl) {
    width: auto;
  }
}

.Form-td--right {
  width: 30%;
  text-align: left;
  flex-grow: 0;

  @media (max-width: $screen-mobile-xxxl) {
    width: auto;
  }
}

/**
 * This one is used to achieve "cells" as small as possible (shrink-to-fit)
 * Be aware that the text/inline-blocks WON'T be forced to wrap. I.e., if
 * the "cell"'s content is long, the cell might end up longer than its
 * siblings
 */
.Form-td--minimal {
  flex: 0 0 auto;

  @media (max-width: $screen-mobile-xxxl) {
    width: auto;
  }
}

/* Labels
  ========================================================================= */

// Labels
.Form-label {
  display: block;
  margin: 0 0 0.3125rem;
  cursor: pointer;
  text-transform: none;
  font-size: $font-size-sm;
  line-height: 1.2;

  &.is-required {
    &::after {
      margin: 0 1px;
      content: "*";
      color: $link-hover-color;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A label with a checkbox or a radio button inside.
 * Had to introduce this construct so that 1) a text didn't go under the
 * control on the 2nd line, AND 2) the test was inline for the * (in
 * `is-required`) to be right after it, not below
 *
 * 1) Removing pos:a for checkboxes/radios' error hints as otherwise they
 *    could overlap with the label's text
 * 2) cross browser hack the for radiobutton's/checkbox's align
 *    (Firefox, IE, Edge)
 * 3) For the elements not to be too close to each other, for touch screens
 */
.Form-label--withToggle {
  position: relative;
  margin-bottom: 0.625rem; /* 3 */
  padding-left: 1.5em;

  .Form-control--radio,
  .Form-control--checkbox {
    position: absolute;
    left: 0;
    margin-top: 0.1rem; /* 2 */
  }

  .Form-hint--error { /* 1 */
    position: relative;
    top: 0;
    float: right;
    margin-top: 0.3em;
  }
}

.Form-label--l {
  margin-bottom: 1rem;
  font-size: $font-size-base;
}

.Form-label--indented {
  margin-left: 1rem;
}

.Form-labelText {
  display: block;
  overflow: hidden;
  margin: 0 0 0.2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/* Controls
  ========================================================================= */

/**
 * This might be a ruleset with common styles for text fields (text, password,
 * email, etc.), selects.
 */
.Form-control {
  box-sizing: border-box;
  color: $text-color;
  font-family: $font-family-vanilla;
  font-weight: normal;

  &[disabled] {
    background: $gray-light;
  }
}

.Form-control--input,
.Form-control--textarea,
.Form-control--select {
  display: block;
  width: 100%;
  padding: 0.3125rem 0.375rem;
  border: 1px solid $border-color;
  border-radius: 0;
  background: $white;
  font-size: $font-size-sm;
  line-height: 1.5;
}

.Form-control--input,
.Form-control--select {
  height: $_form-input-height;
}

/**
 * 1) [Chrome] Chrome displays too much junk inside the input, which increases
 *  its size too much. Hiding the increase/decrease buttons
 */
.Form-control--input {
  &::-webkit-inner-spin-button { /* 1 */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }
}

/**
 * 1) [IE, Edge, all versions] Adding padding-right to a select leads to a gap
 *    between the right edge and the dropdown trigger. Fixing IE 10+
 * 2) The same, but for Edge 12+ (at least works in 14)
 */
.Form-control--select {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    padding-right: 0; /* 1 */
  }

  @supports (-ms-ime-align:auto) { /* 2 */
    padding-right: 0;
  }
}

.Form-control--textarea {
  height: 6.5rem;
}

.Form-control--checkbox {
  float: left;
  width: 14px;
  height: 14px;
  margin: 1px 7px 0 0;
  cursor: pointer;
}

/**
 * A dropdown pseudo-control
 */
.Form-dropdown {
  position: relative;
  display: block;
  cursor: pointer;
  border: 1px solid $border-color;
  background: $white;
  font-size: $font-size-sm;

  &.is-open {
    border-bottom-color: $white;

    > .Form-dropdownHeader {
      &::before {
        content: "\e80a";
      }
    }

    > .Form-dropdownBody {
      display: block;
    }
  }
}

.Form-dropdownHeader {
  position: relative;
  display: block;
  padding: 0.3125rem 0.375rem;
  padding-right: 1.4rem;

  &::before {
    position: absolute;
    top: 50%;
    right: 0.7rem;
    display: inline-block;
    content: "\e809";
    transform: translate(50%, -50%);
    color: $gray-dark;
    font-family: $icon-font;
    font-size: 1em;
    line-height: 0.8;
  }
}

.Form-dropdownBody {
  position: absolute;
  z-index: 1000;
  right: -1px;
  left: -1px;
  display: none;
  overflow-y: auto;
  max-height: 300px;
  padding: 0.65em;
  border: 1px solid $border-color;
  border-width: 0 1px 1px;
  background: $white;

  @media (max-width: $screen-mobile-xxxl) {
    position: relative;
    right: auto;
    left: auto;
    border-width: 0 0 1px;
  }

  &::after {
    display: block;
    width: 100%;
    height: 0.65em;
    content: "";

    @media (max-width: $screen-mobile-xxxl) {
      content: none;
    }
  }
}

.Form-dates {
  .Form-controlBlock {
    display: inline-block;
    width: 116px;
    vertical-align: middle;
  }
}

/* Icons
  ========================================================================= */

/**
 * A wrapper for a contol if that control should have an icon inside it (input)
 * or next to it (select).
 * ONLY form control (and optionally a .Form-icon) must be placed inside it.
 *
 * TODO: Should we actually move all this, together with the .Form-control, to
 *    a separate component? The only thing stopping us from doing that are forms
 *    with special colors and backgrounds, where inputs must look differently.
 *
 * 1) To make a <select> both expand and not have its margin beyond the parent's
 *    boundaries.
 */
.Form-controlBlock {
  position: relative;
  display: flex; /* 1 */
  min-height: $_form-input-height;

  .Form-control--input {
    padding-right: $_form-input-height;
  }

  .Form-control--select {
    flex-grow: 1; /* 1 */
    margin-right: $_form-input-height;
  }
}

/**
 * The same, but for bigger inputs
 */
.Form-controlBlock--l {
  min-height: $_form-control-height-l;

  .Form-control--input {
    height: $_form-control-height-l;
    padding-right: $_form-control-height-l;
  }

  .Form-control--select {
    height: $_form-control-height-l;
    margin-right: $_form-control-height-l;
  }

  .Form-icon {
    top: $_form-control-height-l / 2;
    right: $_form-control-height-l / 2;
    left: auto;
  }

  .Form-icon--btn {
    top: 0;
    right: 0;
    width: $_form-control-height-l;
    height: $_form-control-height-l;
    line-height: $_form-control-height-l;
  }
}

/**
 * An icon next to an input (for now, no other use cases).
 */
.Form-icon {
  position: absolute;
  z-index: 100;
  top: $_form-input-height / 2;
  right: $_form-input-height / 2;
  cursor: pointer;
  transform: translate(50%, -50%);
}

/**
 * This is for bigger icons (for easier tapping). There are also cases,
 * when such pos:a behavior is needed on a button with an icon inside, hense
 * the resets.
 * 1) Copied .Icon props
 */
.Form-icon--btn {
  top: 0;
  right: 0;
  width: $_form-input-height;
  height: $_form-input-height;
  padding: 0;
  transform: none;
  text-align: center;
  vertical-align: middle;
  border: none;
  background: none;
  font-size: 0.875em; /* 1 */
  line-height: $_form-input-height;

  &:hover {
    color: $link-hover-color;
  }
}

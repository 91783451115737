/**
 * define Slider
 *
 * Styles for sliders - technically carousels with only one visible element at
 * a time.
 * TODO: move the homepage/news slider here
 */

/* Helpers
 ========================================================================= */

$slider-pager-gap-horz: 0.5rem;

/* Component
 ========================================================================= */

/**
 * A jeneric full-width slider
 * E.g.: Market Advertising page (COSS-132)
 */

.Slider {
  display: block;
  overflow: hidden;
}

/**
 * A variant of a Slider without a carousel.
 * It's likely a container for only one slide (used on PGH-256). If we don't
 * need the slider functionality, then we don't need the extra space for the
 * pagination and the arrow controls.
 */
.Slider--noCarousel {
  .Slider-item {
    padding: 2rem 2.5rem;

    @media (max-width: $screen-mobile-m) {
      padding: 1rem 1.5rem;
    }
  }
}

/**
 * This slider has a) 1:0.88 image, b) slide text after the image (not
 * "inside"), c) thumbs pager between them (hidden for no-JS).
 * E.g.: Breaking News slider (Homepage), News image+text markup (News
 * homepage)
 *
 * 1) Allocating empty space between the image and the text so that the pager
 *    would occupy it (required by the design).
 * 1a) 25% * propoprtion - is space allocated for the pagination. 3% is for
 *    vartical gaps between the img and the pager and the pager and the
 *    following text
 *    Placing it here because there are places we have this slider's markup
 *    being static, i.e. just the markup. There is no pagination there, just
 *    the slide and the content, so we'll be nullifying this big margin with
 *    the `u-noMargin` util class, and only relying on the small bottom gap
 *    after the slide.
 * 1b) The pager has pos:a, is stuck to the top and shifted down for the image
 *    size (100% * proportion) + some gap. Since we need to shift the image
 *    based on the container's width, we could use margin-top: xxx%, but we
 *    can't due to a bug in Safari 6.1, Chrome 25-, Android 4.2-, when % vert
 *    margins of pos:a elements are based not on width (as per standard), but
 *    on height.
 *    So using translateY for the proportional shift: slide image height is
 *    88% of width, pager items are 1/4 of width => their height = 1/4 of
 *    image/container height, thus 400%. And margin-top for fixed sized gap.
 * 2) The pagination has negative left margin, so settihg width: 100% would
 *    shrink it by that margin's value.
 * 3) This silder is supposed to be inside the Breaking News block (thus
 *    consistent padding), but even if not - the texts will need the gaps
 *    anyway
 */
.Slider--imgProportion {
  position: relative;

  .Slider-item {
    height: auto;
    padding: 0;
  }

  .Slider-itemBg,
  .Slider-pageItemWrap {
    position: relative;
    height: auto;
    background-color: none;

    &::before {
      display: block;
      width: 100%;
      padding-top: 100% * $slider-img-height-proportion;
      content: "";
    }
  }

  .Slider-itemBg {
    display: block;
    overflow: hidden;
    width: auto;
    margin-bottom: 0.625rem;
  }

  .Slider-itemContent {
    max-width: none;
    margin: 25% * $slider-img-height-proportion + 3% 0 0; /* 1a */
    padding: 0 0.625rem 1rem; /* 3 */
    color: $text-color;
  }

  .Slider-pagination {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0; /* 2 */
    left: 0; /* 2 */
    margin-top: 4 * $slider-pager-gap-horz; /* 1b */
    transform: translate(0, 400%); /* 1b */
  }

  .owl-next,
  .owl-prev {
    top: 50% * $slider-img-height-proportion;
  }
}

/**
 * A slider placed in the content of an article
 *
 * 1) The same conditions as for switching to the post_square crop
 */
.Slider--inContent {
  display: flex;
  flex-wrap: nowrap;

  .Slider-item {
    display: block;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    padding: 0;
  }

  .Slider-itemBg {
    position: relative;
    height: auto;
    margin-bottom: 0.625rem;

    &::before {
      display: block;
      width: 100%;
      padding-top: 100% * $slider-img-height-proportion-wide;
      content: "";

      @media (max-width: $screen-mobile-xxxl) and (orientation: portrait) { /* 1 */
        padding-top: 100% * $slider-img-height-proportion;
      }
    }
  }

  .Slider-itemContent {
    max-width: none;
    color: $text-color;
    font-size: $font-size-sm;
  }
}

/* Slider item and its contents
 ========================================================================= */

/**
 * A slider item
 *
 * 1) Show only the 1st slide for noJS and while the carousel is still
 *    initializing, but not if inside an init-ed carousel
 * 2) Vert paddings - to make space for nav buttons on the sides
 */
.Slider-item {
  position: relative;
  display: none; /* 1 */
  height: 345px;
  padding: 2.5rem 4.5rem; /* 2 */
  color: $white;
  background-position: center center;
  background-size: cover;

  @media (max-width: $screen-mobile-xxl) {
    padding: 2rem 3.5rem;
  }

  @media (max-width: $screen-mobile-m) {
    padding: 1rem 3.5rem;
  }

  &:first-child {
    display: block; /* 1 */
  }

  .owl-carousel & {
    display: block; /* 1 */
  }
}

/**
 * A modifier for slider items that get smaller on tiny screens
 * Use case: Network page, so that the hero image's logo fits the screen
 */
.Slider-item--shrinkable {
  @media (max-width: $screen-mobile-l) {
    height: 280px;
  }
}

.Slider-itemBg {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $titlebar-bg;
}

/**
 * A text inside a slide (or maybe spread this for outside texts too?)
 *
 * 1) To be on top of a possible overlay (if we add it in the future)
 */

.Slider-itemContent {
  position: relative; /* 1 */
  z-index: 10; /* 1 */
  max-width: 23.5rem;
}

.Slider-itemContent--featured {
  top: 50%;
  max-width: none;
  transform: translate(0, -50%);

  .Slider-heading {
    line-height: 1;
  }
}

/**
 * 1) Resetting possible defaults
 */
.Slider-heading {
  @include mac-font-smoothing;
  @include font-family($font-family-alt);

  display: block;
  margin: 0 0 1rem;
  padding-bottom: 0.7rem;
  text-transform: none;
  color: $white;
  border-bottom: 1px solid;
  font-size: $font-size-xl;

  @media print {
    break-after: avoid;
    break-inside: avoid;
  }

  &:last-child {
    margin-bottom: 0;
    padding: 0;
    border: none;
  }

  &::after {
    content: none; /* 1 */
  }
}

.Slider-headingSup {
  display: block;
  color: #d9d9d9;
  font-size: 3.35rem;

  @media (max-width: $screen-tablet-s) {
    font-size: 2rem;
  }
}

.Slider-headingMain {
  display: block;
  font-size: 3.9rem;

  @media (max-width: $screen-tablet-s) {
    font-size: 2.25rem;
  }
}

/* Navigation
 ========================================================================= */

/**
 * Container for Pagination
 */
.Slider-pagination {
  @include clearfix;

  display: block;
  margin-left: -0.5rem;
}

/**
 * Slider pagination item wrapper. Using a separate element because we don't
 * want the spacing thing be clickable.
 *
 * 1) border instead of padding to be able to use absolutely positioned child
 *    (.Slider-pageImg)
 * 2) Making the border (along with the whole element) unclickable
 */
.Slider-pageItemWrap {
  position: relative;
  display: block;
  float: left;
  overflow: hidden;
  width: 25%;
  cursor: pointer;
  pointer-events: none;
  border-left: $slider-pager-gap-horz solid transparent; /* 1 */
  background-position: center center;
  background-clip: content-box;
  background-size: cover;
}

/**
 * The clickable item itself
 * Since the image is actually optional (we might want to opt for some other
 * elements), we need this for a non-JS link.
 *
 * 1) Taking the clickability back.
 */
.Slider-pageItem {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s linear 0s;
  pointer-events: auto; /* 1 */
  opacity: 0.5;

  &:hover,
  .Slider-pageItemWrap.active & {
    opacity: 1;
  }
}

/**
 * 1) For .Picture to have something to inherit from
 */
.Slider-img,
.Slider-pageImg {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%; /* 1 */
  transform: translate(0, -50%);
}

/**
 * 1) These don't need alt to be visible
 */
.Slider-pageImg {
  font-size: 0; /* 1 */
}

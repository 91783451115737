/** @define Container */

/**
 * A container that defines width of content
 * WARNING. For now vert. spacings are not set up for Container. So at the
 * moment Container is meant to be used only as a direct (and most likely the
 * only or the last) child of sth else. If there's a need to use multiple
 * Containers and/or have vert spacings, consider adding them here. DON'T make
 * a layout to rely on Container's last child bottom spacing/first child top
 * spacing to make a spacing after a Container.
 *
 * Media query values for width must be consistent with the ones from .Page
 * AND with responsive utility classes.
 */
.Container {
  @include clearfix;

  width: 1180px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;

  @media print {
    width: 100% !important;
    max-width: 1180px;
  }

  @media (max-width: $screen-desktop-s - 1) {
    width: 960px;
    // TODO: use paddings only with width:auto?
    padding-right: 10px;
    padding-left: 10px;
  }

  @media (max-width: $screen-tablet-l - 1) {
    width: 748px;
  }

  @media (max-width: $screen-tablet-s - 1) {
    width: auto;
  }
}

.Container--m {
  width: 860px;

  @media (max-width: $screen-tablet-l - 1) {
    width: 748px;
  }

  @media (max-width: $screen-tablet-s - 1) {
    width: auto;
  }

  &.Container--embed {
    max-width: 860px;
  }
}

/**
 * In case a page is being embedded as an Iframe, as the result of which there
 * is not much sense to limit a Container with @media queries since they would
 * work based on the width of the iframe, not the window
 */
.Container--embed {
  width: auto;
  max-width: 1180px;
}

/**
 * A container that needs vertical spacings.
 * The main case is top logo and bottom copyright blocks for printing - they
 * need some vertical before/after screen vertical edges and the .Page
 */
.Container--vertSpaced {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/**
 * @define utilities
 * Spacings.
 *
 * Only here `!important` is allowed for general use
 */

// margin
.u-noMargin {
  margin: 0 !important;
}

.u-noMarginTop {
  margin-top: 0 !important;
}

.u-noMarginBottom {
  margin-bottom: 0 !important;
}

.u-noMarginLeft {
  margin-left: 0 !important;
}

.u-noMarginRight {
  margin-right: 0 !important;
}

.u-noMarginHorz {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.u-noMarginVert {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.u-marginRightSM {
  margin-right: 1.25rem;
}

.u-marginBottomSM {
  margin-bottom: 1.25rem;
}

.u-marginBottomS {
  margin-bottom: 1rem;
}

.u-marginTopXS {
  margin-top: 0.5rem !important;
}

// padding
.u-noPadding {
  padding: 0 !important;
}

.u-noPaddingTop {
  padding-top: 0 !important;
}

.u-noPaddingBottom {
  padding-bottom: 0 !important;
}

.u-noPaddingLeft {
  padding-left: 0 !important;
}

.u-noPaddingRight {
  padding-right: 0 !important;
}

// margins that appears only on small screens
.u-noPaddingLeftRightMobileXXXL {
  @media (max-width: $screen-mobile-xxxl) {  // ~640px
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

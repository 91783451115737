/** @define CubWidgetLabel */

/**
 * Label styles for cub widget
 */

/**
 * Common styles
 */
.cub-Label {
  margin-bottom: 0;
  font-size: inherit;
}

.cub-Label--checkboxGroup {
  margin-bottom: 0.5rem;
}

.cub-Label--input,
.cub-Label--textarea,
.cub-Label--select {
  display: block;
  margin-bottom: 0.3125rem; // ~5px
  line-height: 1;
}

.cub-Label--inputGroup {
  margin-bottom: 0.75rem;
}

// Legend for Fieldset. Use the smae font styles as h2
.cub-Label--legend {
  @include font-family($font-family-alt);

  margin-bottom: 1rem;
  font-size: $font-size-ml;
}

/**
 * Rules for labels that wrap checkboxes an radios
 *
 * 1) resetting the default, as this shift is already managed here, by the
 *    labels' padding
 */
.cub-Label--checkbox,
.cub-Label--radio {
  margin-right: 1rem;
  line-height: 1.3;

  &:last-child {
    margin-right: 0;
  }

  .cub-Label-textAdditional {
    font-size: 0.9rem;
  }
}

.cub-Label--checkbox {
  margin-bottom: 3px;
  line-height: 1.4;
}

.cub-Label-asterisk {
  color: $link-hover-color;
}

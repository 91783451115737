.cw-Button {
  @extend %button !optional;

  &:hover {
    .cw-Button-icon {
      @extend %button-hover-icon !optional;
    }
  }
}

.cw-Button--l {
  @extend %button-l !optional;

  .cw-Button-icon {
    @extend %button-l-icon !optional;
  }
}

.cw-Button-icon {
  @extend %button-icon !optional;
}

.cw-Button--close {
  display: inline-block;
}

// TODO: refactor this shit
.cw-ButtonGroup {
  margin-bottom: 1rem;

  .cw-Button {
    margin-left: 0.625rem;

    &:first-child {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

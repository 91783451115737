/* @define Search */

.Search {
  overflow: hidden;
}

.Search-resultsCount {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.Search-resultRow {
  margin-bottom: 1.2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.Search-facets {
  float: right;
  width: 25%;
  margin: 0 0 1rem 1rem;

  @media (max-width: $screen-tablet-s) {
    width: 100%;
    margin-left: 0;
  }
}

.Search-facetRow {
  margin: 0;
  padding: 0;
  color: $text-color;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: $border-color;

  &::before {
    content: none;
  }

  &:first-child {
    border-width: 1px;
  }

  &:hover {
    color: $white;
    background: $titlebar-hover-icon-bg;
  }
}

.Search-facetRow--selected {
  color: $white;
  border-color: $titlebar-icon-bg !important;
  background: $titlebar-icon-bg !important;
}

.Search-facetLink {
  display: block;
  padding: 1em;
  transition: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.Search-loadMore {
  margin-bottom: 1rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

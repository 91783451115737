/** @define CubWidgetMenu */

/**
 * Menu styles for of cub widget v0.13.
 * 1) This css contains !important to override more specific site selectors.
 * After optimizing site css !important can be removed from this code.
*/

// mixin for menu item separator
@mixin cub-menu-separator($hide-last-child: false) {
  $_padding: 1.25em;

  position: relative;
  padding-right: $_padding * 2;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1em;
    margin: 0 $_padding;
    content: "";
    transform: translateY(-50%);
    background: $white;
  }

  @if ($hide-last-child == true) {
    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

/**
 * 1) Needed for the field with text-overflow to inherit its max-width from
 */
.cub-Menu {
  @include clearfix;

  position: relative;
  display: block;
  float: right;
  margin: 0;

  @media (max-width: $screen-tablet-l) {
    clear: both;
  }

  @media (max-width: $screen-tablet-s) {
    float: none;
    padding: 10px;

    &.is-menuOpened {
      background: $cub-menu-bg;
    }
  }

  .cub-Image--medium {
    margin-right: 1.25rem;

    @media (max-width: $screen-tablet-s) {
      margin-right: 0.75rem;
    }
  }
}

/**
 * 1) Needed for the field with text-overflow to inherit its max-width from
 */
.cub-Menu-items {
  max-width: 100%; /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}

/**
 * 1) Needed for the field with text-overflow to inherit its max-width from
 * 2) Overriding <li> defaults
 * 3) Overriding our styles for <li>
 * 4) On this screen size the elements in the header are aligned to top in CSS
 *    to avoid creepy vert centering when a Cub menu is expanded. But we still
 *    want them to LOOK like they are centered vertically in the default state.
 *    This extra spacing would actually hurt the menu if a user is signed in
 *    (i.e. the block has a picture). So applying this trick to .cub-Menu-item,
 *    not to any of its parents, to be able to counter it in
 *    .cub-Menu-item--user
 */
.cub-Menu-item {
  position: relative;
  float: left;
  max-width: 100%; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
  color: inherit;
  line-height: 1rem;

  &::before { /* 3 */
    content: none;
  }

  @media (max-width: $screen-tablet-l) {
    font-size: $cub-font-size;
  }

  @media (max-width: $screen-tablet-s) {
    padding-top: 0.15em; /* 4 */
  }
}

/**
 * A menu item for a signed in user.
 *
 * 1) Countering the vert shifting for .cub-Menu-item (set in the ruleset
 *    above) for smaller screens.
 */
.cub-Menu-item--user {
  padding: 0; /* 1 */
}

.cub-Menu-link {
  @media (max-width: $screen-tablet-l) {
    // Line-height is related to CubWidgetImage size
    line-height: 1.875rem; // 30px
  }

  @media (max-width: $screen-tablet-s) {
    line-height: 1.25rem; // 20px
  }

  @media (max-width: $screen-tablet-s - 1) {
    max-width: 350px;
  }
}

.cub-Menu-link,
.cub-Menu-submenuLink {
  position: relative;
  transition: all 0.3s ease;
  text-align: right;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;

  &:hover {
    text-decoration: none;
    color: $cub-menu-hover-color !important;
  }

  @media (max-width: $screen-tablet-s) {
    text-align: left;
  }
}

// submenu
.cub-Menu-itemsContainer {
  top: 35px;
  right: 0;
  left: auto;
  display: flex;
  margin: 0;
  background-color: $cub-menu-bg;
  align-items: center;

  &::after {
    position: absolute;
    top: -5px;
    right: 15px;
    width: 0;
    height: 0;
    content: "";
    border-width: 0 5px 5px 5px;
    border-style: solid;
    border-color: transparent transparent $cub-menu-bg transparent;
  }

  @media (max-width: $screen-tablet-l) {
    position: relative;
    top: 0;
    float: right;
    margin-top: 0.5rem;
  }

  @media (max-width: $screen-tablet-s) {
    width: 100%;
    margin-top: 10px;
    padding: 0;
    padding-top: 10px;
    border-top: 1px solid $cub-menu-color;
    background-color: transparent;

    &::after {
      display: none;
    }
  }
}

.cub-Menu-submenuItems {
  flex: 1;
  margin: 0;
  padding-left: 0;
}

.cub-Menu-submenuItem {
  display: block;
  float: none;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: $screen-tablet-l) {
    font-size: $cub-font-size;
  }
}

.cub-Menu-submenuLink {
  float: none;
  width: auto;
  text-align: left;
}

// menu items modifiers
.cub-Menu-link--login {
  @include cub-menu-separator;
}

.cub-Menu-link--userMenu {
  display: block;
  float: right;
  overflow: hidden;
  height: 1.4375rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.4375rem;

  @media (max-width: $screen-tablet-l) {
    height: 30px;
    line-height: 30px;
  }

  @media (max-width: $screen-tablet-s) {
    display: inline-block;
    float: none;
    height: 20px;
    vertical-align: middle;
    line-height: 20px;
  }
}

.cub-Menu-item--user {
  @media (max-width: $screen-tablet-s) {
    float: none;
  }
}

.cub-Menu-item--org {
  display: none;
}

/**
 * Some beautification for FormStack embeds
 * Like here: https://www.corrections1.com/help/advertise/
 * Using an id wrapper to avoid repeating multi-class selectors from the
 * Formstack's CSS to override them.
 * 1) remove clearing, because the FormStack form jumps under the left column
 * when .fsError class added
 */

#formstack {
  .fsSectionHeading {
    @extend .Heading;
    @extend .Heading--xl;
  }

  /**
   * Using these resets because right here in the gray box:
   * https://www.corrections1.com/help/advertise/ there is a 3-level list
   * with EMPTY outer two levels (!) with text markers:
   * `<ul><ul><ul><li> • some text</li> ...`
   */
  ul {
    padding: 0;
    list-style-type: none;
  }
}

.fsError {
  clear: none !important; /* 1 */
}

// TODO: refactor this
.GoToDispatchLink {
  position: fixed;
  z-index: 9999;
  top: 50%;
  right: 0;
  display: block;
  padding: 2px 5px;
  color: white;
  border-radius: 5px 0 0 5px;
  background: green;
  font-size: 12px;
  font-weight: bold;

  &:hover {
    color: white;
  }
}

/**
 * Styles related to the Choices plugin
 * https://github.com/jshjohnson/Choices
 */
$choices-bg-color: $white;
$choices-keyline-color: $border-color;
$choices-border-radius: 0;

// EXCEPTION. Below are the custom styles for a 3rd party lib that are only
// used with the styles of that lib
// stylelint-disable-next-line scss/partial-no-import
@import "choices.js/assets/styles/scss/choices";

.choices__inner,
.choices[data-type*="select-one"] .choices__input,
.choices__list--dropdown .choices__item {
  height: 1.875rem;
  min-height: 1.875rem;
  padding: 0.3125rem 0.375rem;
}

.choices__list--dropdown .choices__item {
  overflow: hidden;
  white-space: nowrap;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 0.375rem;
}

.choices__list--single {
  padding: 0;
}

.choices[data-type*="select-one"]::after {
  z-index: 2;
}

.choices__list--dropdown {
  top: 1px;
}

/** @define CubWidgetTabs */

/**
 * Tabs styles for cub widget
 */
// mixins
@mixin tabs() {
  display: flex;
  clear: both;
  overflow: hidden;
  width: 100%;
  background: #dbdbdb; // IE 9 fallback
  background: linear-gradient(to bottom, #fafafa 0%, #dbdbdb 100%);
}

@mixin tab-item($side-borders: false) {
  @include font-family($caps: true);

  display: flex;
  float: left;
  flex: 1;
  margin: 0;
  padding: 12px 0;
  color: $gray-dark;
  border: none;
  border-right: 1px solid $border-color;
  outline: none;
  background: none;
  font-size: $font-size-sm;
  font-weight: bold;
  line-height: 1.3em;
  align-items: stretch;

  > a {
    flex: 1;
    align-items: stretch;
  }

  @if ($side-borders) {
    &:first-child {
      border-left: 1px solid $border-color;
    }
  } @else {
    &:last-child {
      border-right: 0;
    }
  }

  @media screen and (max-width: $screen-desktop-s - 1) {
    font-size: $font-size-sm;
  }
}

@mixin tab-item-active() {
  margin: 0;
  padding: 12px 0;
  color: $black;
  background: $white;
  /* stylelint-disable */
  /* autoprefixer: ignore next */
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
  /* autoprefixer: ignore next */
  filter: none;
  /* stylelint-enable */
}
// end of mixins

// TODO: remove this file after next refactoring:
// remove .article-template ul
.cub-Tabs {
  // TODO: extent Tabs... or just copy-paste, the code is pretty simple
  @include tabs;

  max-width: $cub-width;
  margin-bottom: 1rem !important;
  border: 1px solid $border-color;
  border-bottom: none;
}

/**
 * A tab
 *
 * 1) Resetting our generic <li>
 */
.cub-Tabs-item {
  &::before {
    content: none; /* 1 */
  }

  &,
  &:hover {
    // TODO: extent Tabs-item... or just copy-paste, the code is pretty simple
    @include tab-item;

    text-align: left;
    border-radius: 0;
  }

  &.is-active {
    @include tab-item-active;
  }

  &,
  &:hover,
  &.is-active,
  &.is-active:hover {
    padding: 0;
    border: none;
    border-left: 1px solid #bbbaba;

    &:first-child {
      border: none;
    }
  }
}

.cub-Tabs-itemLink {
  width: 100%;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

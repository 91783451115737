/**
 * Old peel banner styles
 * Also used in our custom DFP interstitial templates, so if we decide to
 * replace these, we'll have to change the current banners and provide Ceres
 * with the new markup
 */

.u-visible {
  display: block !important;
  opacity: 1 !important;
}

#png,
#popup_container,
#popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#png,
#popup_container {
  display: none;
  transition: opacity 0.3s ease 0s;
  opacity: 0;
}

#png {
  z-index: 9998;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
}

#popup_container {
  z-index: 9999;
  overflow-y: auto;
}

/**
 * This holds the .popup-inner, which is a container for the ad's contents
 *
 * 1) A ghost element to enforce line-height to be 100% and by that be able to
 *    center the inner block vertically.
 * 2) Removing the gap between the ghost and the inner block
 */
#popup {
  text-align: center;
  font-size: 0; /* 2 */

  &::before { /* 1 */
    display: inline-block;
    width: 0;
    height: 100%;
    content: "";
    vertical-align: middle;
  }
}

/**
 * A container for the ad's contents
 *
 * 1) Setting the default font + resetting fz:0 on a parent
 */
.popup-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem; /* 1 */
}

#object_container {
  position: relative;
  width: 100%;
}

#object_container {
  > div {
    img {
      max-width: 100%;
      border: none;
      outline: none;
    }
  }
}

/**
 * 1) Define font-family, cause it binds to button.
 *    This css is used inside Dfp iframe.
 */
.close-btn {
  @extend %button !optional;

  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; // 1

  &::after {
    content: "SKIP THIS AD";
  }

  img {
    display: none;
  }
}

body {
  @media screen and (max-width: 560px) {
    #object_container {
      iframe,
      embed,
      object {
        width: 100% !important;
      }
    }
  }
}

// styles for peelbanner with bootstrap

.popup-ad-container {
  display: none;
}

.popup-ad-container-inner {
  position: relative;
  z-index: 9998;
  width: 100%;
  text-align: center;
}

.cover {
  position: absolute;
  z-index: 9999;
  right: 0;
  left: 0;
  width: 640px;
  height: 480px;
  cursor: pointer;
}

.modal {
  &.popup-ad-container {
    .modal-dialog {
      width: auto;
      background: none;
    }
  }
}

.popup-ad-content-container {
  #lead-form-container-with-department-horizontal {
    box-sizing: border-box;
    width: 635px;
    margin: 0 auto;
  }

  img {
    max-width: 100%;
  }
}

@media (max-width: $screen-tablet-s - 1) {
  .modal {
    &.popup-ad-container {
      .modal-dialog {
        width: 100%;
      }
    }

    .popup-ad-content-container {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// A container element for an Interstitial banner
.Banner--modal {
  display: none;
  overflow: visible;
  margin: 0;

  iframe {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

/**
 * @define TitleBar
 *
 * A heading/title in a form of a bar with background
 */

/**
 * HTML examples:
  Simplest - without a link, just a heading:
  <h1 class="TitleBar">Police Careers and Job Listings</h1>

  Without a link, with some text to the right
  <div class="TitleBar">
    <h2 class="TitleBar-heading">Some text</h2>
    <span class="TitleBar-inner">other text</span>
  </div>

  Wrapping it in an <a> will make it a link - with :hover effect and an arrow
  at the right:
  <a class="TitleBar" herf="...">
    <h2 class="TitleBar-heading">Some text</h2>
    <span class="TitleBar-inner">other text</span>
  </a>

  With a Grants image (e.g. PoliceGrantsHelp.com) and police badge:
  <a class="TitleBar TitleBar--grants TitleBar--badge" href="#">
    <h2 class="TitleBar-heading">PoliceGrantsHelp.com</h2>
  </a>
*/

/* Helpers
 ======================================================================= */

$_titlebar-arrow-width: 1.875rem;
$_titlebar-knowledge-color: #a2d6fe;
$_titlebar-knowledge-arrow: #1174c9;
$_titlebar-knowledge-bg: #004a87;

/* The component
  ======================================================================= */

/**
 * A default title bar
 *
 * 1) An arrow and the border as its bg are only used with links, but
 *    setting them up here for easier overriding.
 */
.TitleBar {
  @include titlebar-basis;
  @include clearfix;

  display: block;
  border: 0 solid $titlebar-icon-bg; /* 1 */

  @media print {
    padding-right: 0;
    padding-left: 0;
    color: $text-color;
    border: none;
    background: none;
    break-after: avoid;
    break-inside: avoid;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before { /* 2 */
    position: absolute;
    top: 50%;
    right: - $_titlebar-arrow-width / 2;
    display: none; /* 2 */
    content: "\f105"; // .Icon--angle-right
    transform: translate(50%, -50%);
    font-family: $icon-font;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;

    @media print {
      content: none;
    }
  }
}

/**
 * This SUIT violation is a RARE EXCEPTIONAL CASE. If we used a modifier
 * here, then backend guys would have to add both <a> and a modifier class.
 * So decided to spare them extra work.
 *
 * 1) A border will serve as a bg for an arrow, and with it we can safely
 *    keep usual padding (not include the arrow part in it).
 */
/* stylelint-disable */
a.TitleBar {
  /* stylelint-enable */
  transition: none 0.2s ease 0s;
  transition-property: border-color, background-color;
  text-decoration: none;
  border-right-width: $_titlebar-arrow-width; /* 1 */

  &::before {
    display: block;
  }

  &:hover {
    text-decoration: none;
    color: $white;
    border-color: $titlebar-hover-icon-bg;
    background-color: $titlebar-hover-bg;
  }
}

/**
 * A titlebar to be used in different kinds of promotions and ads
 * E.g.: GMP-703
 */
.TitleBar--promo {
  color: $color-green-vogue;
  background: $yellow;
}

/**
 * A title with InPublicSafety background image
 * e.g.: https://policeone.com
 *
 * 1) Visually hide the text (but keep it for robots/screen-readers)
 */
.TitleBar--safety {
  height: 40px;
  border-color: $titlebar-inpublicsafety-icon-bg;
  background-image: url("../images/bg-in-public-safety.jpg");

  &.is-lazyLoad {
    background-image: none;

    > .TitleBar-heading {
      visibility: visible;
    }
  }

  > .TitleBar-heading {
    visibility: hidden; /* 1 */

    @media print {
      visibility: visible;
    }
  }
}

/* stylelint-disable */
a.TitleBar--safety:hover {
  /* stylelint-enable */
  border-color: $titlebar-inpublicsafety-hover-icon-bg;
}

/**
 * A title with LifeStyle background image
 * e.g.: https://policeone.com
 *
 * 1) Visually hide the text (but keep it for robots/screen-readers)
 */
.TitleBar--lifestyle {
  height: 40px;
  background-image: url("../images/bg-lifestyle.jpg");

  &.is-lazyLoad {
    background-image: none;

    > .TitleBar-heading {
      visibility: visible;
    }
  }

  > .TitleBar-heading {
    visibility: hidden; /* 1 */

    @media print {
      visibility: visible;
    }
  }
}

/**
 * A title with QuietWarriors background image
 * e.g.: https://policeone.com
 *
 * 1) Visually hide the text (but keep it for robots/screen-readers)
 */
.TitleBar--quietWarriors {
  height: 40px;
  border-color: $titlebar-quietwarriors-icon-bg;
  background-image: url("../images/bg-quiet-warriors.jpg");

  @media print {
    background: none;
  }

  &.is-lazyLoad {
    background-image: none;

    > .TitleBar-heading {
      visibility: visible;
    }
  }

  > .TitleBar-heading {
    visibility: hidden; /* 1 */

    @media print {
      visibility: visible;
    }
  }
}

/**
 * A title bar with a Police badge to the right
 * Using a conditional variable here because we SPECIFICALLY need to NOT
 * HAVE these styles on some sites (i.e. the class will be there, but it
 * mustn't have any effect)
 *
 * 1) Putting it on .TitleBar-heading's pseudo since the component's after
 *    is for clearfix and before is for an arrow. Using :before since
 *    .TitleBar-heading's might be used as Grant image (see above)
 */

@if ($titlebar-is-include-badge-styles == true) {
  .TitleBar--badge {
    border-left: 40px solid $titlebar-icon-bg;

    > .TitleBar-heading {
      /* stylelint-disable */
      &::before { /* 1 */
        /* stylelint-enable */
        position: absolute;
        top: 0;
        left: -40px;
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 15px;
        content: "";
        background: url("../images/icon-police-badge.png") center no-repeat;
      }
    }
  }
}

/**
 * A title for Officer Down blocks
 *
 * 1) This is not a conventional border, so it doesn't have $border-color
 */
.TitleBar--black {
  border-color: $gray-medium; /* 1 */
  background-color: $black;
}

/* stylelint-disable */
a.TitleBar--black {
  /* stylelint-enable */
  &:hover {
    border-color: darken($gray-medium, 15%);
    background-color: $black;
  }
}

/**
 * A gray one with. It has a border, so it CAN'T BE A LINK.
 */
.TitleBar--gray {
  color: $blue-header;
  border: 1px solid $border-color;
  background-color: $gray-light;
}

/**
 * A title with a logo at the right (next to an arrow).
 *    And its variant with backgroundless logo
 * Can't acheive it with just pasting an element, because the parent and
 * the heading styles needed to be changed.
 *
 * 1) Using flex, not d:tb because browsers calculate the position of
 *    a pos:a pseudo with an arrow differently (FF, IE: relative to the
 *    outer edge including border, Webkit: not including border)
 * 2) [Safari] will treat the :auto as an element and give it some space.
 *    Preventing that.
 * 3) IE10-11 don't treat inline elements as flex children. Fixing that
 * 4) So that the logo image vertical alignment was smooth.
 * 5) [non-flex] Should work in old Firefoxes and IE9
 */
.TitleBar--withLogo,
.TitleBar--withLogoNoBg {
  display: flex; /* 1 */
  padding: 0;
  align-items: center;

  &::after { /* 2 */
    content: none;
  }

  > .TitleBar-heading {
    display: block; /* 3 */
    flex: 1 1 auto; /* 1 */
    padding: 0.625rem;

    .is-noFlexOr2009Only & { /* 5 */
      display: table-cell;
      width: 100%;
    }
  }

  > .TitleBar-logoBlock {
    display: flex;
    float: none;
    flex: 0 0 auto; /* 1 */
    padding-top: 5px;
    padding-bottom: 5px;
    background: $titlebar-icon-bg;
    font-size: 0; /* 4 */
    align-items: center;
    align-self: stretch;

    .is-noFlexOr2009Only & { /* 5 */
      display: table-cell;
      vertical-align: middle;
    }
  }
}

/* stylelint-disable */
a.TitleBar--withLogo:hover {
  > .TitleBar-logoBlock {
    /* stylelint-enable */
    background: $titlebar-hover-icon-bg;
  }
}

/**
 * A little bit changed variant of .TitleBar--withLogo without bg under logo
 */
.TitleBar--withLogoNoBg {
  > .TitleBar-logoBlock {
    margin-right: 0.5rem;
    background: none;
  }
}

/**
 * A title bar that should have a group of buttons (or something else) at
 * the right.
 * ISN'T MEANT TO BE A LINK
 * E.g.: Video Originals (bottom), Protected article page:
 * http://responsive.policeone.com/policeone/topic-article-video-template.html
 *
 * 1) For the margins to work (the element can be a span)
 * 2) Fallback for non-flex browsers
 */
.TitleBar--withBtns {
  display: flex;
  width: 100%;
  align-items: center;

  @media (max-width: $screen-tablet-s) {
    display: block;
  }

  > .TitleBar-heading {
    display: block; /* 1 */
    float: left; /* 2 */
    flex: 1 1 auto;
    margin: 0 0.5rem 0 0;

    @media (max-width: $screen-tablet-s) {
      float: none;
      margin-bottom: 0.5rem;
    }
  }

  > .TitleBar-extraText {
    float: none;
    flex: 0 0 auto;
    margin: 0;

    @media (max-width: $screen-tablet-s) {
      display: block;
      text-align: center;
    }
  }
}

/**
 * A TitleBar with a number at the left.
 * TODO: making this a titlebar because some combinations of this num., logo,
 * and other such elements might occure. But it's also possible to make it
 * a list element with a proper counter
 */
.TitleBar--numbered {
  $_border-width: 2.5rem;

  @include font-family($font-family-alt);

  text-transform: none;
  border-left-width: $_border-width;
  font-size: $font-size-ml;

  @media print {
    border: none;
  }

  .TitleBar-count {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: - $_border-width / 2;
    display: block;
    transform: translate(-50%, -50%);
    line-height: 1;

    @media print {
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      padding-right: 0.6rem;
      transform: none;
    }
  }
}

/**
 * "Lexipol Knowledge base"
 * Usnig fixed color values because the styles for this titlebar should be the
 * same across all verticals.
 */
.TitleBar--knowledge {
  color: $_titlebar-knowledge-color;
  border-color: $_titlebar-knowledge-arrow;
  background: $_titlebar-knowledge-bg;
}

/* stylelint-disable */
a.TitleBar--knowledge {
  /* stylelint-enable */
  &:hover {
    text-decoration: none;
    color: $_titlebar-knowledge-color;
    border-color: lighten($_titlebar-knowledge-arrow, 10%);
    background-color: lighten($_titlebar-knowledge-bg, 10%);
  }
}

// a.TitleBar

/* The elements
  ======================================================================= */

/**
 * An element class specifically for cases, when <h2>, etc. need to be NOT
 * the .TitleBar, but INSIDE it
 *
 * 1) So that we can place the right-floated text element AFTER the heading
 *    while keeping them on the same line.
 * 2) Resetting h2's defaults
 */

.TitleBar-heading {
  @include font-family(inherit);

  display: inline; /* 1 */
  margin: 0;
  color: inherit;
  font: inherit;

  &::after {
    content: none;
  }
}

/**
 * An inline text element. Might only be needed if a .TitleBar-logo is present -
 * to make its spacings work as intended.
 */
.TitleBar-text {
  margin: 0 0.25em 0 0;

  &:last-child {
    margin-right: 0;
  }
}

/**
 * Just a smaller text alongside the heading. Floated to the right
 *
 * 1) The TitleBar has AA on Mac on; with that this text it too thin. Fixing
 * 2) A small cheat ^_^. On pages like https://ems1.com/ems-careers/ the
 *    "Total jobs..." texts' and the 3rd column's left edges should be
 *    aligned. So basically, this prop is to avoid introducing width-based
 *    utils. In all other cases this should not hurt as well, so the cheat
 *    is harmless.
 */
.TitleBar-extraText,
.TitleBar-extraTextSmall {
  @include mac-font-smoothing(false); /* 1 */

  float: right;
  min-width: 18%; /* 2 */
  margin-left: 0.625rem;
  text-align: right;
  font-weight: 400;
}

/**
 * e.g.: Job List http://responsive.policeone.com/policeone/job-list.html
 */
.TitleBar-extraText {
  @include font-family;

  font-size: $font-size-sm;
}

/**
 * Even smaller text at the right, slightly grayed out
 * 1) inherit the parent's color on hover. TODO: uncomment after refactoring
 *    (.responsive now gets reflected in `&` selector)
 * e.g.: Brand Focus https://policeone.com
 */
.TitleBar-extraTextSmall {
  margin-top: 0.3em;
  text-transform: uppercase;
  color: #d1d1d1; // TODO: CROSS-1352
  font-size: $font-size-xs;
}

/**
 * A block with a logo. Just float it to the right by default
 */
.TitleBar-logoBlock {
  float: right;
  overflow: hidden;
  max-width: 150px;
  padding-left: 0.635rem;
  transition: background-color 0.2s ease 0s;

  > .TitleBar-logo {
    max-height: 30px; /* 1 */
  }
}

/**
 * The logo if it's by itself.
 *
 * 1) We need the alt for accessibility, but we don't want it visible because
 *    while the image is loading it has incorrect vertical alignment
 */
.TitleBar-logo {
  display: inline-block;
  max-height: 16px;
  margin: 0 0.25em 0 0;
  vertical-align: middle;
  color: transparent;

  &:last-child {
    margin-right: 0;
  }
}

/**
 * 1) [webkit/blink] A bug with max-height being not enough for properly scaling
 *    an SVG image
 */
.TitleBar-logo--svg {
  height: 1000px; /* 1 */
}

/**
 * This one is closely tied to the title bar, so maybe leave it as element
 * e.g.: Brand Focus https://policeone.com
 */
.TitleBar-highlight {
  color: $yellow;
}

/**
 * To be used inside mainly TitleBar--withBtns (but possible in other
 * variants as well). Not using `.TitleBar .Button` to avoid unnecessary
 * extra selectors if .Button gets @extend-ed (we plan to @extend Button
 * in Cub buttnos)
 */
.TitleBar-btn {
  margin-right: 0.4rem;

  &:last-child {
    margin-right: 0;
  }
}

/**
 * Overriding miscellaneous widgets, iframes, embeds, etc.
 * TODO: probably split this file into multiple ones when the content grows
 */

/**
 * Twitter widget
 *
 * 1) Bug in Chrome when <twitterwidget> shrinks when next to a left-floated
 *    block
 */
.twitter-tweet-rendered {
  overflow: hidden; /* 1 */
}

/**
 * Google Recaptcha.
 *
 * 1) To shrink-to-fit the captcha while pertaining its block-like behavior
 * 2) If we keep d:tb, the scaling will work only visually, while the captcha
 *    will be filling the space originally intended (not 270, but 304 e.g.)
 */
.g-recaptcha {
  display: table; /* 1 */
  margin: 0 auto 1rem;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  // Scale Google Captcha for tiny screens, otherwise it won't fit the screen
  @media (max-width: $screen-mobile-s) {
    display: block; /* 2 */
    width: 270px;
    transform: scale(0.9);
    transform-origin: 0 0;
  }
}

/**
 * Storify stopped working in May 2018. Hiding its embeds to avoid showing
 * the 503 to the users, until deciding what to do with them.
 */
.storify {
  display: none !important;
}

/** @define CubWidgetExperience */

/**
 * Experience styles for cub widget
 */
.cub-UserExperience {
  .cub-Caption {
    @include font-family($font: $font-family-alt, $line-height: 1);

    margin: 0 0 12px;
    padding: 0;
    color: $black;
    border: none;
    background: none;
    font-size: $font-size-xxxl;

    a {
      font-family: inherit;
    }
  }
}

.cub-Experience {
  .cub-Text {
    max-width: $cub-width;
  }
}

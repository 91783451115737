.cw-Text {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.cw-Text--M {
  font-size: 0.875rem;
}

.cw-Text--gapS {
  margin-bottom: 0.5rem;
}

.cw-Text--gapTopXS {
  margin-top: 0.25rem;
}

.cw-Text--withIcon {
  position: relative;
  margin-right: 1.25rem;
  padding-left: 2.5rem;
}

.cw-Text-icon {
  position: absolute;
  top: -5px;
  left: 0;
  width: 2rem;
  transform: rotate(0deg);
  transform-origin: 49% 50%;
  text-align: center;
}

/**
 * The main media element.
 * Uses the <blockquote> (though with inline styles) tag so some resets are
 * needed
 *
 * 1) Resetting our <blockquote> styles
 */
.instagram-media {
  &::before {
    content: none; /* 1 */
  }
}

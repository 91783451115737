/**
 * @define HCard
 *
 * This component is quite similar to .Card, even the elements' names are
 * mostly identical. The main difference is that the image/video is floated,
 * so most of the decisions ("how to have img and title under one parent",
 * etc.) are made keeping that in mind.
 *
 * It was decided to make a separate component for these instead of a set of
 * modifiers for .Card because there are quite a few of modifiers to this
 * very type of constructs.
 *
 * WARNING. If you plan to change any of the image sizes here, please adjust
 * the preset for the Picture C# component as well.
 */

/* Sass helpers
 ========================================================================= */

// A gap between an img/video an the texts to the right of it
$_hcard-img-margin-left: 1rem;
$_hcard-img-title-margin-bottom: 0.625rem;

/**
 * Just margins for meta items for HCard's title (icons, date)
 */

@mixin _hcard-meta-item {
  margin: 0 0 0 0.4em;

  &:first-child {
    margin-left: 0;
  }
}

/**
 * This mixin creates styles for img/video block size HCard modifiers
 *
 * $img-block-width - the width of the image block. Meant to be FIXED. Also
 *    used as a basis for texts block size in HCard--table and for media
 *    breakpoint when the HCard should become a row.
 * $breakpoint - (optional) a media breakpoint for when the HCard shoul
 *    become a row. If not specified, the breakpoint is calculated based on
 *    the $img-block-width value
 *
 * 1) For HCard--table. d:b is actually not that crucial.
 * 2) There are images that, while not being wide enough, are tall enough for
 *    repsonsive img markup. So we might not want to force users to replace
 *    such images with wider ones, but we'll still need to use `width: 100%`
 *    on them to prevent them from shrinking on high-dpr screens. Which would
 *    cause very ugly upscaling. To prevent that we'll be limiting the image
 *    height, plus `object-fit: scale`.
 *    Using a limit on the block as well for .Picture inside it to inherit it.
 * 2a) We'll be using bigger limits for smaller HCards since product info is
 *    often displayed in them. And it's products that tend to have such tall
 *    images (like - tall photos of vertical objects)
 * 3) Setting max-width for cases when a imgBlock has d:tbc
 *    Eg.: .HCard--carouselItem
 */

@mixin _hcard-img-variant(
  $img-block-width: 100px,
  $breakpoint: null
) {
  $breakpoint: if($breakpoint != null, $breakpoint, $img-block-width * 2.2);
  $_img-max-height: if($img-block-width > 150px, $img-block-width, 150px); /* 2a */

  @if ($breakpoint >= $screen-mobile-xs) {
    @media (max-width: $breakpoint) {
      display: block; /* 1 */
      margin-top: 0; /* 1 */

      &::after {
        content: none;
      }
    }
  }

  .HCard-imgBlock,
  .HCard-videoLink,
  // This thing is to make space to the left of the textsWrap
  .HCard-imgTitleWrap::after {
    width: $img-block-width;
    max-width: $img-block-width; /* 3 */
    max-height: $_img-max-height; /* 2 */

    @if ($breakpoint >= $screen-mobile-xs) {
      @media (max-width: $breakpoint) {
        float: none;
        width: auto;
        max-width: 100%;
        margin: 0 auto 0.625rem;
        content: none;
      }
    }
  }

  @if ($img-block-width >= 200px) {
    .HCard-videoLink {
      &::after {
        font-size: 40px;
      }
    }
  }

  .HCard-img {
    max-height: inherit; /* 2 */
  }

  .HCard-textsWrap {
    flex-basis: $img-block-width * 1.2; /* 1 */
  }
}

/* The component
 ========================================================================= */

/**
 * A generic HCard
 *
 * 1) Clears img/video's float, as neither .HCard-imgTitleWrap (otherwise the
 *    following text could be too far away from the title), nor .HCard-text
 *    (it can be missing) can't do that.
 * 2) A tricky pseudo is shifted down by the bottom margin of imgTitleWrap. To
 *    compensate for that we use the same value (yet negative) here and
 *    exactly the same value for textsWrap
 *    TODO: ajust this for HCards with different title font options.
 */

/** Example markup:
  <div class="HCard HCard--imgL">
    <a class="HCard-imgTitleWrap" href="#" data-js-hover>
      <div class="HCard-videoLink">
        <img class="HCard-video" src="..." alt="">
        OR
        include the Picture component
      </div>
      <h3 class="HCard-title">...</h3>
    </a>
    <!-- !ALWAYS! put other text inside this wrapper. Otherwise elements
        outside it won't be in line with the title vertically
      And ALWAYS put this wrapper right after the imgTitleWrap! If you put sth
      between them, everything will move to the left edge of the HCard
    -->
    <div class="HCard-textsWrap">
      <p class="HCard-subtitle">...</p>
      <p class="HCard-subtitle">...</p>
      <p class="HCard-footer">...</p>
    </div>
  </div>
 */

/** HCard with some meta info next to its title
  <div class="HCard HCard--imgL">
    <a class="HCard-imgTitleWrap" href="#" data-js-hover>
      <div class="HCard-imgBlock">
        <img class="HCard-img" src="..." alt="">
        OR
        include the Picture component
      </div>
      <div class="HCard-titleWrap">
        <h3 class="HCard-title">...</h3>
        <span class="HCard-meta"><i class="Icon Icon--comment">
          <sub class="Icon-subText">(37)</sub></i>
        </span>
      </div>
    </a>
    <div class="HCard-textsWrap">
      ...
    </div>
  </div>
 */
.HCard {
  @include clearfix; /* 1 */

  display: block;

  &::after {
    margin-bottom: - $_hcard-img-title-margin-bottom; /* 2 */
  }
}

.HCard--shaded {
  background: $gray-light;
}

/**
 * This HCard if for (mainly) using as a sole element inside a carousel item.
 * We make two assumptions: a) it will most likely be a blocklink, b) there
 * won't be any meta info (icons, date) next to the title - so we make it d:b
 * CAN HAVE INFOBLOCK CONTENT INSIDE!
 *
 * 1) Without tbl:f width:100% wouldn't constrain the table if its contents
 *    are too big. And we would need it for infoblocks. For that reason it's
 *    CRITICAL to remove the pseudos (otherwise they'll occupy space).
 * 2) This HCard doesn't need tricks of the usual one, so we remove the
 *    pseudos thus removing their marings as well.
 * 3) Supposing it's always used inside a Carousel or a Grid or something like
 *    that
 * 4) imgTitleWrap is not usually used in this card, but just in case
 * 5) No need for the title to be d:i as well, so setting a margin and stuff
 */
.HCard--carouselItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  table-layout: fixed; /* 1 */

  &:hover {
    background: $gray-light;
  }

  &::before,
  &::after {
    content: none; /* 1, 2 */
  }

  .HCard-imgTitleWrap { /* 4 */
    height: 100%;
    margin-top: 0;
    padding: 0;

    &::after {
      content: none; /* 2 */
    }
  }

  .HCard-imgBlock,
  .HCard-videoLink {
    flex: 0 0 auto;
    margin: 0;
  }

  .HCard-textsWrap {
    flex: 1 1 auto;
    margin: 0;
    padding-left: $_hcard-img-margin-left;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }
  }

  .HCard-title { /* 5 */
    display: block;
    margin-bottom: 0.25rem;
    font-size: $font-size-sm;
    line-height: 1.215;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .HCard-descr {
    color: $text-color;
    font-size: $font-size-s;
  }
}

/**
 * This HCard is also for inside a carousel item. But it's not one big link
 * and can have texts other than the title.
 * E.g.: Featured Products and Assistance (PGH, homepage)
 */
.HCard--carouselItemPlain {
  padding: 0.75rem;

  .HCard-title {
    font-size: $font-size-sm;
  }
}

/**
 * This one is when the image and the texts need to be centered vertically
 * towards each other.
 * E.g.: Police1 - Grants page.
 *
 * 1) Tricky pseudos aren't needed AND we don't want them to mess with flex
 * 2) HCard-imgTitleWrap IS NOT needed, not it's necessary. But just in case
 *    it is there, placing some styles
 * 3) When the image and the texts are on the same "line", this gives us
 *    a $_margin-left between them. When their width and screen size make
 *    them take multiple lines, we have $_margin-top as a vert spacing
 *    between them.
 * 4) Using padding on children for the sake of non-flex browsers: there,
 *    textsWrap's left margin would not have any effect.
 * 5) [Chrome, IE] Some browsers will make it too large if a fixed width/
 *    flex-basis are not specified. They are specified if we use some
 *    HCard--img<...>; but just in case we don't, applying this fix.
 */
.HCard--table {
  $_margin-top: 0.625rem;
  $_margin-left: 1.25rem;

  display: flex;
  margin-top: - $_margin-top; /* 3 */
  margin-left: - $_margin-left; /* 3 */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: none; /* 1 */
  }

  .HCard-imgTitleWrap { /* 2 */
    display: flex;
    flex: 1 0 100%;
    margin-top: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &::after {
      content: none; /* 1, 2 */
    }
  }

  .HCard-imgBlock,
  .HCard-videoLink {
    flex: 1 0 auto;
    width: 200px; /* 5 */
    margin: $_margin-top 0 0; /* 3 */
    padding-left: $_margin-left; /* 4 */
  }

  .HCard-img {
    vertical-align: middle;
    object-position: center;
  }

  .HCard-textsWrap {
    flex: 1 0 300px; /* 5 */
    margin: $_margin-top 0 0 0; /* 4 */
    padding-left: $_margin-left; /* 4 */
  }
}

/* Modifiers: Image/video size
 ========================================================================= */

// WARNING. If you plan to change any of the image sizes here (and in other
// modifiers), please adjust the preset for the Picture C# component as well.

.HCard--imgXXS {
  @include _hcard-img-variant(67px);
}

.HCard--imgXS {
  @include _hcard-img-variant(100px);
}

.HCard--imgS {
  @include _hcard-img-variant(110px);
}

.HCard--imgSM {
  @include _hcard-img-variant(130px);
}

.HCard--imgM {
  @include _hcard-img-variant(140px);
}

.HCard--imgL {
  @include _hcard-img-variant (200px);
}

.HCard--imgXXL {
  @include _hcard-img-variant (270px);
}

/* Other modifiers
 ========================================================================= */

/**
 * An HCard used to look like an article intro
 * The main difference for now is the heading (Rokkit 24px)
 *
 * 1) An extra title text above/below HCard-title (not part of <hx> tags)
 *    and usually smaller)
 *    E.g.: "Sponsored Content" in sponsored items (CROSS-705)
 *    TODO: mv to the root?
 * 2) Some extra stuff is needed to align it vertically with an image since
 *    this has smaller font size than the HCard-title, to which the default
 *    aligning tricks are tuned.
 * 3) Need to override this since HCard-title with Rokkitt has smaller lh, which
 *    would make HCard-imgTitleWrap's bigger lh override it plus add reflow on
 *    font switching.
 */
.HCard--pseudoArticle {
  .HCard-imgTitleWrap {
    line-height: $line-height-large-texts; /* 3 */
  }

  .HCard-title {
    @include font-family($font-family-alt);

    font-size: $font-size-ml;
  }

  .HCard-titleNote { /* 1 */
    @include font-family($font-family-alt);

    text-transform: uppercase;
    font-size: $font-size-base;

    &:first-child {
      margin-top: 0.1em; /* 2 */
    }
  }
}

/**
 * An HCard without a hacks to align the image's and the title's top edges.
 * Used in HCards with no img/video, or when the shift is not needed (like
 * when there is no title, and HCard-textsWrap starts with non-text element)
 * E.g.: Columnist infor (PGH, Columnist page)
 *
 * 1) No need to align a title's top edge with an image (as there is none)
 * 2) Making the img block preserve space while the image is still loading
 *    (or if it never loads), to avoid the text to the left jumping suddenly
 *    when it loads. In usual HCards, HCard-imgTitleWrap's pseudo does this.
 *    If you DON'T NEED the image at all, don't add these emelents
 */
.HCard--noShifts {
  &::after {
    margin-bottom: 0;
  }

  .HCard-imgTitleWrap {
    margin-top: 0; /* 1 */

    &::after {
      content: none;
    }
  }

  .HCard-imgBlock,
  .HCard-videoLink {
    min-height: 1px; /* 2 */
    margin-top: 0; /* 1 */
  }

  .HCard-textsWrap {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * In this one the texts do float around the image
 * E.g.: Featured expert (PHG homepage)
 */
.HCard--float {
  .HCard-textsWrap {
    overflow: visible;
  }
}

/**
 * Usually an HCard is a block with very little text, so floating that text
 * would not be optimal even on smaller screens. But in case of an HCard as a
 * content widget (see CROSS-2131) there could be a lot of text, so the empty
 * space could start popping up rather quickly (on rather big mobile screens).
 * For such cases I decided not to play around with flexible settings on `--img`
 * modifiers, but instead just to use a global rule for starting the floating.
 * And HCards with bigger images should just collapse even before reaching this
 * breakpoint.
 */
.HCard--floatMobile {
  .HCard-textsWrap {
    @media (max-width: $screen-mobile-xxl) {
      overflow: visible;
    }
  }
}

/**
 * The card with as little vert margins as possible
 * E.g. PC companies, Product category page (unsponsored)
 */
.HCard--dense {
  .HCard-imgTitleWrap,
  .HCard-titleWrap,
  .HCard-title,
  .HCard-subtitle,
  .HCard-descr,
  .HCard-copyright,
  .HCard-footer {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Elements: containers
 ========================================================================= */

/**
 * A wrapper for an image, title and meta
 * Has a purpose of accomodating those inside a link (see CROSS-128)
 * ! Uses a tricky pseudo to make the texts that go after it be to the right
 * of the img/video without adding a left margin to them. The actual width is
 * specified in HCard's image/video size modifiers above.
 * We use this trick instead of just margin-right = img width + img margin, so
 * that the image's width could be fluid (in %) AND with min/max values, which
 * impossible for margin to have
 *
 * 1) Not clearing .Card-imgBlock's float so that the following text starts
 *    right after the title (that is in the same blocklink as .Card-imgBlock)
 * 2) We want top edges of a title and an image be in line. But we can't give
 *    HCard-title negative mt since it would shift the WHOLE BLOCK up. So we
 *    give the negative margin to this wrapper, and later shift an
 *    image/video by the same value.
 *    BE CAREFUL if a title has significantly different fz.
 * 3) Specifying line-height here as well since a title and meta have d:i
 * 4) A pseudo is floated just like the img/video and has the same width (see
 *    Image/video size modifiers above), so that the texts are exactly in line
 *    with the title vertically
 * 5) Clearing the img/video's float so that the pseudo doesn't end up to the
 *    right of the img/video
 * 6) It needs at least SOME height otherwise it won't take the needed
 *    horizontal space.
 * 7) This pseudo needs to be on the same vert level as the textsWrap for the
 *    latter to get shifted to the right. So we move it down by the bottom
 *    margin of the element - just where the textsWrap would start
 */
.HCard-imgTitleWrap { /* 1 */
  display: block;
  margin: -0.25rem 0 $_hcard-img-title-margin-bottom; /* 2 */
  line-height: $line-height-general; /* 3 */

  &:last-child {
    margin-bottom: 0;
  }

  &::after { /* 4 */
    display: block;
    float: left;
    clear: both; /* 5 */
    height: 1px; /* 6 */
    margin-top: $_hcard-img-title-margin-bottom; /* 7 */
    margin-right: $_hcard-img-margin-left;
    content: "";
  }
}

/**
 * A container for HCard's texts other than the title and meta info.
 *
 * 1) This el is on the same level as imgTitleWrap's floated pseudo and needs
 *    to be to the right of it.
 * 2) imgTitleWrap's floated pseudo creates additional bottom gap, which is
 *    compensated by HCard's :after negative margin bottom. And in case these
 *    texts bottom edge is below the imgTitleWrap's pseudo's bottom edge, it
 *    needs the same margin so that HCard's :after negative mb didn't create
 *    unnecessary shift
 */
.HCard-textsWrap {
  display: block;
  overflow: hidden; /* 1 */
  min-width: 40%;
  margin-bottom: $_hcard-img-title-margin-bottom; /* 2 */
}

/* Elements: Image/video
 ========================================================================= */

/**
 * Containers for an image and a video respectively.
 * Floated to the left (which is the main difference between .HCard and .Card)
 *
 * 1) Top margin - to compensate for negative mt of imgTitleWrap
 * 2) If an img is taller than a title element and has a mb, it is added to
 *    overall visible bottom gap of a card (since it clears an img's float).
 *    To avoid this we don't use mb here; imgTitleWrap's mb seems to do good
 *    job separating an image from the following text.
 * 3) To avoid unnecessary gaps/line-height
 */
.HCard-imgBlock,
.HCard-videoLink {
  display: block;
  float: left;
  box-sizing: border-box;
  width: 100px;
  margin: 0.25rem $_hcard-img-margin-left 0 0; /* 1, 2 */
  text-align: center;
  font-size: 0; /* 3 */
}

/**
 * A container for an image. Could be a link or a block-level element.
 */
// .HCard-imgBlock {}

/**
 * The actual image
 *
 * 1) An image that is marked up in any of HTML5 responsive images markup
 *    variants (picture/img[scrset]) is distributed over physical pixels, not
 *    CSS pixels. So, unless it has (min)width/height, if an image is not wide
 *    enough to fill the <spot width>x<screen DPR>, it will become smaller
 *    then expected. It includes images that are not wide enough, but are
 *    _tall enough_ (i.e, a narrow portret-oriented image).
 *    To avoid that we are settigh the width for the responsive images in JS.
 *    `object-fit` is to preserve visual aspect ratio for cases when the
 *    max-height would make an image's proportions distorted. Using `contain`
 *    because `scale-down`, while doesn't add upscaling, would still make
 *    small images shrink on high-dpr screens.
 *    As of now, `max-height` is set in the mixin for HCard image size
 *    modifiers.
 */
.HCard-img {
  max-width: 100%;
  object-fit: contain; /* 1 */
  object-position: top;
}

/**
 * A container for a video thumb. Could be a link or a block-level element.
 * Also floated to the left (the main difference between .HCard and .Card)
 *
 * 1) This one will set the container height proportional to its width
 * 2) Setting the proportion
 * 3) Adding a "play" icon
 * 4) ?? (from the prev. version of the styles)
 */
.HCard-videoLink {
  @include video-button(30px); /* 3 */

  position: relative;
  overflow: hidden;
  background: $black;
  transform-style: preserve-3d; /* 4 */

  &::before { /* 1 */
    display: block;
    width: 100%;
    padding-top: 56.25%; /* 2 */
    content: "";
  }
}

/**
 * A video (most likely a thumbnail) inside a videoLink
 * Absolutely positioned since the container maintains proportions with
 * a pseudo element. And centered.
 *
 * 1) An image that is marked up with any of HTML5 responsive markup options
 *    is distributed over physical pixels (i.e. not upscaled). So if an img
 *    is not wide enough for a high-dpr screen's `CSS width x DPR`, it will
 *    be smaller than it is expected to be.
 *    The only way to solve this is to set a fixed width/bottom limit. The
 *    img will not look as sharp on a high-dpr screen, but it won't shrink,
 *    and it will at least be not worse a usual 1x image (in many cases -
 *    better).
 */
.HCard-video {
  @include center-absolute;

  width: 100%; /* 1 */
}

/* Elements: title, meta info
 ========================================================================= */

/**
 * A wrapper for a title and meta info.
 * Had to introduce another element because a title + meta info should both
 * respect an img's float (not wrap if taller than the img) AND be inline, so
 * giving them ov:h and left margin wouldn't work.
 *
 * 1) Needs to be on the same line with meta (woks in conjunction with
 *    .HCard-imgTitleWrap being inline)
 * 2) This all could be inside a link, so we need the ability to inherit text
 *    color
 */
.HCard-titleWrap {
  display: block;
  overflow: hidden;
  min-width: 40%;
  margin: 0;

  .HCard-title {
    display: inline; /* 1 */
    overflow: visible;
    margin: 0;
  }

  .HCard-subtitle {
    color: inherit; /* 2 */
  }
}

/**
 * ... another wrapper in case we need multiple block level elements in the
 * title area:
 * - If it's not just one title, we must wrap it in .HCard-titleWrap
 * - ... which means HCard-title will have display:inline
 * - So HCard-titleBlock will be responsible for the bottom margin
 */
/** Example markup:
  <div class="HCard HCard--pseudoArticle">
    <a class="HCard-imgTitleWrap" href="#" data-js-hover>
      <div class="HCard-imgBlock">
        ... include a Picture component ...
      </div>
      <div class="HCard-titleWrap">
        <div class="HCard-titleBlock">
          <h3 class="HCard-title"> ... a title text ... </h3>
        </div>
        <p class="HCard-subtitle"> ... a subtitle text ... </p>
      </div>
    </a>
    <div class="HCard-textsWrap"> ... </div>
  </div>
 */
.HCard-titleBlock {
  margin-bottom: 0.1875rem;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * A title, the one inside a common link (with an image).
 * 1) So that its text wouldn't float under an image if the title ends up
 *    taller than that image.
 * 2) This might be on h2-h4 etc, so overriding their styles.
 */
.HCard-title {
  @include font-family; /* 2 */

  display: block; /* 1 */
  overflow: hidden; /* 1 */
  min-width: 40%;
  margin: 0;
  text-align: inherit; /* 2 */
  text-transform: none; /* 2 */
  color: inherit;
  font-size: 1rem; /* 2 */
  font-weight: 400; /* 2 */
}

/**
 * Meta info next to the title (date, icons)
 *
 * 1) Needs to be on the same line with title (woks in conjunction with
 *    .HCard-imgTitleWrap being inline). d:ib won't work because it will force
 *    the element to a new line if it doesn't fit the remaining witdh on the
 *    current one
 * 2) HCard-meta is supposed to have a left margin ALWAYS. That's because
 *    :first-child rule will work even if there is text before an element. If
 *    you don't need that margin (e.g., if it's truly the 1st or the only
 *    child), just use u-noMargin.
 */
.HCard-meta {
  display: inline; /* 1 */
  margin: 0 0 0 0.4em; /* 2 */
}

/**
 * Generic element for date, location, some count.
 */
.HCard-metaItem {
  @include _hcard-meta-item;

  display: inline-block;
  color: $gray-medium; // TODO: add a dedicated variable for such texts?
}

/**
 * Additional text (or other) element inside HCard-meta. Differs from metaItem
 * visually by its color.
 * E.g.: "– End of Service" black text in "LODD archive" list
 *
 * 1) Resetting link behavior
 */
.HCard-metaSpan {
  @include _hcard-meta-item;

  display: inline-block; /* 1 */
  color: $text-color; /* 1 */
}

/* Other texts
 ========================================================================= */

.HCard-subtitle {
  display: block;
  margin-bottom: 1rem;
  color: $text-color;
  font-size: $font-size-base;
  line-height: $line-height-general;

  &:last-child {
    margin-bottom: 0;
  }
}

.HCard-descr {
  display: block;
  margin-bottom: 0.375rem;
  color: $text-color;
  font-size: $font-size-sm;
  line-height: $line-height-general;

  &:last-child {
    margin-bottom: 0;
  }
}

.HCard-copyright {
  display: block;
  margin-bottom: 0.375rem;
  color: $text-color;
  font-size: $font-size-s;
  line-height: $line-height-general;

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Usually a holder for a "more" link, a button, a socnet widget, etc.
 * Most likely the last element in an HCard, but not necessarily
 *
 * 1) This one is an EXCEPTION to our "bottom margins only" convention, as
 *    it must have some bigger (~10px) top margin irrespective of what goes
 *    before it. It's according to the Design, not for our convenience!
 */
.HCard-footer {
  display: block;
  margin-top: 0.625rem; /* 1 */
  margin-bottom: 0.625rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

/**
 * Common Media Sites variables
 *
 * All of them are either the same for all sites or overriden in corresponding
 * _variables.scss files. In case of the latter, Police1 defaults are taken
 *
 * "!default" is used in case this partial has to be imported after the sites'
 * partials (yet this would not be recommended).
 */

$non-responsive: false !default;
$is-debug: false !default;

/* Typography
========================================================================= */

// Backup font (if the main one can"t load). Also used in some places (form
// inputs)
$font-family-vanilla: Arial, sans-serif;
// The main font for most texts
$font-family-main: "Open Sans";
// The alt font (some headings, etc)
$font-family-alt: "Rokkitt";
// The font for Menu for now.
$font-family-header: "Montserrat";

$icon-font: "Fontello";

// TODO: use rem? so that all of these would change
// when the document's fz change
$font-size-xs: 10px;
$font-size-xs2: 11px; // Lead form footer hint
$font-size-s: 12px;
$font-size-sm: 14px;
$font-size-base: 16px;
$font-size-m: 18px;
$font-size-m2: 20px;
$font-size-ml: 24px;
$font-size-l: 26px;
$font-size-xl: 30px;
$font-size-xl2: 32px; // The designer absolutely insisted on this fz to exist
$font-size-xxl: 34px;
$font-size-xxxl: 40px;

$font-size-giga: 50px;

// This is a universal value that can be applied to elements with different
// font sizes (usually up to 24px). TODO: remove lh variables with units above
$line-height-general: 1.375;
// lh for big font sizes (above 24px)
$line-height-large-texts: 1.082;

// In order to mitigate reflow caused by switching from a backup font to a main
// web font, we need to have some fine control over text sizes both before and
// after the switching. This set of maps is exactly for that. The top level has
// target fonts' names as keys. The second level includes the main (default)
// settings for when a web font has loaded, the settings for its backup font,
// as well as some extra config options in case we need to fine-tune for bold,
// caps, ect. The `font-family` is doing the switching in styles.
$fonts-settings: (
  "#{$font-family-main}": (
    "main": (
      "letter-spacing": 0,
    ),
    "backup": (
      "font-family": $font-family-vanilla,
      "letter-spacing": 0.019em,
    ),
    "caps": (
      "letter-spacing": -0.024em
    )
  ),
  // We use only the bold (600) font-weight for Rokkitt, so we can afford
  // hard-coding this here and the 400 fw for the backup Georgia for less tight
  // letter-spacing and thus better readability.
  "#{$font-family-alt}": (
    "main": (
      "letter-spacing": 0.013em,
      "font-weight": 600,
      "line-height": $line-height-large-texts
    ),
    "backup": (
      "font-family": Georgia,
      "letter-spacing": -0.005em,
      "font-weight": 400,
      "font-variant-numeric": lining-nums,
      "line-height": 1.082
    )
  ),
  "#{$font-family-header}": (
    "main": (
      "letter-spacing": 0,
    ),
    "backup": (
      "font-family": $font-family-vanilla,
      "letter-spacing": 0.04em,
    ),
    "caps": (
      "letter-spacing": 0.018em,
    )
  )
);

// The list of fonts that a project will be using
$fonts: (
  "Fontello": (
    "path": "../fonts/fontello/font/fontello",
  ),
);

/* Media query breakpoints (alternative)
========================================================================= */

// At some point we felt that we need much more breakpoints, but the
// $screen-<size> namig system can only cover a small amount of names. Below
// is the list of breakponts chosen like so: "I need a breakpoint around
// this size; what common mobile devices width/height correspond to it?"
// We can still fit something with -mobile-sm, ml, etc
// http://www.tactics.com/info/womens-sizing-charts :)

$screen-desktop-s: 1220px;

$screen-tablet-l: 1024px; // height: iPad 1-4, Mini
$screen-tablet-m: 800px; // width: Galaxy Tab4
$screen-tablet-s: 768px; // width: iPad 1-4, Mini
$screen-tablet-xs: 600px; // width: Nexus 7, Lenovo A5500

$screen-mobile-xxxl: 640px; // height (fullscreen): Galaxy S4, S7, Note3
$screen-mobile-xxl: 560px; // height (available): Galaxy S4, S7, Note3
// width: LG G5, Samsung Galaxy Note 5, One Plus 3 Portrait; heigth: iPhone 2-4
$screen-mobile-xl: 480px;
// These two are so close, so they should be interchangeable in a way like,
// for max-width use 414 (to also cover 411), for min-width use 411 (to also
// cover 414).
$screen-mobile-l: 414px; // width; iPhone 6+
// width; Nexus 6P, Nexus 5X, Google Pixel 1-3 (usual and XL) Portrait
$screen-mobile-l2: 411px;
$screen-mobile-m: 375px; // width; iPhone 6/6S
$screen-mobile-s: 360px; // width; Nexus 5, Samsung Galaxy
$screen-mobile-xs: 320px; // width; a bulk of older phones: iPhone 2-5, etc.

/* Spacings
 ========================================================================= */

// Bottom margin of various elements; 34px instead of 40px as the el itself or
// the el after it can be a text (and its half-leading would contribute to the
// gap)
$article-item-mbottom: 2.125rem;

// Bottom margin of text elements inside edited content blocks. We mean those
// elements that have usual text and usual line-height.
$content-el-mbottom: 1.375rem;

// Bottom margin used in .ContentAside by design and in .Content as a trick
// to nullify .ContentAside child's bottom margin. The other two can be
// overwritten on the sites (e.g., on Military)
$content-aside-mtop: 1rem;
$content-aside-mside: 2.5rem;
$content-aside-mbottom: 1.5rem;

/* Generic colors Colors
 ======================================================================= */

// Generic colors. Colors that are universal across the sites, but might not
// be used in the same places on all sites.

$white: #fff !default;
$black: #000 !default;
$red: #aa1801 !default;

$color-gold: #ffd200;
$color-green-vogue: #02234c; // wtf is with this name :D
$color-dusty-gray: #9a9a9a;

// Don't use these except for a corr. social network icons, buttons, etc.
$color-facebook: #4267b2;

/* Theme colors
 ======================================================================= */

// Grays. Differ from the Style Guides, Approved here:
// https://praetoriangroup.atlassian.net/browse/PGH-228
$gray-dark: #404040;
$gray-medium: #878787;
$gray-light: #efefef;

$color-warn: #cc001e;

$blue-dark: #001226 !default;
$blue-link: #1174c9 !default;
$blue-header: #02234c !default;
$blue-header-hover: #014380 !default;
$blue-header-btn: #168cf2 !default;
$blue-3: #01234a !default;
$blue-4: #1b357b !default;
$blue-5: #1e5ba1 !default;
$blue-8: #024788 !default;
$blue-10: #002859 !default;
$blue-11: #4283c5 !default;
$blue-15: #184a85 !default;
$blue-16: #4173ae !default;

$yellow: #ffd200 !default;

$red-bright-hover: #ca2027 !default;

// Specific colors

// Global color for gray borders, hr-s, etc.
$border-color: $gray-medium !default;

$blue-border: #1d73ba !default;

$red-btn: #901200 !default;
$red-btn-hover: #cb0303 !default;
$red-link: #aa1801 !default;

$image-bg: $blue-header !default;

// Global text color on `<body>`
$text-color: $black;
// Global textual link color.
$link-color: $blue-link !default;
$link-hover-color: $red-link;

// Special links (like .Link; .List--inline .List-link, etc).
$link-special-color: $red-link;
$link-special-hover-color: $red-link;

// Darker links (E.g., counters in Top-5)
$link-dark-color: $blue-header-hover;

$article-form-error-color: $red-bright-hover;

/* Components
 ========================================================================= */
// The general convention is to place here only variables that are used across
// multiple components AND/OR are to be overridden by the other sites' themes

// buttons
$btn-bg: $link-hover-color;
$btn-bg-hover: $red-btn-hover;
$btn-icon-bg: $red-btn;
$btn-icon-bg-hover: null;

// Box
$box-themed-bg: $blue-header;
$box-themed-color: $white;

// Icon
$icon-highlight-color: #83c6ff;

// Heading
$heading-sm-color: $blue-header !default;

// Pagination
$pagination-bg-active: $link-color !default;
$pagination-border-active: $blue-header-hover !default;

// TitleBar
$titlebar-bg: $blue-header;
$titlebar-icon-bg: $link-color;
$titlebar-hover-bg: $blue-header-hover;
$titlebar-hover-icon-bg: $blue-header-btn;
$titlebar-is-include-badge-styles: true;
$titlebar-inpublicsafety-icon-bg: $blue-border;
$titlebar-inpublicsafety-hover-icon-bg: $blue-header-btn;
$titlebar-quietwarriors-icon-bg: #e75524;
$titlebar-quietwarriors-hover-icon-bg: darken($titlebar-quietwarriors-icon-bg, 15%);

// "Editor"s note"
$callout-ribbon-bg: $blue-header;
$callout-ribbon-back-bg: $blue-header-hover;

// Modal
$modal-bg: $blue-header;
$modal-icon-bg: $link-color;
$modal-hover-icon-bg: $blue-header-btn;
// -warning- variables are used on the Modal-title--warning
$modal-warning-bg: $red-btn;
$modal-warning-icon-bg: $red-bright-hover;
$modal-warning-hover-icon-bg: $red-link;

// Table
// <th>
$table-head-bg: $blue-11;
$table-head-color: $white;
// <tr>
// Initially these colors are for highlighted/special rows. Now, after the
// styling for those special rows changed to gray bg, these vars are used on
// them only in the mobile mode.
$table-row-highlight-bg: $blue-15;
$table-row-highlight-color: $white;

// 500.html
$page500-h1-color: #183989;

// Owl Carousel
$owl-carousel-side-margin: 1.875rem;

// Form
$form-focus-color: #66afe9;
$form-themed-color: $white;
$form-themed-bg: $blue-header-btn;
$form-themed-text-dedicated-color: inherit;
$form-themed-text-special-color: inherit;
$form-themed-input-borders: $blue-header;
$form-subscription-bg: $blue-header !default;

// Cub Widget
$cub-width: 520px;
$cub-font-size: $font-size-sm;
$cub-vr-verified-color: #3c763d;
$cub-form-background-color: $gray-light;
$cub-msg-color: $white;
$cub-info-bg: $gray-medium;
$cub-error-bg: $red-bright-hover;
$cub-heading-bg: $blue-10;
$cub-menu-color: $white;
$cub-menu-hover-color: $yellow;
$cub-menu-bg: $blue-link;
$cub-control-text-color: $black;
$cub-error-color: $link-hover-color;
$cub-error-color-top: $red;

// Slider
$slider-img-height-proportion: 0.88;
$slider-img-height-proportion-wide: 0.5625;

// Footer
$footer-top-bg: $blue-3 !default;
$footer-top-line-color: null !default;
$footer-top-color: inherit;
$footer-bottom-bg: $blue-4 !default;
$footer-logo-width: 230px !default;

$footer-color: #b5c8fe !default;
$footer-hover-color: $yellow !default;

$footer-menu-color: #bbccf9 !default;
$footer-menu-hover-color: $yellow !default;
$footer-menu-active-weight: 400 !default; // only used on EDUGH
$footer-menu-margin-lg: 20px !default;
$footer-menu-border-sm: #4875ae !default;
$footer-menu-bg-sm: $blue-8 !default;

$footer-menu-color-mobile: $footer-menu-color !default;
$footer-menu-active-color-mobile: $footer-menu-hover-color !default;
$footer-menu-hover-color-mobile: $footer-menu-hover-color !default;

// Header New
$header-login-max-width: 190px !default;

$header-new-bg: #12244b !default;
$header-new-top-ad-bg: $blue-3 !default;
$header-top-min-height: 2.5rem !default;
$header-new-top-link-color: $white !default;
$header-new-top-link-hover-color: $yellow !default;
$header-new-usermenu-bg: $blue-border !default;
$header-new-cub-menu-hover-color: $yellow;
$header-new-cub-menu-border: transparent;

$header-logo-width: 257px !default;
$header-logo-height: 5rem !default;
$header-new-logo-bg: $blue-border !default;

$header-rebrand-logo-width: 138px !default;

$header-new-bottom-bg-theme-image: url("../images/backgrounds/header-bg-image.jpg") !default;
// An image variant for mobile devices, when the fill image is not visible
// Two reasons: 1) to remove the fill pattern from this img, 2) to have a
// smaller version of this image for smaller screens. Equals the desktop image
// by default to avoid extra http calls on screen resize
$header-new-bottom-bg-theme-image-mobile: $header-new-bottom-bg-theme-image !default;
$header-new-bottom-bg-theme-image-pos: 100% 0;
// Some header images are not semi-transparent in the design and they impact
// the visibility of other elements when overlapped. So for screen sizes when
// that is possible we'll have such images semi-transparent
$header-new-bottom-bg-theme-image-opacity-option: 1 !default;
$header-new-bottom-bg-fill-image: none !default;
$header-new-bottom-bg-fill-pos: 100% 0 !default;

$header-scroll-height: $header-logo-height + $header-top-min-height;

// News Line
$news-line-color: $black !default;

// Main Menu
$menu-new-item-color: $white !default;
$menu-new-item-color-mobile: $menu-new-item-color !default;
$menu-new-item-hover-color: $yellow !default;

$menu-submenus-bg: $blue-3 !default;
$menu-submenu-bg: $blue-5 !default;
$menu-submenu-border-color: $blue-5 !default;

$menu-submenu-link-l1-hover-bg: $blue-5 !default;
$menu-submenu-link-l2-color: $black !default;
$menu-submenu-link-l2-hover-bg: $yellow !default;

// Main Menu for Tablet Screens
$header-menu-toggle-bg: $blue-link !default;
$header-menu-toggle-active-color: #231f20 !default;
$header-menu-toggle-active-bg: $yellow !default;

$menu-submenu-bg-tablet: $blue-15 !default;
$menu-submenu-brd-color-tablet: $blue-16 !default;

$menu-item-bg-tablet-hover: $blue-5 !default;
$menu-item-brd-color-tablet: $blue-dark !default;

// Headline
// 1) Special Report, Member Update
$headline-bg: $blue-link;
$headline-title-bg: $blue-header-hover;
$headline-btn-bg-hover: $blue-header;

// 2) Breaking News
$headline-bg-danger: $red-bright-hover;
$headline-title-bg-danger: $red-link;
$headline-btn-bg-hover-danger: $red-btn;

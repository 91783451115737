/**
 * Base, core rules / resets
 */

*,
*::after,
*::before {
  box-sizing: border-box;
}

/**
 * Using such rule because: a) we do want to remove bottom margin from every
 * last child of every element; b) ul:last-child would create specificity of
 * 101, to fight which we'll have to use kludges in component classes
 */
:last-child {
  margin-bottom: 0;
}

/**
 * 1) A base for calculating all the font-sizes and other values in rem/em.
 *    Subject to change on screens of different sizes (on media breakpoints).
 */
html {
  height: 100%;
  color: $text-color;
  font-size: $font-size-base; /* 1 */
  line-height: $line-height-general;
  scroll-padding-top: $header-scroll-height * 1.1; /* 2 */

  // The same media query as in .Banner--sticky!
  @media (max-width: $screen-tablet-l), (max-height: $screen-tablet-s) {
    scroll-padding-top: 0;
  }
}

/**
 * 1) Setting the font here instead of on html in order to keep the Font
 *    observing classes inside a mixin (one place to edit instead of multiple).
 * 2) The footer's bottom edge should not be above the screen's bottom edge.
 *    For that we expand the <body> and stretch the content
 * 3) [Chrome 56+] ScrollAnchoring breaks autotriggering "Load more" (scroll
 *    position is moved automatically (multiple times!) on pressing Back when
 *    popstate event fires, making undesired loading of items.
 *    https://github.com/WICG/ScrollAnchoring/blob/master/explainer.md
 *    TODO: maybe reconsider the auto Load more behavior
 * 4) [Firefox] Otherwise it would print only the 1st page.
 * 5) [IE] Containers with `d:f; fxd:c` won't calculate their flexed childrens'
 *    sizes if the container has `mih` but no explicit `height`
 *    https://github.com/philipwalton/flexbugs#flexbug-3
 */
body {
  @include font-family; /* 1 */

  display: flex; /* 2 */
  flex-direction: column; /* 2 */
  min-width: $screen-mobile-xs;
  min-height: 100%; /* 2 */
  margin: 0;
  overflow-anchor: none; /* 3 */

  @media print {
    display: block; /* 4 */
    background: $white;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: 100%; /* 5 */
  }
}

/**
 * Styles for printed pages
 *
 * 1) Copied from the old styles
 */
@page {
  margin: 0.5cm; /* 1 */
}

/* Block-level text elements
 ========================================================================= */

h1,
h2,
h3,
h4,
h5,
h6 {
  @include mac-font-smoothing; /* 1 */
  @include font-family;

  display: block;
  margin: 0 0 $content-el-mbottom;
  font-weight: 700;
  line-height: $line-height-general;

  @media print {
    break-inside: avoid;
  }
}

h1 {
  font-size: $font-size-xxxl;
}

h2 {
  @include font-family($caps: true);

  color: $heading-sm-color;
  font-size: $font-size-m;
}

h3 {
  @include font-family($caps: true);

  font-size: $font-size-base;
}

h4,
h5,
h6 {
  text-transform: none;
  font-size: $font-size-sm;
}

p {
  margin: 0 0 1em;
}

/**
 * The default blockquote
 * Unfortunately we can't center the text vertically if it's shorter than the
 * "qoute" symbol: both flex and CSS table needs the text wrapped in something
 * for that. And since the quotes will be created in and html editor, such a
 * structure would be too fragile.
 *
 * 1) A magic number - it is to be at least tall as the qoute symbol's height
 * 2) The "quote" icon at the left
 * 3) Otherwise it's too bold on Mac
 * 4) So that it doesn't overlap with floats (Related aside, images)
 * 5) Making the quote and the left spacing small early to avoid the
 *    blockquote from shrinking too much when beside a float, like here:
 *    https://firerescue1.com/opinion/articles/134919018/
 */
blockquote {
  position: relative;
  display: block;
  overflow: hidden; /* 4 */
  min-height: 5rem; /* 2 */
  margin: 0 0 $content-el-mbottom 4rem;
  padding: 0 0 0 6rem;

  @media (max-width: $screen-desktop-s) { /* 5 */
    min-height: 2.75rem; /* 1 */
    margin-left: 2rem;
    padding-left: 4rem;
  }

  @media (max-width: $screen-mobile-xl) {
    margin-left: 1rem;
    padding-left: 3rem;
  }

  &::before { /* 2 */
    @include mac-font-smoothing; /* 3 */

    position: absolute;
    top: 0;
    left: 0;
    content: "\f10d";  // .Icon--quote-left
    color: $gray-light;
    font-family: $icon-font;
    font-size: 5rem;
    line-height: 1;

    @media (max-width: $screen-desktop-s) { /* 5 */
      font-size: 3rem;
    }

    @media (max-width: $screen-mobile-xl) {
      font-size: 2rem;
    }
  }
}

/**
 * 1) So that it doesn't overlap with floats (Related aside, images)
 */
cite {
  position: relative;
  display: block;
  overflow: hidden; /* 1 */
  margin: 0 0 $content-el-mbottom;
  padding: 0 0 0 1.25rem;
  text-transform: uppercase;
  font-style: italic;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "—";
    font-weight: 700;
  }
}

/**
 * 1) So that it doesn't overlap with floats (Related aside, images)
 */
code {
  display: block;
  overflow: hidden; /* 1 */
  margin: 0 0 1.25rem;
  padding: 2rem;
  border-left: 0.5rem solid $gray-medium;
  background: $gray-light;
  font-family: monospace;
  font-size: $font-size-m;

  @media (max-width: $screen-tablet-s) {
    padding: 1rem;
  }

  @media print {
    border-top: 1px solid $gray-medium;
    border-right: 1px solid $gray-medium;
    border-bottom: 1px solid $gray-medium;
    background: none;
  }
}

address {
  margin: 0 0 1rem;
  font-style: normal;
}

/**
 * Lists
 *
 * 1) Results in 26px, as per the Style Guide (considering the default lh)
 * 2) If we create a sublist inside a list in CKeditor, it won't wrap the text
 *    of the <li> that is becoming a host to such sublist in a <p>. Thus no
 *    spacing between the text and the sublist. Solving that. And any
 *    component that uses ul/ol markup overrides that top margin anyway.
 */
ul,
ol {
  margin: 0 0 1.25rem; /* 1 */

  & & { /* 2 */
    margin-top: 1.25rem;
  }
}

ul {
  padding: 0;
  list-style-type: none;

  ul {
    padding-left: 2.25em;
    list-style-type: initial;

    ul {
      list-style-type: circle;
    }

    > li {
      padding: 0;

      &::before {
        content: none;
      }
    }
  }
}

/**
 * The default ordered list
 *
 * 1) Reset the hack for printing background from a generic <li>
 * 2) The editors require nested numbered lists to have different counters,
 *    so we have to have this level of nesting here.
 */
ol {
  padding: 0;
  list-style: none;
  counter-reset: ol;

  > li {
    padding-left: 2.25em;
    counter-increment: ol;

    &::before {
      @include mac-font-smoothing;
      @include font-family($font: $font-family-alt, $line-height: 1);

      top: 0;
      left: 0;
      width: 1.5em;
      height: auto;
      content: counter(ol) ".";
      text-align: center;
      background: transparent;
      font-size: $font-size-ml;

      @media print {
        border: none; /* 1 */
      }
    }
  }

  ol {
    counter-reset: ol-inner;

    > li {
      counter-increment: ol-inner;

      &::before {
        content: counter(ol-inner, lower-alpha) ".";
      }
    }

    ol {
      counter-reset: ol-inner-roman;

      // The editors require these nesting
      // stylelint-disable-next-line selector-max-compound-selectors
      > li {
        counter-increment: ol-inner-roman;

        // stylelint-disable-next-line max-line-length
        // stylelint-disable-next-line selector-max-compound-selectors, max-nesting-depth
        &::before {
          content: counter(ol-inner-roman, lower-roman) ".";
        }
      }
    }
  }
}

/**
 * The default list item
 *
 * 1) Browsers may not print the element's background depending on the
 *    settings. So we emulate the bullet's bg with border.
 */
li {
  position: relative;
  margin: 0 0 1.25rem;
  padding: 0 0 0 1rem;

  &::before {
    position: absolute;
    top: 0.5rem;
    left: 0;
    display: block;
    width: 7px;
    height: 6px;
    content: "";
    background: $gray-dark;

    @media print {
      border: 3px solid $gray-dark; /* 1 */
      background: none;
    }
  }
}

fieldset {
  margin: 0 1rem;
  padding: 1.25rem;
  border: none;
}

/* Inline elements
 ========================================================================= */

/**
 * Overriding Normalize. We don't want relative font-weight
 */
b,
strong {
  font-weight: bold;
}

a {
  @include hover {
    text-decoration: underline;
    color: $link-hover-color;
  }

  transition: color 0.3s ease 0s;
  text-decoration: none;
  color: $link-color;
}

button {
  // Resetting normalize
  @include font-family;
}

/* Objects, replaced els
 ========================================================================= */

hr {
  border: 1px solid $border-color;
  border-width: 1px 0 0;
}

iframe {
  border: none;

  @media print {
    break-inside: avoid;
  }
}

/**
 * 1) To help lazysizes load images for which `getBoundingClientRect()` would
 *    otherwise return zero dimensions (i.e., if the images fz is calculated
 *    to zero) https://github.com/aFarkas/lazysizes/issues/483
 */
img {
  min-width: 1px; /* 1 */
  max-width: 100%;
  min-height: 1px; /* 1 */
  border: none;

  @media print {
    break-inside: avoid;
  }
}

/* Tables
 ========================================================================= */

/**
 * Default table layout
 *
 * 1) To override possible attribute values added by CKEditor or manually in
 *    the source mode (width, height, align). !important - to override those
 *    in a form of inline styles
 *    TODO: probably later on we set up filtering in CKEditor, and the !
 *    will not be needed anymore.
 * 2) Decided to not use `fixed` since in most cases rows don't need to be
 *    sized evenly.
 */
table {
  float: none; /* 1 */
  width: 100% !important; /* 1 */
  height: auto !important; /* 1 */
  table-layout: auto; /* 2 */
  border-spacing: 0;
  text-align: left;
  vertical-align: top;
  border: none;
  border-collapse: collapse;
}

caption {
  @include titlebar-basis;

  margin: 0;
  border-bottom: 1px solid $white;
}

th,
td {
  padding: 0.5rem 0.625rem;
  text-align: left;
  vertical-align: top;
  border: none;
  border-width: 0 0 1px; /* 2 */
}

th {
  color: $white;
  background: $table-head-bg;
  font-weight: 700;
}

/**
 * Forms
 * 1) Define focused state of controls.
 * 2) Use important to override more specific selectors (e.g. one-level classes)
 */
input,
textarea,
select {
  &:focus { /* 1 */
    border-color: $form-focus-color; /* 2 */
    outline: none !important; /* 2 */
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px lighten($form-focus-color, 0.6); /* 2 */
  }
}

button {
  &:focus { /* 1 */
    box-shadow: none !important; /* 2 */
  }
}

@font-face {
  font-family: "Montserrat";
  src:
    url("/static/latest/base/fonts/Montserrat/font/montserrat-v14-latin-regular.woff2?v=2") format("woff2"),
    url("/static/latest/base/fonts/Montserrat/font/montserrat-v14-latin-regular.woff?v=2") format("woff"),
    url("/static/latest/base/fonts/Montserrat/font/montserrat-v14-latin-regular.ttf?v=2") format("truetype");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


@font-face {
  font-family: "Montserrat";
  src:
    url("/static/latest/base/fonts/Montserrat/font/montserrat-v14-latin-500.woff2?v=2") format("woff2"),
    url("/static/latest/base/fonts/Montserrat/font/montserrat-v14-latin-500.woff?v=2") format("woff"),
    url("/static/latest/base/fonts/Montserrat/font/montserrat-v14-latin-500.ttf?v=2") format("truetype");
  font-weight: 500;
  font-style: normal;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

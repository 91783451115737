/**
 * @define CubWidgetOption
 * 1) TODO: remove !important after refactoring (.article-template *)
 */

.cub-Option-firstLine {
  font-weight: 600 !important; // *1
}

.cub-Option-secondLine {
  font-size: 0.8rem !important; // *1
}

/**
 * @define utilities
 *
 * Animation util classes that are universal, i.e. not tied to a certaint
 * component/element and thus can be used with different components/els
 */

/**
 * Fade in with medium speed. Used on records that are loaded with Load more
 */
$_anim-time: 0.5s;

.u-fadeInM {
  animation: fadeIn $_anim-time ease 0s both;
}

.u-fadeInMToTop {
  animation:
    fadeIn $_anim-time ease 0s both,
    toTop $_anim-time ease 0s both;
}

/** @define CubWidgetTOS */

/**
 * TOS styles for cub widget
 */

.cub-TermsOfService {
  .cub-Caption--large {
    @include font-family($font: $font-family-alt, $line-height: 1);

    margin: 0 0 1.2rem;
    padding: 0;
    color: $black;
    border: none;
    background: none;
    font-size: $font-size-xxxl;

    a {
      font-family: inherit;
    }
  }

  .cub-Caption--medium {
    @include font-family($font-family-alt);

    display: block;
    margin: 0 0 1rem;
    padding: 0;
    text-transform: none;
    color: $gray-dark;
    border: none;
    background: none;
    font-size: $font-size-ml;

    &::after {
      content: none;
    }
  }
}

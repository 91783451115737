/**
 * @define Headline
 *
 * The headline/breaking news line at the top of the page content highlighted
 * with a background
 */

/**
 * The main component. Inside it you can put a banner, a JS call, etc.
 */
.Headline {
  position: relative;
  overflow: hidden;
  margin: 0 0 1rem;
  color: $white;
  background: $headline-bg;
  font-size: $font-size-base;
  font-weight: 400;

  &:hover {
    text-decoration: none;
  }
}

.Headline-content {
  display: table;
  color: inherit;

  @media (max-width: $screen-tablet-s) {
    display: block;
  }
}

.Headline-title,
.Headline-texts {
  display: table-cell;
  padding: 0.8125rem 1.75rem; // 6px 28px
  vertical-align: middle;
  color: $white;

  @media (max-width: $screen-tablet-s) {
    display: block;
    padding: 0.4375rem 0.5625rem; // 7px 9px
  }
}

.Headline-title {
  @include mac-font-smoothing;
  @include font-family($font: $font-family-alt, $line-height: 1);

  margin: 0;
  white-space: nowrap;
  text-transform: capitalize;
  background: $headline-title-bg;
  font-size: $font-size-xxl;

  @media (max-width: $screen-tablet-s) {
    font-size: $font-size-m;
  }
}

.Headline-subTitle {
  @include mac-font-smoothing;

  margin-bottom: 0;
  color: inherit;
  font-size: $font-size-m2;
  font-weight: 700;

  @if variable-exists(headline-text-color) {
    color: $headline-text-color;
  }

  @media (max-width: $screen-tablet-s) {
    font-size: $font-size-sm;
  }
}

.Headline-link {
  &,
  &:hover {
    color: inherit !important;
  }
}

.Headline-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  transition: background-color 0.5s ease;
  text-align: center;
  color: inherit;
  background: $headline-title-bg;
  font-family: $icon-font;
  font-size: $font-size-s;
  line-height: 0.5625; // 9px

  &::before {
    content: "\2715"; // .Icon--cancel
  }

  &:hover {
    transition-duration: 0.3s;
    text-decoration: none;
    color: inherit;
    background: $headline-btn-bg-hover;
  }

  @media (max-width: $screen-tablet-s) {
    padding: 0.6875rem;
  }
}

// Breaking News modifier
.Headline--danger {
  background: $headline-bg-danger;

  .Headline-title {
    background: $headline-title-bg-danger;
  }

  .Headline-subTitle {
    color: $white;
  }

  .Headline-button {
    background: $headline-title-bg-danger;

    &:hover {
      background: $headline-btn-bg-hover-danger;
    }
  }
}
